/* eslint-disable */
import React, { Component, ReactNode } from 'react';
import { withRouter } from 'react-router-dom';
import shortid from 'shortid';
import { Footer, Header } from '@components';
import { BoardService, FileService, TokenService } from '@services';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StringUtil } from '@utils';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  Typography,
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  TextField,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Button,
  Checkbox,
} from '@material-ui/core';
import '../../join/JoinPage.scss';

interface MemberBenefitsCreatePageProps {
  location: any;
  history: any;
  match: any;
  enums: any;
}

class MemberBenefitsCreatePage extends Component<MemberBenefitsCreatePageProps, any> {
  constructor(props: MemberBenefitsCreatePageProps) {
    super(props);

    this.state = {
      board: {
        topFixedFlag: false,
        category: 'select',
        thumbnailId: '',
        title: '',
        type: 'GENERAL',
        activeStart: '',
        activeEnd: '',
        content: '',
        availableRank: 'ALL',
        promotionLetterId: '',
        introduceCompanyAndService: '',
        detailContent: '',
        usageOption: 'LINK',
        usageOptionContent: '',
        usageButtonLink: '',
        caution: '',

        inquiryName: '',
        inquiryGrade: '',
        inquiryEmail: '',
        inquiryPhone: '',

        managerName: '',
        managerGrade: '',
        managerEmail: '',
        managerPhone: '',
        managerEtc: '',
      },
      logo: {
        tempLogo: null,
        logoPreview: '',
      },
      promotionImg: null,
    };
  }

  componentDidMount = () => {
    const { history } = this.props;
    const accessToken: string | null = TokenService.getAccessToken();
    if (!accessToken) {
      history.push('/login', { redirectUrl: '/membership/benefits/create' });
    }
  };

  onClickSaveButton = () => {
    if (this.checkValidation() && window.confirm('게시글을 등록하시겠습니까?')) {
      this.insertBoard();
    }
  };

  insertBoard = async () => {
    const { board, logo, promotionImg } = this.state;
    const { history } = this.props;

    const thumbnailAttachId = (await FileService.upload(logo.tempLogo))[0].id;
    board.thumbnailId = thumbnailAttachId;

    const promotionLetterAttachId = (await FileService.upload(promotionImg))[0].id;
    board.promotionLetterId = promotionLetterAttachId;

    try {
      await BoardService.insertBenefit(board);
      alert('내부 가입 승인 검토 후,\n담당자 이메일로 최종 안내드리도록 하겠습니다.');

      history.push(`/membership/benefits`);
    } catch (error) {
      console.log(error);
    }
  };

  handleChangeDate = (date: any, key: any) => {
    this.setState((prevState: any) => ({
      board: {
        ...prevState.board,
        [key]: StringUtil.formatter.date(date || '', 'YYYY-MM-DD'),
      },
    }));
  };

  handleChangeText = (e: any, key: any) => {
    const { value } = e.target;

    this.setState((prevState: any) => ({
      board: {
        ...prevState.board,
        [key]: value,
      },
    }));
  };

  onChangeLogoFile = async (e: any) => {
    e.preventDefault();

    const file: File = e.target.files[0];

    if (!file) {
      return;
    }

    e.target.value = '';

    try {
      this.setState({
        logo: {
          tempLogo: file,
          logoPreview: await FileService.loadImgDataSource(file),
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  onClickRemoveLogoFile = async () => {
    this.setState({
      logo: {
        tempLogo: undefined,
        logoPreview: '',
      },
    });
  };

  onChangeAttachedFile = async (e: any) => {
    e.preventDefault();

    const file = e.target.files[0];

    if (!file) {
      return;
    }

    e.target.value = '';

    try {
      this.setState({
        promotionImg: file,
      });
    } catch (e) {
      console.log(e);
    }
  };

  onClickRemoveAttachedFile = () => {
    this.setState({
      promotionImg: null,
    });
  };

  onClickMoveBack = (): void => {
    const { history } = this.props;
    history.push(`/membership/benefits`);
  };

  onChangeCheckbox = (e: any): void => {
    if (e.target.checked) {
      this.setState((prevState: any) => ({
        board: {
          ...prevState.board,
          managerName: this.state.board.inquiryName,
          managerGrade: this.state.board.inquiryGrade,
          managerEmail: this.state.board.inquiryEmail,
          managerPhone: this.state.board.inquiryPhone,
        },
      }));
    } else {
      this.setState((prevState: any) => ({
        board: {
          ...prevState.board,
          managerName: '',
          managerGrade: '',
          managerEmail: '',
          managerPhone: '',
        },
      }));
    }
  };

  checkValidation = () => {
    const { board, logo, promotionImg } = this.state;

    let msgId = '';

    if (board.category === 'select') {
      msgId = '활동 카테고리를 선택해주세요.';
    } else if (!board.title || board.title.trim() === '') {
      msgId = '프로모션 제목을 입력해주세요.';
    } else if (!logo.tempLogo) {
      msgId = '썸네일을 등록해주세요.';
    } else if (board.activeStart === '' || board.activeEnd === '') {
      msgId = '프로모션 제공기간을 선택해주세요.';
    } else if (board.activeStart === 'Invalid date' || board.activeEnd === 'Invalid date') {
      msgId = '프로모션 제공기간 형식을 바르게 선택해주세요.';
    } else if (board.activeStart >= board.activeEnd) {
      msgId = '프로모션 제공 종료 시간을 시작 시간보다 느린 날짜로 입력해주세요.';
    } else if (!board.content || board.content.trim() === '') {
      msgId = '프로모션 내용을 입력해주세요.';
    } else if (!promotionImg) {
      msgId = '프로모션 레터 이미지를 등록해주세요.';
    } else if (
      !board.introduceCompanyAndService ||
      board.introduceCompanyAndService.trim() === ''
    ) {
      msgId = '기업 & 서비스 소개를 입력해주세요.';
    } else if (!board.detailContent || board.detailContent.trim() === '') {
      msgId = '프로모션 상세 내용을 입력해주세요.';
    } else if (!board.usageButtonLink || board.usageButtonLink.trim() === '') {
      msgId = '프로모션 이용하기 버튼링크를 입력해주세요.';
    } else if (!board.inquiryName || board.inquiryName.trim() === '') {
      msgId = '문의처 이름을 입력해주세요.';
    } else if (!board.inquiryGrade || board.inquiryGrade.trim() === '') {
      msgId = '문의처 직함을 입력해주세요.';
    } else if (!board.inquiryEmail || board.inquiryEmail.trim() === '') {
      msgId = '문의처 이메일주소을 입력해주세요.';
    } else if (!board.inquiryPhone || board.inquiryPhone.trim() === '') {
      msgId = '문의처 연락처을 입력해주세요.';
    } else if (!board.managerName || board.managerName.trim() === '') {
      msgId = '프로모션 담당자 이름을 입력해주세요.';
    } else if (!board.managerGrade || board.managerGrade.trim() === '') {
      msgId = '프로모션 담당자 직함을 입력해주세요.';
    } else if (!board.managerEmail || board.managerEmail.trim() === '') {
      msgId = '프로모션 담당자 이메일 주소를 입력해주세요.';
    } else if (!board.managerPhone || board.managerPhone.trim() === '') {
      msgId = '프로모션 담당자 연락처를 입력해주세요.';
    }

    if (msgId) {
      alert(msgId);
      return false;
    }
    return true;
  };

  render(): ReactNode {
    const { board, logo, promotionImg } = this.state;
    const { enums } = this.props;

    return (
      <>
        <div className='join-page-wrap join-page-sub'>
          <Header />
          <div className='apply-page apply-page-sub apply-tablet'>
            <div className='section'>
              <div className='title-wrap'>
                <h2>프로모션 제안</h2>
              </div>
              <div className='contents-wrap'>
                <div className='apply-input-container'>
                  <div className='apply-input-wrap'>
                    <div className='sub-title'>
                      <p>프로모션 제안 내용 입력</p>
                    </div>
                    <div className='inner'>
                      <div className='input-container'>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>프로모션 카테고리</span>
                            </div>
                            <div className='input-area'>
                              <FormControl variant='outlined' className='select-wrap'>
                                <Select
                                  value={board.category}
                                  className='select-item'
                                  onChange={(e): void => this.handleChangeText(e, 'category')}
                                >
                                  <MenuItem value='select'>카테고리</MenuItem>
                                  {enums &&
                                    enums['benefit-category'].map((item: any) => (
                                      <MenuItem key={item.key} value={item.key}>
                                        {item.value}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>썸네일 로고</span>
                            </div>
                            <div className='logo-file'>
                              <div>
                                <div className='file-upload'>
                                  <div className='file-inner'>
                                    <input
                                      type='file'
                                      className='upload-photo'
                                      accept='.png, .jpg'
                                      onChange={this.onChangeLogoFile}
                                    />
                                    {logo.tempLogo && logo.tempLogo.name ? (
                                      <>
                                        <img
                                          className='mypage-logo'
                                          src={logo.logoPreview}
                                          alt=''
                                        />
                                        <button
                                          type='button'
                                          className='btn btn-file delete-btn'
                                          onClick={this.onClickRemoveLogoFile}
                                        >
                                          삭제
                                        </button>
                                      </>
                                    ) : (
                                      <Button variant='outlined' className='btn-photo'>
                                        <span className='upload-text'>
                                          클릭하여 이미지 업로드
                                          <br />
                                          <span className='font-gray'>권장사이즈 : 350*220px</span>
                                        </span>
                                      </Button>
                                    )}
                                  </div>
                                  <p>*파일 형식 : png, jpg | 최대 10MB</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>프로모션 제목</span>
                            </div>
                            <div className='input-area'>
                              <TextField
                                className='input-text long'
                                placeholder='[기업명] 제목으로 작성해 주세요'
                                InputProps={{
                                  inputProps: {
                                    type: 'text',
                                    maxLength: 100,
                                  },
                                }}
                                value={board.title}
                                onChange={(e): void => this.handleChangeText(e, 'title')}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>프로모션 종류</span>
                            </div>
                            <div className='input-area'>
                              <FormControl component='fieldset' className='radio-wrap'>
                                <RadioGroup
                                  aria-label='type'
                                  className='input-radio'
                                  value={board.type}
                                  onChange={(e): void => this.handleChangeText(e, 'type')}
                                >
                                  <FormControlLabel
                                    value='GENERAL'
                                    control={<Radio className='radio' color='default' />}
                                    label='일반'
                                  />
                                  <FormControlLabel
                                    value='KSF_ONLY'
                                    control={<Radio className='radio' color='default' />}
                                    label='코스포 ONLY'
                                  />
                                  <FormControlLabel
                                    value='CHUSEOK'
                                    control={<Radio className='radio' color='default' />}
                                    label='추석'
                                  />
                                  <FormControlLabel
                                    value='SEOL'
                                    control={<Radio className='radio' color='default' />}
                                    label='설날'
                                  />
                                </RadioGroup>
                                <p>
                                  - 일반 : 기본 프로모션으로 타 제휴처에 제공하는 프로모션과 동일한
                                  프로모션
                                  <br />
                                  - 코스포 ONLY : 특별 프로모션으로 코스포 회원사에게만 제공되는
                                  특별 혜택으로, 타 제휴처에 제공하는 프로모션과 명확한 차이가 있는
                                  프로모션)
                                  <br />- 추석 또는 설날 선택 : 시즌성 프로모션으로 명절에만
                                  제공하는 프로모션
                                </p>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <p className='input-title'>
                              <span className='required'>프로모션 제공기간</span>
                            </p>
                            <div className='input-area date-wrap'>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  variant='inline'
                                  format='yyyy-MM-dd'
                                  autoOk
                                  className='input-date'
                                  value={board.activeStart || null}
                                  onChange={(e) => this.handleChangeDate(e, 'activeStart')}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                              <span>~</span>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  variant='inline'
                                  format='yyyy-MM-dd'
                                  autoOk
                                  className='input-date'
                                  value={board.activeEnd || null}
                                  onChange={(e) => this.handleChangeDate(e, 'activeEnd')}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                          </div>
                        </div>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>프로모션 내용</span>
                            </div>
                            <div className='input-area'>
                              <TextField
                                className='input-text long'
                                InputProps={{
                                  inputProps: {
                                    type: 'text',
                                    maxLength: 100,
                                  },
                                }}
                                value={board.content}
                                onChange={(e): void => this.handleChangeText(e, 'content')}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>이용가능 회원등급</span>
                            </div>
                            <div className='input-area'>
                              <FormControl component='fieldset' className='radio-wrap'>
                                <RadioGroup
                                  aria-label='gradeType'
                                  className='input-radio'
                                  value={board.availableRank}
                                  onChange={(e): void => this.handleChangeText(e, 'availableRank')}
                                >
                                  <FormControlLabel
                                    value='ALL'
                                    control={<Radio className='radio' color='default' />}
                                    label='전체'
                                  />
                                  <FormControlLabel
                                    value='REGULAR'
                                    control={<Radio className='radio' color='default' />}
                                    label='정회원'
                                  />
                                  <FormControlLabel
                                    value='ASSOCIATE'
                                    control={<Radio className='radio' color='default' />}
                                    label='준회원'
                                  />
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>프로모션 레터 이미지</span>
                            </div>
                            <div className='input-area file-container'>
                              <div className='file-wrap'>
                                <input
                                  type='file'
                                  accept='.png, .jpg'
                                  id='file'
                                  className='input-file'
                                  onChange={this.onChangeAttachedFile}
                                />
                                <label htmlFor='file' className='btn btn-file'>
                                  파일첨부
                                </label>
                                <p className='file-tip'>
                                  * 이미지 비율은 16:9로 등록 | 파일 형식 : png, jpg | 최대 10MB
                                </p>
                              </div>
                              {promotionImg && promotionImg.name && (
                                <div className='file-box-wrap'>
                                  <p className='filename'>{promotionImg.name}</p>
                                  <button
                                    type='button'
                                    className='btn btn-file delete-btn'
                                    onClick={this.onClickRemoveAttachedFile}
                                  >
                                    삭제
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>기업 & 서비스 소개</span>
                            </div>
                            <div className='input-area'>
                              <textarea
                                maxLength={100}
                                rows={2}
                                value={board.introduceCompanyAndService}
                                onChange={(e): void =>
                                  this.handleChangeText(e, 'introduceCompanyAndService')
                                }
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>프로모션 상세 내용</span>
                            </div>
                            <div className='input-area'>
                              <textarea
                                maxLength={100}
                                rows={5}
                                value={board.detailContent}
                                onChange={(e): void => this.handleChangeText(e, 'detailContent')}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>프로모션 이용방법</span>
                            </div>
                            <div className='input-area'>
                              <FormControl component='fieldset' className='radio-wrap'>
                                <RadioGroup
                                  aria-label='usageOption'
                                  className='input-radio'
                                  value={board.usageOption}
                                  onChange={(e): void => this.handleChangeText(e, 'usageOption')}
                                >
                                  <FormControlLabel
                                    value='LINK'
                                    control={<Radio className='radio' color='default' />}
                                    label='회원사 전용 링크 개설'
                                  />
                                  <FormControlLabel
                                    value='CODE'
                                    control={<Radio className='radio' color='default' />}
                                    label='회원사 전용 프로모션 코드 제공'
                                  />
                                  <FormControlLabel
                                    value='INQUIRY'
                                    control={<Radio className='radio' color='default' />}
                                    label='문의처 통해 이용방법 확인'
                                  />
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        {(board.usageOption === 'LINK' || board.usageOption === 'CODE') && (
                          <div className='input-wrap'>
                            <div className='input-inner'>
                              <div className='input-title'>
                                <span>추가작성</span>
                              </div>
                              <div className='input-area'>
                                <TextField
                                  className='input-text'
                                  InputProps={{
                                    inputProps: {
                                      type: 'text',
                                      maxLength: 100,
                                    },
                                  }}
                                  value={board.usageOptionContent}
                                  onChange={(e): void =>
                                    this.handleChangeText(e, 'usageOptionContent')
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>프로모션 이용하기 버튼 링크</span>
                            </div>
                            <div className='input-area'>
                              <TextField
                                className='input-text long'
                                placeholder='하단 이용하기 버튼 클릭 시 연결되는 링크입니다.'
                                InputProps={{
                                  inputProps: {
                                    type: 'text',
                                  },
                                }}
                                value={board.usageButtonLink}
                                onChange={(e): void => this.handleChangeText(e, 'usageButtonLink')}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span>유의사항</span>
                            </div>
                            <div className='input-area'>
                              <TextField
                                className='input-text'
                                InputProps={{
                                  inputProps: {
                                    type: 'caution',
                                    maxLength: 100,
                                  },
                                }}
                                value={board.caution}
                                onChange={(e): void => this.handleChangeText(e, 'caution')}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='apply-input-wrap'>
                    <div className='sub-title'>
                      <p>문의처</p>
                    </div>
                    <div className='inner'>
                      <div className='input-container'>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>이름</span>
                            </div>
                            <div className='input-area'>
                              <TextField
                                className='input-text'
                                InputProps={{
                                  inputProps: {
                                    type: 'text',
                                    maxLength: 100,
                                  },
                                }}
                                value={board.inquiryName}
                                onChange={(e): void => this.handleChangeText(e, 'inquiryName')}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>직함</span>
                            </div>
                            <div className='input-area'>
                              <TextField
                                className='input-text'
                                InputProps={{
                                  inputProps: {
                                    type: 'text',
                                    maxLength: 100,
                                  },
                                }}
                                value={board.inquiryGrade}
                                onChange={(e): void => this.handleChangeText(e, 'inquiryGrade')}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>이메일 주소</span>
                            </div>
                            <div className='input-area'>
                              <TextField
                                className='input-text'
                                InputProps={{
                                  inputProps: {
                                    type: 'text',
                                    maxLength: 100,
                                  },
                                }}
                                value={board.inquiryEmail}
                                onChange={(e): void => this.handleChangeText(e, 'inquiryEmail')}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>연락처</span>
                            </div>
                            <div className='input-area'>
                              <TextField
                                className='input-text'
                                InputProps={{
                                  inputProps: {
                                    type: 'text',
                                    maxLength: 11,
                                  },
                                }}
                                value={board.inquiryPhone}
                                onChange={(e): void => this.handleChangeText(e, 'inquiryPhone')}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='apply-input-wrap'>
                    <div className='sub-title'>
                      <p>프로모션 담당자 정보</p>
                      <div className='checkbox-wrap'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              onChange={this.onChangeCheckbox}
                              className='policy-checkbox'
                            />
                          }
                          label='문의처 정보와 동일'
                        />
                      </div>
                    </div>
                    <div className='inner'>
                      <div className='input-container'>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>이름</span>
                            </div>
                            <div className='input-area'>
                              <TextField
                                className='input-text'
                                InputProps={{
                                  inputProps: {
                                    type: 'text',
                                    maxLength: 100,
                                  },
                                }}
                                value={board.managerName}
                                onChange={(e): void => this.handleChangeText(e, 'managerName')}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>직함</span>
                            </div>
                            <div className='input-area'>
                              <TextField
                                className='input-text'
                                InputProps={{
                                  inputProps: {
                                    type: 'text',
                                    maxLength: 100,
                                  },
                                }}
                                value={board.managerGrade}
                                onChange={(e): void => this.handleChangeText(e, 'managerGrade')}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>이메일 주소</span>
                            </div>
                            <div className='input-area'>
                              <TextField
                                className='input-text'
                                InputProps={{
                                  inputProps: {
                                    type: 'text',
                                    maxLength: 100,
                                  },
                                }}
                                value={board.managerEmail}
                                onChange={(e): void => this.handleChangeText(e, 'managerEmail')}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span className='required'>연락처</span>
                            </div>
                            <div className='input-area'>
                              <TextField
                                className='input-text'
                                InputProps={{
                                  inputProps: {
                                    type: 'text',
                                    maxLength: 11,
                                  },
                                }}
                                value={board.managerPhone}
                                onChange={(e): void => this.handleChangeText(e, 'managerPhone')}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='input-wrap'>
                          <div className='input-inner'>
                            <div className='input-title'>
                              <span>기타</span>
                            </div>
                            <div className='input-area'>
                              <TextField
                                className='input-text'
                                InputProps={{
                                  inputProps: {
                                    type: 'text',
                                    maxLength: 100,
                                  },
                                }}
                                value={board.managerEtc}
                                onChange={(e): void => this.handleChangeText(e, 'managerEtc')}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bottom-area'>
                  <button type='button' className='btn btn-back' onClick={this.onClickMoveBack}>
                    취소
                  </button>
                  <button
                    type='button'
                    className='btn btn-complete'
                    onClick={this.onClickSaveButton}
                  >
                    제출하기
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ common }: any) => ({
  enums: common.enums,
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MemberBenefitsCreatePage);
