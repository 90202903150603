import { RouteProps } from 'react-router-dom';
import { MainPage } from '@pages';

const MainRouteConfigs: RouteProps[] = [
  {
    exact: true,
    path: '/',
    component: MainPage,
  },
];

export default MainRouteConfigs;
