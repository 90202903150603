import React, { Component, ReactNode } from 'react';
import { withRouter } from 'react-router-dom';
import { Header, Footer } from '@components';
import { NumberUtil } from '@utils';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import '../Member.scss';
import { CommonService, MainService } from '@services';

interface MemberGuidePageProps {
  location: any;
  history: any;
  match: any;
}

class MemberGuidePage extends Component<MemberGuidePageProps, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      count: {
        member: 0,
        accumulativeInvestment: 0,
        business: 0,
        incumbent: 0,
      },
    };
  }

  componentDidMount = () => {
    this.getCount();
  };

  getCount = async () => {
    try {
      const count = await MainService.getCount();

      this.setState({ count });
    } catch (error) {
      console.log(error);
    }
  };

  render(): ReactNode {
    const { count } = this.state;

    return (
      <div>
        <Header />
        <div className='member-page'>
          <div className='inner'>
            <div className='guide'>
              <div className='left-contents title'>
                <h1>
                  연회비
                  <br />
                  <span>납부안내</span>
                </h1>
                <h3>Members</h3>
              </div>
              <div className='right-contents'>
                <h4>안녕하세요. 코리아스타트업포럼입니다.</h4>
                <p>
                  코리아스타트업포럼은 스타트업의 성장과 생태계 발전을 지원하고, 공동의 이익을
                  대변하기 위해 2016년 9월 50여 스타트업이 모여 발족, 2018년 4월 사단법인으로 공식
                  출범 했습니다. 현재 {NumberUtil.formatter.commas(count.member)}여 스타트업 및
                  기업, 스타트업 지원기관이 동참하는 국내 최대 스타트업 단체로 성장하고 있습니다.{' '}
                  <br />
                  <br />
                  코리아스타트업포럼은 스타트업이 겪는 다양한 규제 해소, 비즈니스 생태계 구축 및
                  스타트업 간 네트워킹, 산업·지역 협의회 운영, 스타트업 성장 지원 활동 등 다양한
                  사업을 스타트업과 함께 추진해오고 있습니다.
                  <br />
                  <br />
                  코리아스타트업포럼의 모든 활동은 회원사에서 납부해주신 연회비로 운영 되고있습니다.
                  모아진 운영 예산은 스타트업 생태계에 보탬 되도록 소중히, 그리고 투명하게
                  사용하겠습니다. 연초 정기총회를 통해 보고 드리고 있으니 참고 부탁드립니다.
                </p>
              </div>
              <div className='member-contents member-pay'>
                <div className='title'>
                  <h3>
                    회원별
                    <br />
                    <span>연회비</span>
                  </h3>
                  <div className='sub-title'>
                    <h4>
                      회원자격 변경 <span>(준회원 → 정회원)</span>
                    </h4>
                    <p>
                      회원자격 변경을 원하신다면,
                      <span>연회비 납부 후 담당자에게 연락 주시기 바랍니다.</span>
                    </p>
                  </div>
                </div>
                <div className='list'>
                  <div className='table'>
                    <TableContainer>
                      <Table aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell align='center'>회원구분</TableCell>
                            <TableCell align='center'>연회비 금액</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell align='center'>정회원 (이사)</TableCell>
                            <TableCell align='center'>1,200만원 이상</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align='center'>정회원 (운영위원)</TableCell>
                            <TableCell align='center'>600만원 이상</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align='center'>정회원 (일반회원) *</TableCell>
                            <TableCell align='center'>
                              60만원, 120만원, 180만원 이상 중 택 1
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align='center'>특별회원사</TableCell>
                            <TableCell align='center'>2,000만원 이상</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align='center'>후원회원사</TableCell>
                            <TableCell align='center'>100만원 이상</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align='center'>준회원사</TableCell>
                            <TableCell align='center'>무료</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='guide-contents rule'>
            <div className='inner'>
              <div className='title'>
                <h3>
                  연회비 <br />
                  <span>규정 안내</span>
                </h3>
              </div>
              <div className='sub-title'>
                <p>
                  <span>
                    정회원 (일반회원)의 경우 ‘연회비 선택납부제(60만원, 120만원, 180만원 이상)’를
                    도입하고 있습니다.
                  </span>
                  회비 차액에 대한 혜택 차이는 없으며, 납부 시 기업에서 자율적으로 판단해주시면
                  되겠습니다. 이런 제도를 도입한 이유는 개별 회원사 마다 규모와 단계가 상이하고,
                  코리아스타트업포럼의 경험 정도에 따라 회원사 관점에서 얻는 명분과 효용이 다를
                  것이기 때문입니다. <br />
                  <br />
                  납부 금액이 커질수록 코리아스타트업포럼 운영 재정에 더 많은 기여를 해주시는 것은
                  맞지만 활동혜택에 차이를 두지 않으니, 부담없이 판단해주시길 바랍니다.
                </p>
              </div>
            </div>
          </div>
          <div className='guide-contents'>
            <div className='inner'>
              <div className='title left'>
                <h3>
                  <span>납부 방법</span>
                </h3>
                <p>계좌이체 또는 카드결제 (SMS링크)</p>
              </div>
              <div className='right'>
                <p>
                  <span className='font-bold'>계좌이체 : </span>우리 1005-303-467906
                  <span className='block'>(예금주: (사)코리아스타트업포럼)</span>
                  <br />
                  <span className='font-bold'>카드결제 : </span>연회비 청구서(공문)*에 기재된
                  <span className='block'>결제링크를 통한 카드결제</span>
                  <br />
                </p>
                <p className='info'>
                  * 연회비 청구서(공문)은 연회비 납부 대상 회원분들에게 이메일로 발송됩니다.
                </p>
              </div>
              <div className='sub-title pay-info'>
                <p>
                  (사)코리아스타트업포럼은 비영리법인으로 회원 자격으로 납부하는 회비에 관하여
                  영수증을 발급해 드리고 있습니다. 별도의 계산서는 제공하지 않습니다. <br />
                  ‘연회비 납입확인서’ 으로 증빙 가능하며, 카드 결제시에는 ‘신용카드매출전표’로
                  증빙하면 됩니다. 연회비 납입확인서가 필요하신 경우 별도 요청해 주시기 바랍니다.
                </p>
              </div>
            </div>
          </div>
          <div className='guide-contents question'>
            <div className='inner'>
              <div className='title left'>
                <h3>
                  <span>문의</span>
                </h3>
                <p>
                  <span className='block'>(사)코리아스타트업포럼 사무국</span>
                  <br />
                  <br />
                  <br />
                  <span className='block mt-25'>02-6211-9400</span> <br />
                  hi@kstartupforum.org
                </p>
              </div>
              <div className='right'>
                <ul>
                  <li>
                    <button
                      type='button'
                      onClick={() => {
                        CommonService.downLink('/코스포 사업자등록증.pdf');
                      }}
                    >
                      (사)코리아스타트업포럼 사업자등록증
                    </button>
                  </li>
                  <li>
                    <button
                      type='button'
                      onClick={() => {
                        CommonService.downLink('/코리아스타트업포럼_통장사본.jpg');
                      }}
                    >
                      (사)코리아스타트업포럼 통장사본
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(MemberGuidePage);
