import React, { Component, ReactNode } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer';
import '../JoinPage.scss';

interface JoinResultPageProps {
  location: any;
  history: any;
  match: any;
}

interface JoinResultPageStates {
  applyState: boolean;
}

class JoinResultPage extends Component<JoinResultPageProps, JoinResultPageStates> {
  constructor(props: any) {
    super(props);

    this.state = {
      applyState: true,
    };
  }

  componentDidMount = () => {
    const { match } = this.props;
    const applyState = match.params.status === 'success';
    this.setState({
      applyState,
    });
  };

  render(): ReactNode {
    const { applyState } = this.state;
    return (
      <div className='apply-result-wrap'>
        <Header />
        <div className='apply-result-page'>
          <div className='inner'>
            <div className='text-area'>
              {applyState ? <h1>가입신청 완료</h1> : <h1>승인 대기안내</h1>}
              {applyState ? (
                <>
                  <h3>Welcome!</h3>
                  <p>회원사 가입신청이 완료되었습니다.</p>
                </>
              ) : (
                <p className='info'>
                  가입승인은 1~2주정도 소요됩니다. <br />
                  자세한 사항은 관리자에게 문의해주세요.
                </p>
              )}
            </div>
            <div className='button-area'>
              {applyState ? (
                <>
                  <a href='/login' className='btn btn-login'>
                    로그인
                  </a>
                  <a href='/' className='btn btn-main'>
                    메인으로 이동
                  </a>
                </>
              ) : (
                <a href='/' className='btn2 btn-main'>
                  메인으로 이동
                </a>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(JoinResultPage);
