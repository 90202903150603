import React, { Component, ReactNode } from 'react';
import { withRouter } from 'react-router-dom';
import { TokenService, AuthService } from '@services';
import './Header.scss';
import LoginPopup from '../popup';

/**
 * header
 */

interface HeaderProps {
  location: any;
  history: any;
  match: any;
}

interface HeaderStates {
  isPageType: boolean;
  isSideMenuVisible: boolean;
  isForumMenuVisible: boolean;
  isNewsMenuVisible: boolean;
  isMembershipMenuVisible: boolean;
  isCommunityMenuVisible: boolean;
  isEnMenuVisible: boolean;
  isVisiblePopup: boolean;

  accessToken: string | null;
}

class MobileHeader extends Component<HeaderProps, HeaderStates> {
  constructor(props: any) {
    super(props);
    this.state = {
      isPageType: false,
      isSideMenuVisible: false,
      isForumMenuVisible: false,
      isNewsMenuVisible: false,
      isMembershipMenuVisible: false,
      isCommunityMenuVisible: false,
      isEnMenuVisible: false,
      isVisiblePopup: false,
      accessToken: TokenService.getAccessToken(),
    };
  }

  componentDidMount = (): void => {
    const { location } = this.props;
    if (location.pathname === '/') {
      this.setState({
        isPageType: true,
      });
    }
  };

  checkGranted = async (url: string): Promise<void> => {
    try {
      const accessToken = TokenService.getAccessToken();

      if (!accessToken) {
        this.openVisiblePopup();
      } else {
        window.location.href = url;
      }
    } catch (error) {
      console.log(error);
    }
  };

  openVisiblePopup = () => {
    this.setState({ isVisiblePopup: true });
  };

  closeVisiblePopup = () => {
    this.setState({ isVisiblePopup: false });
  };

  onClickSideMenuButton = (): void => {
    this.setState({
      isSideMenuVisible: true,
    });
  };

  onClickCancelButton = (): void => {
    this.setState({
      isSideMenuVisible: false,
    });
  };

  onClickForumToggleMenu = (): void => {
    const { isForumMenuVisible } = this.state;
    this.setState({
      isForumMenuVisible: !isForumMenuVisible,
    });
  };

  onClickNewsToggleMenu = (): void => {
    const { isNewsMenuVisible } = this.state;
    this.setState({
      isNewsMenuVisible: !isNewsMenuVisible,
    });
  };

  onClickMembershipToggleMenu = (): void => {
    const { isMembershipMenuVisible } = this.state;
    this.setState({
      isMembershipMenuVisible: !isMembershipMenuVisible,
    });
  };

  onClickCommunityToggleMenu = (): void => {
    const { isCommunityMenuVisible } = this.state;
    this.setState({
      isCommunityMenuVisible: !isCommunityMenuVisible,
    });
  };

  onClickEnToggleMenu = (): void => {
    const { isEnMenuVisible } = this.state;
    this.setState({
      isEnMenuVisible: !isEnMenuVisible,
    });
  };

  onClickForbidden = (url: string): void => {
    this.checkGranted(url);
  };

  signOut = async (): Promise<void> => {
    const { history, location } = this.props;

    try {
      await AuthService.signOut();
    } finally {
      if (location.pathname === '/') {
        window.location.reload();
      } else {
        history.push('/');
      }
    }
  };

  moveToMypage = (): void => {
    const { history } = this.props;
    history.push(`/mypage`);
  };

  render(): ReactNode {
    const {
      isPageType,
      isSideMenuVisible,
      isForumMenuVisible,
      isNewsMenuVisible,
      isMembershipMenuVisible,
      isCommunityMenuVisible,
      isEnMenuVisible,
      isVisiblePopup,
      accessToken,
    } = this.state;
    return (
      <>
        {isSideMenuVisible ? (
          <div className='m-nav'>
            <button type='button' onClick={this.onClickCancelButton} className='btn-menu-cancel'>
              <h1>닫기</h1>
            </button>
          </div>
        ) : (
          <div className='m-nav'>
            <button
              type='button'
              onClick={this.onClickSideMenuButton}
              className={`${isPageType ? 'btn-menu' : 'btn-menu-black'}`}
            >
              <h1>메뉴</h1>
            </button>
          </div>
        )}
        {isSideMenuVisible ? (
          <div className='side-menu-wrap'>
            <div className='side-menu'>
              <div className='menu-list'>
                <div className='menu-top'>
                  <ul>
                    <li>
                      <button type='button' onClick={this.onClickForumToggleMenu}>
                        코리아스타트업포럼
                      </button>
                    </li>
                    {isForumMenuVisible ? (
                      <div className='toggle-menu'>
                        <ul>
                          <li>
                            <a href='/forum/introduce'>소개</a>
                          </li>
                          <li>
                            <a href='/forum/manifesto'>선언문</a>
                          </li>
                          {/* <li>
                            <a href='/forum/history'>연혁</a>
                          </li>
                          <li>
                            <a href='/forum/chairperson'>의장 인사말</a>
                          </li> */}
                          <li>
                            <a href='/forum/organization'>함께하는 사람들</a>
                          </li>
                          <li>
                            <a href='/news/notice'>공지사항</a>
                          </li>
                          <li>
                            <a href='/forum/contact'>문의하기</a>
                          </li>
                          <li>
                            <a href='/forum/ci'>CI</a>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      ''
                    )}
                    <li>
                      <button type='button' onClick={this.onClickNewsToggleMenu}>
                        활동
                      </button>
                    </li>
                    {isNewsMenuVisible ? (
                      <div className='toggle-menu'>
                        <ul>
                          {/* <li>
                            <a href='/news/newsletter'>뉴스레터</a>
                          </li> */}
                          <li>
                            <a href='/news/schedule'>프로그램</a>
                          </li>
                          <li>
                            <a href='/news/library'>정책활동</a>
                          </li>
                          <li>
                            <a href='/news/news'>언론보도</a>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      ''
                    )}
                    <li>
                      <button type='button' onClick={this.onClickMembershipToggleMenu}>
                        회원사
                      </button>
                    </li>
                    {isMembershipMenuVisible ? (
                      <div className='toggle-menu'>
                        <ul>
                          <li>
                            {/* <button
                              type='button'
                              className='m-menu-btn'
                              onClick={() => this.onClickForbidden('/membership/member')}
                            >
                              회원사
                            </button> */}
                            <a href='/membership/member'>회원사</a>
                          </li>
                          <li>
                            <a href='/membership/join'>가입안내</a>
                          </li>
                          <li>
                            <a href='/membership/interview'>회원사 인터뷰</a>
                          </li>
                          <li>
                            <a href='/membership/benefits'>회원사 혜택</a>
                          </li>
                          {/* <li>
                            <a href='/membership/guide'>연회비 안내</a>
                          </li> */}
                        </ul>
                      </div>
                    ) : (
                      ''
                    )}
                    <li>
                      <button type='button' onClick={this.onClickCommunityToggleMenu}>
                        커뮤니티
                      </button>
                    </li>
                    {isCommunityMenuVisible ? (
                      <div className='toggle-menu'>
                        <ul>
                          <li>
                            <a href='/community/conference'>협의회</a>
                          </li>
                          <li>
                            <a href='/community/cooperative'>협력제안</a>
                          </li>
                          <li>
                            <a href='/community/legal-support'>법률특허지원</a>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      ''
                    )}
                    <li>
                      <button type='button' onClick={this.onClickEnToggleMenu}>
                        English
                      </button>
                    </li>
                    {isEnMenuVisible ? (
                      <div className='toggle-menu'>
                        <ul>
                          <li>
                            <a href='/en/introduce'>Introduction</a>
                          </li>
                          <li>
                            <a href='/en/manifesto'>Manifesto</a>
                          </li>
                          <li>
                            <a href='/en/history'>History</a>
                          </li>
                          <li>
                            <a href='/en/chairperson'>Chairperson</a>
                          </li>
                          <li>
                            <a href='/en/organization'>Organization</a>
                          </li>
                          <li>
                            <a href='/en/contact'>Contact</a>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      ''
                    )}
                  </ul>
                </div>
                <div className='menu-bottom'>
                  {accessToken ? (
                    <ul>
                      <li>
                        <button
                          type='button'
                          onClick={this.moveToMypage}
                          className='icon icon-user'
                        >
                          마이페이지
                        </button>
                      </li>
                      <li>
                        <button type='button' onClick={this.signOut} className='icon icon-login'>
                          로그아웃
                        </button>
                      </li>
                    </ul>
                  ) : (
                    <ul>
                      <li>
                        <a href='/login' className='icon icon-login'>
                          로그인
                        </a>
                      </li>
                      <li>
                        <a href='/join' className='icon icon-user'>
                          회원가입
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {isVisiblePopup && (
          <LoginPopup
            isVisiblePopup={isVisiblePopup}
            openVisiblePopup={this.openVisiblePopup}
            closeVisiblePopup={this.closeVisiblePopup}
          />
        )}
      </>
    );
  }
}

export default withRouter(MobileHeader);
