/**
 * Common pages
 */
import { lazy } from 'react';

const LoginPage = lazy(() => import('./login/LoginPage'));
const SearchPage = lazy(() => import('./login/SearchPage'));
const SearchResultPage = lazy(() => import('./login/SearchResultPage'));
const GuidancePage = lazy(() => import('./login/GuidancePage'));
const MainPage = lazy(() => import('./main/MainPage'));
const JoinGuidePage = lazy(() => import('./join/JoinGuidePage/JoinGuidePage'));
const JoinDetailPage = lazy(() => import('./join/JoinDetailPage/JoinDetailPage'));
const JoinResultPage = lazy(() => import('./join/JoinResultPage/JoinResultPage'));
const MyPage = lazy(() => import('./mypage/MyPage'));
const ResultPage = lazy(() => import('./mypage/ResultPage'));
const IntroducePage = lazy(() => import('./forum/IntroducePage'));
const Manifesto = lazy(() => import('./forum/ManifestoPage'));
const HistoryPage = lazy(() => import('./forum/HistoryPage'));
const ChairpersonPage = lazy(() => import('./forum/ChairpersonPage'));
const ContactPage = lazy(() => import('./forum/ContactPage'));
const OrganizationPage = lazy(() => import('./forum/OrganizationPage'));
const CiPage = lazy(() => import('./forum/CiPage'));
const CiPageBefore = lazy(() => import('./forum/CiPageBefore'));
const NoticeListPage = lazy(() => import('./news/NoticePage/NoticeListPage'));
const NoticeDetailPage = lazy(() => import('./news/NoticePage/NoticeDetailPage'));
const NewsLetterDetailPage = lazy(() => import('./news/NewsLetterPage/NewsLetterDetailPage'));
const NewsLetterListPage = lazy(() => import('./news/NewsLetterPage/NewsLetterListPage'));
const ScheduleListPage = lazy(() => import('./news/SchedulePage/ScheduleListPage'));
const ScheduleDetailPage = lazy(() => import('./news/SchedulePage/ScheduleDetailPage'));
const ScheduleRegPage = lazy(() => import('./news/SchedulePage/ScheduleRegPage'));
const ScheduleConfirmPage = lazy(() => import('./news/SchedulePage/ScheduleConfirmPage'));
const ScheduleCompletePage = lazy(() => import('./news/SchedulePage/ScheduleCompletePage'));
const ScheduleUpdatePage = lazy(() => import('./news/SchedulePage/ScheduleUpdatePage'));
const NewsListPage = lazy(() => import('./news/NewsPage/NewsListPage'));
const NewsDetailPage = lazy(() => import('./news/NewsPage/NewsDetailPage'));
const LibraryListPage = lazy(() => import('./news/LibraryPage/LibraryListPage'));
const LibraryDetailPage = lazy(() => import('./news/LibraryPage/LibraryDetailPage'));
const MemberListPage = lazy(() => import('./membership/MemberPage/MemberListPage'));
const MemberDetailPage = lazy(() => import('./membership/MemberPage/MemberDetailPage'));
const MemberBenefitsPage = lazy(() => import('./membership/MemberPage/MemberBenefitsPage'));
const MemberBenefitsDetailPage = lazy(
  () => import('./membership/MemberPage/MemberBenefitsDetailPage'),
);
const MemberBenefitsCreatePage = lazy(
  () => import('./membership/MemberPage/MemberBenefitsCreatePage'),
);
const MemberGuidePage = lazy(() => import('./membership/MemberPage/MemberGuidePage'));
const MemberInterviewListPage = lazy(
  () => import('./membership/MemberPage/MemberInterviewListPage'),
);
const CooperativeListPage = lazy(() => import('./community/CooperativePage/CooperativeListPage'));
const CooperativeDetailPage = lazy(
  () => import('./community/CooperativePage/CooperativeDetailPage'),
);
const CooperativeCreatePage = lazy(
  () => import('./community/CooperativePage/CooperativeCreatePage'),
);
const CooperativeEditPage = lazy(() => import('./community/CooperativePage/CooperativeEditPage'));
const ConsultationListPage = lazy(
  () => import('./community/ConsultationPage/ConsultationListPage'),
);
const ConsultationDetailPage = lazy(
  () => import('./community/ConsultationPage/ConsultationDetailPage'),
);
const ConsultationCreatePage = lazy(
  () => import('./community/ConsultationPage/ConsultationCreatePage'),
);
const AffiliateListPage = lazy(() => import('./community/AffiliatePage/AffiliateListPage'));
const AffiliateDetailPage = lazy(() => import('./community/AffiliatePage/AffiliateDetailPage'));
const BenefitListPage = lazy(() => import('./community/BenefitPage/BenefitListPage'));
const BenefitDetailPage = lazy(() => import('./community/BenefitPage/BenefitDetailPage'));
const IntroduceEnPage = lazy(() => import('./english/IntroduceEnPage'));
const ManifestoEnPage = lazy(() => import('./english/ManifestoEnPage'));
const HistoryEnPage = lazy(() => import('./english/HistoryEnPage'));
const ChairpersonEnPage = lazy(() => import('./english/ChairpersonEnPage'));
const OrganizationEnPage = lazy(() => import('./english/OrganizationEnPage'));
const LocationEnPage = lazy(() => import('./english/ContactEnPage'));
const ConferencePage = lazy(() => import('./community/ConferencePage/ConferencePage'));
const ConferenceCreatePage = lazy(() => import('./community/ConferencePage/ConferenceCreatePage'));
const LegalSupportListPage = lazy(
  () => import('./community/LegalSupportPage/LegalSupportListPage'),
);
const LegalSupportDetailPage = lazy(
  () => import('./community/LegalSupportPage/LegalSupportDetailPage'),
);
const LegalSupportCreatePage = lazy(
  () => import('./community/LegalSupportPage/LegalSupportCreatePage'),
);
const LegalSupportEditPage = lazy(
  () => import('./community/LegalSupportPage/LegalSupportEditPage'),
);

export {
  LoginPage,
  SearchPage,
  SearchResultPage,
  GuidancePage,
  MainPage,
  JoinGuidePage,
  JoinDetailPage,
  JoinResultPage,
  MyPage,
  ResultPage,
  IntroducePage,
  Manifesto,
  HistoryPage,
  ChairpersonPage,
  ContactPage,
  OrganizationPage,
  CiPage,
  CiPageBefore,
  NoticeListPage,
  NoticeDetailPage,
  NewsLetterDetailPage,
  NewsLetterListPage,
  ScheduleListPage,
  ScheduleDetailPage,
  ScheduleRegPage,
  ScheduleConfirmPage,
  ScheduleCompletePage,
  ScheduleUpdatePage,
  NewsListPage,
  NewsDetailPage,
  LibraryListPage,
  LibraryDetailPage,
  MemberListPage,
  MemberDetailPage,
  MemberInterviewListPage,
  MemberBenefitsPage,
  MemberBenefitsDetailPage,
  MemberBenefitsCreatePage,
  MemberGuidePage,
  CooperativeListPage,
  CooperativeDetailPage,
  CooperativeCreatePage,
  CooperativeEditPage,
  ConsultationListPage,
  ConsultationDetailPage,
  ConsultationCreatePage,
  AffiliateListPage,
  AffiliateDetailPage,
  BenefitListPage,
  BenefitDetailPage,
  IntroduceEnPage,
  ManifestoEnPage,
  HistoryEnPage,
  ChairpersonEnPage,
  OrganizationEnPage,
  LocationEnPage,
  ConferencePage,
  ConferenceCreatePage,
  LegalSupportListPage,
  LegalSupportDetailPage,
  LegalSupportCreatePage,
  LegalSupportEditPage,
};
