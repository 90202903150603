import { CommonService } from '@services';
import { CommonAction } from '@store/actions';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PageRouter from './routes';

class App extends Component<any> {
  constructor(props: any) {
    super(props);

    this.getBusinessList();
    this.getEnums();
  }

  getBusinessList = async () => {
    const { setBusiness } = this.props;
    const business = await CommonService.getBusinessList();
    setBusiness(business);
  };

  getEnums = async () => {
    const { setEnums } = this.props;
    const enums = await CommonService.getEnums();
    setEnums(enums);
  };

  render(): ReactNode {
    return (
      <Router>
        <Switch>
          <PageRouter />
        </Switch>
      </Router>
    );
  }
}

const mapDispatchToProps = (dispatch: any): any =>
  bindActionCreators(
    {
      setEnums: CommonAction.setEnums,
      setBusiness: CommonAction.setBusiness,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(App);
