import { SignInRequest, SignInResponse } from '@types';
import apiService from './apiService';
import tokenService from './tokenService';

/**
 * Auth API
 * @see https://startnet-rest.devnc.kr/docs/auth
 */
class AuthService {
  /**
   * 사용자 로그인
   * @param {SignInRequest} credential 계정정보(username, password)
   * @return {Promise<any>}
   */
  public signIn = async (credential: SignInRequest): Promise<any> => {
    const url: string = `/auth/sign-in`;

    try {
      const { accessToken, refreshToken }: SignInResponse = (await apiService.post(
        url,
        credential,
        false,
      )) as SignInResponse;

      if (accessToken) {
        tokenService.setAccessToken(accessToken);
      }

      if (refreshToken) {
        tokenService.setRefreshToken(refreshToken);
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 사용자 로그아웃
   * @return {Promise<any>}
   */
  public signOut = async (): Promise<any> => {
    const url: string = `/auth/sign-out`;

    try {
      await apiService.post(url);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    } finally {
      tokenService.cleanTokens();
    }
  };
}

export default new AuthService();
