import React, { Component, ReactNode } from 'react';
import { Header, Footer } from '@components';
import { BoardService, TokenService } from '@services';
import { StringUtil } from '@utils';
import '../Member.scss';
import { saveAs } from 'file-saver';
import env from '@env';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import defaultImgSrc from '../../../assets/images/news/img-notice01.png';

interface MemberBenefitsDetailPageProps {
  location: any;
  history: any;
  match: any;
  enums: any;
}

class MemberBenefitsDetailPage extends Component<MemberBenefitsDetailPageProps, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      board: {},
    };
  }

  componentDidMount = () => {
    const { history, match } = this.props;
    const accessToken: string | null = TokenService.getAccessToken();
    const boardId = Number(match.params.id);
    if (!accessToken) {
      history.push('/login', { redirectUrl: `/membership/benefits/${boardId}` });
    } else {
      this.getBoard();
    }
  };

  getBoard = async () => {
    const { match } = this.props;
    const boardId = Number(match.params.id);

    try {
      const data = await BoardService.getBenefitDetail(boardId);

      this.setState({
        board: data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  saveFile = (id: number) => {
    saveAs(`${env.API_URL}/file/${id}/download`);
  };

  onClickMoveToList = (): void => {
    const { history } = this.props;
    history.push('/membership/benefits');
  };

  onClickAnotherBoardDetail = (id: number): void => {
    window.location.href = `/membership/benefits/${id}`;
  };

  getPassed = (dateString: string): string => {
    if (dateString) {
      const dateArr = dateString.split('-').join('/');
      const date = new Date(dateArr).getTime();
      const now = new Date().getTime();
      return date <= now ? '종료' : '예정';
    }
    return '';
  };

  render(): ReactNode {
    const { board } = this.state;
    const { enums } = this.props;

    return (
      <div className='container'>
        <Header />
        <div className='member-page benefit-detail'>
          <div className='member-benefit-detail-wrap inner p-0'>
            <div className='notice'>
              <div className='benefit-category'>
                <p>
                  <button className='move-back' type='button' onClick={this.onClickMoveToList}>
                    <h2>뒤로 가기</h2>
                  </button>
                  회원혜택 안내
                  <button className='move-list' type='button' onClick={this.onClickMoveToList}>
                    목록
                  </button>
                </p>
              </div>
              <div className='notice-detail-wrap'>
                <div className='top-wrap'>
                  <div className='img-wrap'>
                    <img
                      src={board.thumbnail ? board.thumbnail.attach.path : defaultImgSrc}
                      onError={(e) => {
                        e.currentTarget.src = defaultImgSrc;
                      }}
                      alt=''
                    />
                  </div>
                  <div className='desc-wrap'>
                    {board.isProgressing ? (
                      <div className='reservation-box'>진행중</div>
                    ) : (
                      <div className='reservation-box false'>
                        {this.getPassed(board.activeStart)}
                      </div>
                    )}
                    <div className='title'>{board.title}</div>
                    <div className='info-wrap'>
                      <table>
                        <tr>
                          <th>프로모션 종류</th>
                          <td>
                            {enums &&
                              enums['benefit-type'].map((item: any, idx: any) => {
                                return item.key === board.type && item.value;
                              })}
                          </td>
                        </tr>
                        <tr>
                          <th>제공기간</th>
                          <td>
                            {StringUtil.formatter.date(board.activeStart, 'YYYY. MM. DD')} ~{' '}
                            {StringUtil.formatter.date(board.activeEnd, 'YYYY. MM. DD')}
                          </td>
                        </tr>
                        <tr>
                          <th>프로모션 내용</th>
                          <td>{board.content}</td>
                        </tr>
                        <tr>
                          <th>이용가능 등급</th>
                          <td>
                            {board.availableRank === 'ALL' && '전체'}
                            {board.availableRank === 'REGULAR' && '정회원'}
                            {board.availableRank === 'ASSOCIATE' && '준회원'}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='notice-contents'>
                  <div className='promotion-wrap'>
                    {/* <div className='img-wrap'>
                      <img
                        src={
                          board.promotionLetter ? board.promotionLetter.attach.path : defaultImgSrc
                        }
                        onError={(e) => {
                          e.currentTarget.src = defaultImgSrc;
                        }}
                        alt=''
                      />
                    </div> */}
                    <div className='desc-wrap'>
                      <div className='promotion'>
                        <div className='promotion-title'>기업 서비스 소개</div>
                        <div className='promotion-content'>{board.introduceCompanyAndService}</div>
                      </div>
                      <div className='promotion'>
                        <div className='promotion-title'>프로모션 상세 내용</div>
                        <div
                          className='promotion-content'
                          dangerouslySetInnerHTML={{ __html: board.detailContent }}
                        />
                      </div>
                      <div className='promotion'>
                        <div className='promotion-title'>프로모션 이용 방법</div>
                        <div className='promotion-content'>
                          {enums &&
                            enums['benefit-usage-option'].map(
                              (item: any) => item.key === board.usageOption && item.value,
                            )}
                          {board.usageOptionContent && `\n${board.usageOptionContent}`}
                        </div>
                      </div>
                      {board.caution && (
                        <div className='promotion'>
                          <div className='promotion-title'>유의사항</div>
                          <div className='promotion-content'>{board.caution}</div>
                        </div>
                      )}
                      <div className='promotion'>
                        <div className='promotion-title'>문의처</div>
                        <div className='promotion-content'>
                          <span>{board.inquiryName}</span>
                          <span>{board.inquiryGrade}</span>
                          <span>{board.inquiryEmail}</span>
                          <span>{board.inquiryPhone}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='btn-wrap'>
                  <a
                    className='btn-register'
                    href={board.usageButtonLink}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    이용하기
                  </a>
                </div>
              </div>
              <div className='notice-bottom'>
                <ul className={!board.prevId ? 'next' : ''}>
                  {board.prevId && (
                    <li>
                      <button
                        type='button'
                        className='btn-prev'
                        onClick={() => this.onClickAnotherBoardDetail(board.prevId)}
                      >
                        이전글
                      </button>
                    </li>
                  )}
                  {board.nextId && (
                    <li>
                      <button
                        type='button'
                        className='btn-next'
                        onClick={() => this.onClickAnotherBoardDetail(board.nextId)}
                      >
                        다음글
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({ common }: any) => ({
  enums: common.enums,
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MemberBenefitsDetailPage);
