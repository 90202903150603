export const navigationConfig: Menu[] = [
  {
    title: '코리아스타트업포럼',
    type: 'group',
    url: '/forum/introduce',
    children: [
      {
        title: '소개',
        type: 'item',
        url: '/forum/introduce',
      },
      {
        title: '선언문',
        type: 'item',
        url: '/forum/manifesto',
      },
      // {
      //   title: '연혁',
      //   type: 'item',
      //   url: '/forum/history',
      // },
      // {
      //   title: '의장 소개',
      //   type: 'item',
      //   url: '/forum/chairperson',
      // },
      {
        title: '함께하는 사람들',
        type: 'item',
        url: '/forum/organization',
      },
      {
        title: '공지사항',
        type: 'item',
        url: '/news/notice',
      },
      {
        title: '문의하기',
        type: 'item',
        url: '/forum/contact',
      },
      {
        title: 'CI',
        type: 'item',
        url: '/forum/ci',
      },
    ],
  },
  {
    title: '활동',
    type: 'group',
    url: '/news/schedule',
    children: [
      // {
      //   title: '공지사항',
      //   type: 'item',
      //   url: '/news/notice',
      // },
      // {
      //   title: '뉴스레터',
      //   type: 'item',
      //   url: '/news/newsletter',
      // },
      {
        title: '프로그램',
        type: 'item',
        url: '/news/schedule',
      },
      {
        title: '정책활동',
        type: 'item',
        url: '/news/library',
      },
      {
        title: '언론보도',
        type: 'item',
        url: '/news/news',
      },
    ],
  },
  {
    title: '회원사',
    type: 'group',
    url: '/membership/member',
    children: [
      {
        title: '회원사',
        type: 'item',
        url: '/membership/member',
      },
      {
        title: '가입안내',
        type: 'item',
        url: '/membership/join',
      },
      {
        title: '회원사 인터뷰',
        type: 'item',
        url: '/membership/interview',
      },
      {
        title: '회원사 혜택',
        type: 'item',
        url: '/membership/benefits',
      },
      // {
      //   title: '연회비 안내',
      //   type: 'item',
      //   url: '/membership/guide',
      // },
    ],
  },
  {
    title: '커뮤니티',
    type: 'group',
    url: '/community/cooperative',
    children: [
      {
        title: '협의회',
        type: 'item',
        url: '/community/conference',
      },
      {
        title: '협력제안',
        type: 'item',
        url: '/community/cooperative',
      },
      {
        title: '법률특허지원',
        type: 'item',
        url: '/community/legal-support',
      },
      // {
      //   title: '회원사 제휴/할인', // 제휴클럽
      //   type: 'item',
      //   url: '/community/affiliate',
      // },
      // {
      //   title: '회원사혜택 사용안내', // 혜택정보
      //   type: 'item',
      //   url: '/community/benefit',
      // },
    ],
  },
  {
    title: 'English',
    type: 'group',
    url: '/en/introduce',
    children: [
      {
        title: 'Introduction',
        type: 'item',
        url: '/en/introduce',
      },
      {
        title: 'Manifesto',
        type: 'item',
        url: '/en/manifesto',
      },
      {
        title: 'History',
        type: 'item',
        url: '/en/history',
      },
      {
        title: 'Chairperson',
        type: 'item',
        url: '/en/chairperson',
      },
      {
        title: 'Organization',
        type: 'item',
        url: '/en/organization',
      },
      {
        title: 'Contact',
        type: 'item',
        url: '/en/contact',
      },
    ],
  },
];

export type MenuItem = { title: string; type: 'group' | 'item'; url: string; auth?: boolean };

export interface Menu extends MenuItem {
  desc?: string;
  children?: MenuItem[];
}
