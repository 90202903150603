/* eslint-disable no-alert */
import React, { Component, ReactNode } from 'react';
import { withRouter } from 'react-router-dom';
import { TokenService } from '@services';
import { Footer, Header } from '@components';
import JoinCommonInfoPage from './JoinCommonInfoPage';
import '../JoinPage.scss';

interface JoinDetailPageProps {
  location: any;
  history: any;
  match: any;
}

class JoinDetailPage extends Component<JoinDetailPageProps, any> {
  constructor(props: JoinDetailPageProps) {
    super(props);

    this.state = {};
  }

  componentDidMount = (): void => {
    if (TokenService.getAccessToken()) {
      alert('이미 가입이 완료되어 있습니다');
      window.location.href = '/';
    }
  };

  render(): ReactNode {
    return (
      <div className='join-page-wrap'>
        <Header />
        <div className='apply-page'>
          <div className='section'>
            <div className='title-wrap'>
              <div className='left-contents title'>
                <h1>가입신청</h1>
                <p>Join</p>
              </div>
              <div className='right-contents'>
                <p>
                  온라인 가입신청 페이지 입니다. <br />
                  신청서 작성 후 전송하기 버튼을 눌러주세요. <br />
                  이사회 승인을 거쳐 최종 가입여부를 안내 드립니다. <br />
                  (가입 승인까지 1~2주 정도 소요될 수 있습니다.)
                </p>
                <span className='required'>표기는 필수입력사항입니다.</span>
              </div>
            </div>
            <div className='contents-wrap'>
              <JoinCommonInfoPage />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(JoinDetailPage);
