import moment from 'moment';
import { REGEX_EMAIL } from '@constants';

/**
 * Interfaces for StringUtil
 * @author deokchanjung
 */
interface StringFormatter {
  date: (value: string, format: string) => string;
  btoa: (value: string) => string;
}

interface StringValidator {
  email: (value: string) => boolean;
}

// =============================================================

const formatter: StringFormatter = {
  date: (value: string, format: string): string => moment(value).format(format),
  btoa: (value: string): string => btoa(unescape(encodeURIComponent(value))),
};

const validator: StringValidator = {
  email: (value: string): boolean => REGEX_EMAIL.test(value),
};

const parseTextArea = {
  newline: (value: string): string => value.replace(/\n/g, '<br/>'),
};

export default { formatter, validator, parseTextArea };
