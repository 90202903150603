import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

interface ScrollWithRouterProps {
  location: any;
}

class ScrollWithRouter extends Component<ScrollWithRouterProps | any> {
  componentDidUpdate(prevProps: any) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    // eslint-disable-next-line react/jsx-fragments
    return <React.Fragment />;
  }
}

export default withRouter(ScrollWithRouter);
