/* eslint-disable no-useless-escape */

/**
 * 숫자 정규식
 */
export const REGEX_NUMBER: RegExp = /[^0-9]/g;

/**
 * MOBILE, TEL 정규식
 */
export const REGEX_TEL: RegExp = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

/**
 * 공백체크 정규식
 */
export const REGEX_SPACE: RegExp = /\s/g;

/**
 * 천단위 콤마 정규식
 */
export const REGEX_NUMBER_COMMA: RegExp = /\B(?=(\d{3})+(?!\d))/g;

/**
 * 10만 이상 단위 입력 방지 정규식
 */
export const REGEX_SIX_HUNDRED_THOUSAND: RegExp = /^\d{6}$/g;

/**
 * 소수점 두 자리 이상 입력방지 정규식
 */
export const REGEX_HUNDREDS_PLACE_VALUE: RegExp = /(^\d+$)|(^\d+\.\d{0,2}$)/g;

/**
 * 소수점이 두 번 이상 중복 입력 방지 정규식
 */
export const REGEX_IGNORE_DUPLICATE_DOTS: RegExp = /^\d*[.]\d*$/g;

/**
 * Email 정규식
 */
export const REGEX_EMAIL: RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * URL 정규식
 */
export const REGEX_URL: RegExp = /^(((http(s?))\:\/\/)?)([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?/;

/**
 * PASSWORD
 */
// 비밀번호 정규식 1 : 숫자, 영문 조합 6~16
export const REGEX_PASSWORD_NUM_ENG: RegExp = /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,16}$/;

// 비밀번호 정규식 2 : 숫자, 특수문자 조합 8~16
export const REGEX_PASSWORD_NUM_SPE: RegExp = /^(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/;

// 비밀번호 정규식 3 : 영문, 특수문자 조합 8~16
export const REGEX_PASSWORD_ENG_SPE: RegExp = /^(?=.*[^a-zA-Z0-9])(?=.*[0-9]).{8,16}$/;

// 비밀번호 정규식 4 : 대소문자, 숫자, 영문, 특수문자 조합 6~16
export const REGEX_PASSWORD_ENG_NUM_SPE: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{6,16}$/;
