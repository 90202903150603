import React, { Component, ReactNode } from 'react';
import { withRouter } from 'react-router-dom';
import './Map.scss';

declare global {
  interface Window {
    kakao: any;
  }
}
const { kakao } = window;

class Map extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount = (): void => {
    const container = document.getElementById('map');
    const options = {
      center: new kakao.maps.LatLng(37.4954543973812, 127.038858291112),
      level: 5,
    };
    const map = new window.kakao.maps.Map(container, options);
    const markerPosition = new kakao.maps.LatLng(37.4954543973812, 127.038858291112);
    const marker = new kakao.maps.Marker({
      position: markerPosition,
    });
    marker.setMap(map);
  };

  render(): ReactNode {
    return <div id='map' />;
  }
}

export default withRouter(Map);
