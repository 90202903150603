const SHARE_MAP = {
  LOCALE: 'ko',
  PROJECT_SYMBOL: 'koreastartupforum',
};

const ENV_MAP = {
  development: {
    // local address : https://dev-api.kstartupforum.org
    // product address : https://api.kstartupforum.org
    API_URL: 'https://dev-api.kstartupforum.org',
  },
  production: {
    API_URL: 'https://dev-api.kstartupforum.org',
  },
};

export default {
  ...SHARE_MAP,
  // ...ENV_MAP[process.env.NODE_ENV]
  ...(process.env.NODE_ENV === 'production' ? ENV_MAP.production : ENV_MAP.development),
};
