export const SET_ENUMS = '/COMMON/ENUMS/SET_ENUMS';
export const SET_BUSINESS = '/COMMON/BUSINESS/SET_BUSINESS';

export const setEnums = (enums) => ({
  type: SET_ENUMS,
  payload: enums,
});

export const setBusiness = (business) => {
  return {
    type: SET_BUSINESS,
    payload: business,
  };
};
