import React from 'react';
import '../JoinPage.scss';

const JoinResultPopup = () => {
  return (
    <div className='popup-container'>
      <div className='join-result popup'>
        <div className='content'>
          <p className='title'>
            회원가입 신청이 접수되었습니다.
            <br />
            이사회 승인을 거쳐 최종 가입여부를 안내 드립니다.
          </p>
          <p className='tip'>(가입 승인까지 1~2주 정도 소요될 수 있습니다.)</p>
        </div>
        <div className='button-area'>
          <a href='/membership/join/result/success' className='btn-result'>
            확인
          </a>
        </div>
      </div>
    </div>
  );
};

export default JoinResultPopup;
