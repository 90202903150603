export enum RankType {
  ASSOCIATE = 'ASSOCIATE',
  REGULAR_CHAIR = 'REGULAR_CHAIR',
  REGULAR_DIRECTOR = 'REGULAR_DIRECTOR',
  REGULAR_COMMITTEE = 'REGULAR_COMMITTEE',
  REGULAR_NORMAL = 'REGULAR_NORMAL',
  SPECIAL = 'SPECIAL',
  COOPERATION = 'COOPERATION',
}

export enum GenderType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NONE = 'NONE',
}

export enum InvestmentStepType {
  SUPPORT_FUND = 'SUPPORT_FUND',
  SEED = 'SEED',
  PRE_A = 'PRE_A',
  PRE_B = 'PRE_B',
  SERIES_A = 'SERIES_A',
  SERIES_B = 'SERIES_B',
  SERIES_C = 'SERIES_C',
  SERIES_D = 'SERIES_D',
  SERIES_E = 'SERIES_E',
  SERIES_F = 'SERIES_F',
  PRE_IPO = 'PRE_IPO',
  IPO = 'IPO',
  M_AND_A = 'M_AND_A',
  PRIVATE = 'PRIVATE',
  NO_PLAN = 'NO_PLAN',
  NO_EXPERIENCE = 'NO_EXPERIENCE',
  NONE = 'NONE',
}

export enum ChargeTaskType {
  MONEY = 'MONEY',
  FORUM = 'FORUM',
  ETC = 'ETC',
  NONE = 'NONE',
}

export enum InvestmentSourceType {
  ANGEL = 'ANGEL',
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  INSTITUTIO = 'INSTITUTIO',
  NONE = 'NONE',
}

// export enum EmployeeRangeType {
//   FIVE_MORE = 'FIVE_MORE',
//   TWENTY_MORE = 'TWENTY_MORE',
//   FIFTY_MORE = 'FIFTY_MORE',
//   ONE_HUNDRED_MORE = 'ONE_HUNDRED_MORE',
//   TWO_HUNDRED_MORE = 'TWO_HUNDRED_MORE',
//   FIVE_HUNDRED_MORE = 'FIVE_HUNDRED_MORE',
//   NONE = 'NONE',
// }

export enum GlobalExpansionType {
  PROGRESS = 'PROGRESS',
  PREPARE = 'PREPARE',
  CONSIDER = 'CONSIDER',
  NO_PLAN = 'NO_PLAN',
  NONE = 'NONE',
}

export enum JoinPathType {
  NONE = 'NONE',
  ACQUAINTANCE = 'ACQUAINTANCE',
  PORTAL = 'PORTAL',
  SNS = 'SNS',
  ETC = 'ETC',
}
