import {
  IntroduceEnPage,
  ManifestoEnPage,
  HistoryEnPage,
  ChairpersonEnPage,
  OrganizationEnPage,
  LocationEnPage,
} from '@pages';
import { RouteProps } from 'react-router-dom';

const EnglishouteConfigs: RouteProps[] = [
  {
    exact: true,
    path: '/en/introduce',
    component: IntroduceEnPage,
  },
  {
    exact: true,
    path: '/en/manifesto',
    component: ManifestoEnPage,
  },
  {
    exact: true,
    path: '/en/history',
    component: HistoryEnPage,
  },
  {
    exact: true,
    path: '/en/chairperson',
    component: ChairpersonEnPage,
  },
  {
    exact: true,
    path: '/en/organization',
    component: OrganizationEnPage,
  },
  {
    exact: true,
    path: '/en/contact',
    component: LocationEnPage,
  },
];

export default EnglishouteConfigs;
