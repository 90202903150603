import { SET_USERINFO } from '@store/actions/userAction';

const initialState = {};

const user = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_USERINFO:
      return {
        ...payload,
      };
    default:
      return state;
  }
};

export default user;
