import { REGEX_NUMBER_COMMA } from '@constants';

/**
 * Interfaces for NumberUtil
 * @author deokchanjung
 */
interface NumberFormatter {
  commas: (value: number) => string;
}

interface NumberValidator {
  isLowerMinLength: (value: number, length: number) => boolean;
  isUpperMaxLength: (value: number, length: number) => boolean;
}

// =============================================================

const formatter: NumberFormatter = {
  commas: (value: number = 0): string => value.toString().replace(REGEX_NUMBER_COMMA, ','),
};

const validator: NumberValidator = {
  isLowerMinLength: (value: number, length: number): boolean => value.toString().length < length,
  isUpperMaxLength: (value: number, length: number): boolean => value.toString().length > length,
};

export default { formatter, validator };
