import { RouteProps } from 'react-router-dom';
import {
  NoticeListPage,
  NoticeDetailPage,
  NewsLetterListPage,
  NewsLetterDetailPage,
  NewsDetailPage,
  ScheduleListPage,
  ScheduleDetailPage,
  ScheduleRegPage,
  ScheduleConfirmPage,
  ScheduleCompletePage,
  ScheduleUpdatePage,
  NewsListPage,
  LibraryListPage,
  LibraryDetailPage,
} from '@pages';

const NewsRouteConfigs: RouteProps[] = [
  {
    exact: true,
    path: '/news/notice',
    component: NoticeListPage,
  },
  {
    exact: true,
    path: '/news/notice/:id',
    component: NoticeDetailPage,
  },
  {
    exact: true,
    path: '/news/newsletter',
    component: NewsLetterListPage,
  },
  {
    path: '/news/newsletter/:id',
    component: NewsLetterDetailPage,
  },
  {
    exact: true,
    path: '/news/schedule',
    component: ScheduleListPage,
  },
  {
    path: '/news/schedule/:id/confirm/:userId',
    component: ScheduleConfirmPage,
  },
  {
    path: '/news/schedule/:id/complete/:userId',
    component: ScheduleCompletePage,
  },
  {
    path: '/news/schedule/:id/update/:userId',
    component: ScheduleUpdatePage,
  },
  {
    path: '/news/schedule/:id/register',
    component: ScheduleRegPage,
  },
  {
    path: '/news/schedule/:id',
    component: ScheduleDetailPage,
  },
  {
    exact: true,
    path: '/news/news',
    component: NewsListPage,
  },
  {
    path: '/news/news/:id',
    component: NewsDetailPage,
  },
  {
    exact: true,
    path: '/news/library',
    component: LibraryListPage,
  },
  {
    path: '/news/library/:id',
    component: LibraryDetailPage,
  },
];

export default NewsRouteConfigs;
