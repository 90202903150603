import {
  Account,
  FindPassword,
  FindUserName,
  UserNameType,
  ChangePwType,
  AccountUpdate,
  AccountGet,
} from '@types';
import { StringUtil } from '@utils';
import api from './apiService';

class UserService {
  static getAuthInfo = async (): Promise<Account> => {
    const url: string = `/auth/info`;

    try {
      const { data } = await api.get(url);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getBusinessList = async (): Promise<any> => {
    const url: string = `/user/business`;

    try {
      const response: Account = await api.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static checkRegistration = async (data: string): Promise<any> => {
    const url: string = `/user/check/registration-number`;

    const payload = {
      username: data,
    };

    try {
      const response = await api.post(url, payload);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static registerUser = async (data: Account): Promise<any> => {
    const encodingCeoList: any = [];
    data.ceoList.forEach((x) => {
      encodingCeoList.push({
        ceoName: StringUtil.formatter.btoa(x.ceoName),
        ceoEmail: StringUtil.formatter.btoa(x.ceoEmail),
        ceoMobile: StringUtil.formatter.btoa(x.ceoMobile),
        gender: StringUtil.formatter.btoa(x.gender),
        ageYear: StringUtil.formatter.btoa(x.ageYear ? x.ageYear : ''),
      });
    });
    const encodingChargeList: any = [];
    data.chargeList.forEach((x) => {
      encodingChargeList.push({
        chargeName: StringUtil.formatter.btoa(x.chargeName),
        chargeEmail: StringUtil.formatter.btoa(x.chargeEmail),
        chargeMobile: StringUtil.formatter.btoa(x.chargeMobile),
        chargeTask: x.chargeTask,
        chargeTaskText: x.chargeTaskText,
      });
    });
    const payload = {
      ...data,
      ceoList: encodingCeoList,
      chargeList: encodingChargeList,
      username: StringUtil.formatter.btoa(data.username),
      password: StringUtil.formatter.btoa(data.password),
    };
    const url: string = '/user';

    try {
      await api.post(url, payload);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static updateUser = async (data: AccountUpdate): Promise<any> => {
    const encodingCeoList: any = [];
    data.ceoList.forEach((x) => {
      encodingCeoList.push({
        ceoName: StringUtil.formatter.btoa(x.ceoName),
        ceoEmail: StringUtil.formatter.btoa(x.ceoEmail),
        ceoMobile: StringUtil.formatter.btoa(x.ceoMobile),
        gender: StringUtil.formatter.btoa(x.gender),
        ageYear: StringUtil.formatter.btoa(x.ageYear ? x.ageYear : ''),
        newsletterFlag: x.newsletterFlag ? x.newsletterFlag : '',
      });
    });
    const encodingChargeList: any = [];
    data.chargeList.forEach((x) => {
      encodingChargeList.push({
        chargeName: StringUtil.formatter.btoa(x.chargeName),
        chargeEmail: StringUtil.formatter.btoa(x.chargeEmail),
        chargeMobile: StringUtil.formatter.btoa(x.chargeMobile),
        chargeTask: x.chargeTask,
        chargeTaskText: x.chargeTaskText,
        newsletterFlag: x.newsletterFlag ? x.newsletterFlag : '',
      });
    });
    const payload = {
      ...data,
      ceoList: encodingCeoList,
      chargeList: encodingChargeList,
      username: StringUtil.formatter.btoa(data.username),
    };
    const url: string = `/auth/info`;

    try {
      await api.put(url, payload);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static findUserName = async (data: FindUserName): Promise<any> => {
    const payload = {
      ceoName: data.ceoName,
      companyName: data.companyName,
    };
    const url: string = `/user/find/username`;

    try {
      const response = await api.post(url, payload);
      console.log('res : ', response);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static findPassword = async (data: FindPassword): Promise<any> => {
    const payload = {
      username: StringUtil.formatter.btoa(data.username),
      companyName: data.companyName,
    };
    const url: string = `/user/find/password`;

    try {
      const response = await api.post(url, payload);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static doubleCheckUserName = async (data: UserNameType): Promise<any> => {
    const payload = {
      ...data,
      username: StringUtil.formatter.btoa(data.username),
    };
    const url: string = '/user/duplicate/username';

    try {
      const response = await api.post(url, payload);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static changePassword = async (data: ChangePwType): Promise<any> => {
    const payload = {
      originPassword: StringUtil.formatter.btoa(data.originPassword),
      password: StringUtil.formatter.btoa(data.password),
    };
    const url: string = `/auth/password`;

    try {
      const response = await api.put(url, payload);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static deleteUser = async (): Promise<any> => {
    const url: string = `/auth/info`;

    try {
      await api.delete(url);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getUser = async (id: number): Promise<AccountGet> => {
    const url = `/user/${id}`;

    try {
      const response = await api.get(url);
      // console.log(response);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getUserList = async (conditions: any): Promise<any> => {
    const url = `/user?${api.qs(conditions)}`;

    try {
      const { page: response } = await api.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static updateTempAccount = async (seq: number, data: any): Promise<any> => {
    const url: string = `/user/account/${seq}/temp`;

    try {
      const response = await api.put(url, data);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default UserService;
