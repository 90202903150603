import React from 'react';
import './NotSearch.scss';
import imgSrc from '../../assets/images/icon/icon-warning.png';

const NotSearch = ({ name }: any) => {
  return (
    <div className={`search-noti ${name}`}>
      <div className='search-noti-con'>
        <img src={imgSrc} alt='검색없음' />
        <p>조회되는 내용이 없습니다.</p>
      </div>
    </div>
  );
};

export default NotSearch;
