import { RouteProps } from 'react-router-dom';
import {
  CooperativeListPage,
  CooperativeDetailPage,
  CooperativeCreatePage,
  CooperativeEditPage,
  ConsultationListPage,
  ConsultationDetailPage,
  ConsultationCreatePage,
  AffiliateListPage,
  AffiliateDetailPage,
  BenefitListPage,
  BenefitDetailPage,
  ConferencePage,
  ConferenceCreatePage,
  LegalSupportListPage,
  LegalSupportDetailPage,
  LegalSupportCreatePage,
  LegalSupportEditPage,
} from '@pages';

const CommunityRouteConfigs: RouteProps[] = [
  {
    exact: true,
    path: '/community/conference',
    component: ConferencePage,
  },
  {
    exact: true,
    path: '/community/conference/create',
    component: ConferenceCreatePage,
  },
  {
    exact: true,
    path: '/community/cooperative',
    component: CooperativeListPage,
  },
  {
    exact: true,
    path: '/community/cooperative/create',
    component: CooperativeCreatePage,
  },
  {
    path: '/community/cooperative/update/:id',
    component: CooperativeEditPage,
  },
  {
    path: '/community/cooperative/:id',
    component: CooperativeDetailPage,
  },
  {
    exact: true,
    path: '/community/consultation',
    component: ConsultationListPage,
  },
  {
    exact: true,
    path: '/community/consultation/create',
    component: ConsultationCreatePage,
  },
  {
    path: '/community/consultation/update/:id',
    component: ConsultationCreatePage,
  },
  {
    path: '/community/consultation/:id',
    component: ConsultationDetailPage,
  },
  {
    exact: true,
    path: '/community/affiliate',
    component: AffiliateListPage,
  },
  {
    path: '/community/affiliate/:id',
    component: AffiliateDetailPage,
  },
  {
    exact: true,
    path: '/community/benefit',
    component: BenefitListPage,
  },
  {
    path: '/community/benefit/:id',
    component: BenefitDetailPage,
  },
  {
    exact: true,
    path: '/community/legal-support',
    component: LegalSupportListPage,
  },
  {
    exact: true,
    path: '/community/legal-support/create',
    component: LegalSupportCreatePage,
  },
  {
    exact: true,
    path: '/community/legal-support/update/:id',
    component: LegalSupportEditPage,
  },
  {
    path: '/community/legal-support/:id',
    component: LegalSupportDetailPage,
  },
];

export default CommunityRouteConfigs;
