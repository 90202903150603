import axios, { AxiosRequestConfig, AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import env from '@env';
import { ApiError } from '@types';
import { ERR_EXPIRED_JWT, ERR_ACCESS_DENIED, ERR_UNAUTHENTICATED } from '@constants';
import tokenService from './tokenService';

/**
 * API 호출관련 서비스 로직
 * @author deokchanjung(deokchan@nextculture.kr)
 */
class ApiService {
  private isOnRefreshing: boolean;

  private requestQueue: any[];

  private api: AxiosInstance;

  constructor() {
    this.isOnRefreshing = false;
    this.requestQueue = [];
    this.api = axios.create({
      baseURL: env.API_URL,
      validateStatus: (status: number): boolean => status < 400,
    });
    this.api.interceptors.response.use(this.responseSuccessHandler, this.responseErrorHandler);
  }

  /**
   * 성공한 요청에 대한 응답 결과 핸들링 함수
   * @param {AxiosResponse | Promise<AxiosResponse>} response 성공 응답 객체
   */
  private responseSuccessHandler = (
    response: AxiosResponse,
  ): AxiosResponse | Promise<AxiosResponse> => {
    const { data } = response;
    return data || response;
  };

  /**
   * 실패한 요청에 대한 응답 결과 핸들링 함수
   * @param {AxiosError} error 에러 응답 객체
   */
  private responseErrorHandler = async (error: AxiosError): Promise<any> => {
    const { config, response } = error;

    if (!response) {
      return Promise.reject(error.toJSON());
    }

    if (response.data) {
      const { error_code } = response.data as ApiError;

      switch (error_code) {
        case ERR_EXPIRED_JWT: {
          try {
            if (this.isOnRefreshing) {
              return this.pushRequestToReqestQueue(
                async (token: string): Promise<any> => {
                  const resp = await this.retryRequest(config, token);
                  return Promise.resolve(this.responseSuccessHandler(resp));
                },
              );
            }

            this.isOnRefreshing = true;

            const token = await tokenService.refresh();
            this.onRefreshed(token);
            this.isOnRefreshing = false;

            const resp = await this.retryRequest(config, token);
            return Promise.resolve(this.responseSuccessHandler(resp));
          } catch (error) {
            this.isOnRefreshing = false;
            this.redirect();
            return Promise.reject(error);
          }
        }
        case ERR_ACCESS_DENIED: {
          this.isOnRefreshing = false;
          this.redirect();
          return Promise.reject();
        }
        case ERR_UNAUTHENTICATED: {
          this.isOnRefreshing = false;
          this.redirect();
          return Promise.reject();
        }
        default: {
          return Promise.reject(response.data);
        }
      }
    } else {
      return Promise.reject(error.toJSON());
    }
  };

  private retryRequest = (config: AxiosRequestConfig, token: string): Promise<any> => {
    const originRequest = config;

    if (originRequest.headers.Authorization) {
      originRequest.headers.Authorization = `Bearer ${token}`;
    }
    return axios(originRequest);
  };

  private pushRequestToReqestQueue = (request: any): Promise<any> => {
    this.requestQueue.push(request);
    return this.requestQueue[this.requestQueue.length - 1];
  };

  private onRefreshed = (token: string): void => {
    [...this.requestQueue].map((request) => request(token));
    this.requestQueue = [];
  };

  private redirect = (): Promise<void> => {
    tokenService.cleanTokens();
    window.location.href = '/login';
    return Promise.reject();
  };

  /**
   * 요청 정보 반환 함수
   * @param {boolean} isSecureReqest 보안요청 여부
   * @param {AxiosRequestConfig} customConfig 요청정보에 추가적으로 담을 옵션
   */
  private getRequestConfig = (
    isSecureReqest: boolean,
    customConfig?: AxiosRequestConfig,
  ): AxiosRequestConfig => {
    let config: AxiosRequestConfig = {};

    if (customConfig) {
      config = Object.assign(config, customConfig);
    }

    if (isSecureReqest) {
      const accessToken: string | null = tokenService.getAccessToken();

      if (accessToken) {
        config.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      }
    }
    return config;
  };

  /**
   * GET 요청
   * @param {string} url 요청을 보낼 주소
   * @param {any} payload 요청시 담을 데이터(HTTP Body)
   * @param {boolean} isSecureReqest 보안요청 여부
   */
  public get = (url: string, payload: any = null, isSecureReqest: boolean = true): Promise<any> => {
    const config: AxiosRequestConfig = this.getRequestConfig(isSecureReqest, {
      data: payload,
    });
    return this.api.get(url, config);
  };

  /**
   * POST 요청
   * @param {string} url 요청을 보낼 주소
   * @param {any} payload 요청시 담을 데이터(HTTP Body)
   * @param {boolean} isSecureReqest 보안요청 여부
   */
  public post = (
    url: string,
    payload: any = null,
    isSecureReqest: boolean = true,
  ): Promise<any> => {
    const config: AxiosRequestConfig = this.getRequestConfig(isSecureReqest);
    return this.api.post(url, payload, config);
  };

  /**
   * PATCH 요청
   * @param {string} url 요청을 보낼 주소
   * @param {any} payload 요청시 담을 데이터(HTTP Body)
   * @param {boolean} isSecureReqest 보안요청 여부
   */
  public patch = (
    url: string,
    payload: any = null,
    isSecureReqest: boolean = true,
  ): Promise<any> => {
    const config: AxiosRequestConfig = this.getRequestConfig(isSecureReqest);
    return this.api.patch(url, payload, config);
  };

  /**
   * PUT 요청
   * @param {string} url 요청을 보낼 주소
   * @param {any} payload 요청시 담을 데이터(HTTP Body)
   * @param {boolean} isSecureReqest 보안요청 여부
   */
  public put = (url: string, payload: any = null, isSecureReqest: boolean = true): Promise<any> => {
    const config: AxiosRequestConfig = this.getRequestConfig(isSecureReqest);
    return this.api.put(url, payload, config);
  };

  /**
   * DELETE 요청
   * @param {string} url 요청을 보낼 주소
   * @param {boolean} isSecureReqest 보안요청 여부
   */
  public delete = (url: string, isSecureReqest: boolean = true): Promise<any> => {
    const config: AxiosRequestConfig = this.getRequestConfig(isSecureReqest);
    return this.api.delete(url, config);
  };

  /**
   * 파일 핸들링 관련 요청이나, ApiService의 기본적인
   * get/post/put/delete 함수가 지원하지 않는 형태의 요청시 사용
   * @param {AxiosRequestConfig} config 요청정보
   */
  public request = (config: AxiosRequestConfig): Promise<any> => {
    return this.api.request(config);
  };

  /**
   * 1Depth를 갖는 object를 query string으로 변환해주는 함수
   * @param {*} object 변환하고자 하는 object
   * @returns 변환된 query string
   * @author deokchan jung
   */
  public qs = (object: any = {}): string => {
    if (typeof object !== 'object') throw new Error('The object must be object type.');

    const isNotEmpty = (value: any): boolean =>
      value !== undefined && value !== null && value !== '';

    const keys = Object.keys(object).filter((key) => isNotEmpty(object[key]));

    return keys.reduce(
      (acc, key, index, arr) =>
        acc +
        key.concat('=', object[key] !== undefined && object[key] !== null ? object[key] : '') +
        (index !== arr.length - 1 ? '&' : ''),
      '',
    );
  };
}

export default new ApiService();
