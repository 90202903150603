import apiService from './apiService';

class CommonService {
  getBusinessList = () =>
    new Promise((resolve, reject) => {
      const url = '/user/business';

      apiService
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

  getEnums = () =>
    new Promise((resolve, reject) => {
      const url = '/enum';

      apiService
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

  uploadFile = async (files, options) => {
    const FILE_KEY = 'files';
    const url = '/file';
    const formData = new FormData();

    if (files instanceof Array) {
      files.forEach((file) => formData.append(FILE_KEY, file));
    } else {
      formData.append(FILE_KEY, files);
    }

    Object.keys(options).forEach((key) => options[key] && formData.append(key, options[key]));

    const requestConfig = {
      url,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const data = await apiService.request(requestConfig);

    return data;
  };

  getFileInfo = async (id) => {
    const url = `/file/${id}`;
    const data = await apiService.get(url);
    return data;
  };

  deleteFile = async (id) => {
    const url = `file/${id}`;
    const response = await apiService.delete(url);
    return response;
  };

  downLink = (fileUrl) => {
    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
      fetch(fileUrl)
        .then((resp) => resp.arrayBuffer())
        .then((resp) => {
          const file = new Blob([resp], { type: 'application/pdf' });
          window.navigator.msSaveBlob(file, fileUrl.replace('/', ''));
        });
    } else {
      fetch(fileUrl).then((response) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileUrl.replace('/', '');
          a.click();
        });
      });
    }
  };
}

export default new CommonService();
