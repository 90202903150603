import { CommonService } from '@services';
import React, { Component, ReactNode } from 'react';
import logo from '../../assets/images/logo-white.png';
import './Footer.scss';

class Footer extends Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  onClickFacebook = (): void => {
    window.open('https://ko-kr.facebook.com/koreastartupforum/');
  };

  onClickNotion = (): void => {
    window.open('https://koreastartupforum.oopy.io');
  };

  onClickYoutube = (): void => {
    window.open('https://www.youtube.com/@koreastartupforumk9287/featured');
  };

  onClickKakao = (): void => {
    window.open('https://pf.kakao.com/_YxcNej');
  };

  render(): ReactNode {
    return (
      <footer>
        <div className='footer-wrap'>
          <div className='logo-wrap'>
            <img src={logo} alt='' />
          </div>
          <div className='desc-wrap'>
            <ul className='menu-wrap'>
              <li>
                <a href='/news/notice'>공지사항</a>
              </li>
              <li>
                <a href='/membership/join'>회원가입 안내</a>
              </li>
              <li>
                <a href='https://page.stibee.com/subscriptions/58573'>뉴스레터 구독</a>
              </li>
              <li>
                <a href='/privacy-policy'>개인정보처리방침</a>
              </li>
              <li>
                <button
                  type='button'
                  onClick={() => {
                    CommonService.downLink('/2023 코리아스타트업포럼 백서.pdf');
                  }}
                >
                  2023 코리아스타트업포럼 백서
                  <i className='ic-down-w' />
                </button>
              </li>
              <li>
                <a
                  href='https://drive.google.com/drive/folders/1ednLnRdIm3h3Uu7_Jd6DpZgflosKf-F2'
                  target='_blank'
                  rel='noreferrer'
                >
                  코스포 소개서 다운로드
                  <i className='ic-down-w' />
                </a>
              </li>
            </ul>
            <div className='info-wrap'>
              <div className='address-wrap'>
                <div className='company-name'>(사)코리아스타트업포럼</div>
                <div className='company-info'>
                  <div className='email'>사무국 hi@kstartupforum.org, 02-6211-9400</div>
                  {/* <div className='tel'>사무국 02-6211-9400</div> */}
                </div>
                <div className='m-company-info'>
                  <div className='email'>이메일 hi@kstartupforum.org</div>
                  <div className='tel'>사무국 02-6211-9400</div>
                </div>
                <div className='date'>
                  06248 서울특별시 강남구 역삼로 180, 5층
                  <br />
                  사업자등록번호 741-82-00164 대표 한상우
                </div>
              </div>
              <div className='channel-wrap'>
                <ul>
                  <li>
                    <button
                      type='button'
                      aria-label='facebook'
                      className='facebook'
                      onClick={this.onClickFacebook}
                    />
                  </li>
                  <li>
                    <button
                      type='button'
                      aria-label='notion'
                      className='notion'
                      onClick={this.onClickNotion}
                    />
                  </li>
                  <li>
                    <button
                      type='button'
                      aria-label='youtube'
                      className='youtube'
                      onClick={this.onClickYoutube}
                    />
                  </li>
                  <li>
                    <button
                      type='button'
                      aria-label='kakao'
                      className='kakao'
                      onClick={this.onClickKakao}
                    />
                  </li>
                </ul>
                <div className='copy-right'>
                  Copyright 2022. Korea Startup Forum. all rights reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
