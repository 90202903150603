import React, { Component, ReactNode } from 'react';
import { withRouter } from 'react-router-dom';
import './LoginPopup.scss';

interface LoginPopupProps {
  location: any;
  history: any;
  match: any;
  isVisiblePopup: any;
  openVisiblePopup: any;
  closeVisiblePopup: any;
}

class LoginPopup extends Component<LoginPopupProps, any> {
  render(): ReactNode {
    const { isVisiblePopup, closeVisiblePopup } = this.props;
    return (
      <>
        {isVisiblePopup ? (
          <>
            <div className='dimmed' />
            <div className='popup-login'>
              <div className='top'>
                <button type='button' className='btn-modal-cancel' onClick={closeVisiblePopup}>
                  <h1>닫기</h1>
                </button>
                <p className='title'>로그인 안내</p>
                <span className='sub-title'>
                  해당 메뉴는 로그인한 회원에게 제공되는 화면입니다.
                  <br />
                  로그인 후 이용해주세요.
                </span>
              </div>
              <div className='bottom'>
                <p className='title'>코리아스타트업포럼 회원사 </p>
                <span className='sub-title'>
                  스타트업 및 액셀러레이터,
                  <br />
                  VC, 스타트업 협업 희망 기업
                </span>
                <div className='button-area'>
                  <a href='/login' className='btn-modal-login'>
                    로그인
                  </a>
                </div>
                <p className='info'>
                  아직 회원이 아니신가요?
                  <a href='/join' className='btn-join'>
                    회원가입 안내
                  </a>
                </p>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default withRouter(LoginPopup);
