import api from './apiService';

class MainService {
  static getBannerList = async (conditions: any) => {
    const url: string = `/main/banner?${api.qs(conditions)}`;

    try {
      const { page: response } = await api.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getCount = async () => {
    const url: string = `/main/count/1`;

    try {
      const { data: response } = await api.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default MainService;
