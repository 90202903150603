import { AttachFile } from '@types';
import { AxiosRequestConfig } from 'axios';
import apiService from './apiService';

/**
 * 파일 업로드 관련 서비스로직
 * @author deokchanjung(deokchan@nextculture.kr)
 */
class FileService {
  /**
   * 파일 업로드
   * @param files 업로드할 파일객체 또는 파일배열
   * @param options 업로드 파일 옵션
   */
  public upload = async (files: any | Array<any>): Promise<Array<AttachFile>> => {
    const FILE_KEY: string = 'files';
    const url: string = 'file';
    const formData: FormData = new FormData();

    if (files instanceof Array) {
      files.forEach((file: any) => formData.append(FILE_KEY, file));
    } else {
      formData.append(FILE_KEY, files);
    }

    // Object.keys(options).forEach(
    //   (key: string): void => options[key] && formData.append(key, options[key]),
    // );

    const requestConfig: AxiosRequestConfig = {
      url,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      const { list } = await apiService.request(requestConfig);

      return Promise.resolve(list);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public info = async (id: number): Promise<AttachFile> => {
    try {
      const url: string = `file/${id}`;
      const { data } = await apiService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 파일 존재여부 확인
   * @param id 확인할 파일 고유번호
   */
  public isExist = async (id: number): Promise<boolean> => {
    const url: string = `file/${id}`;
    const is: boolean = await apiService.get(url);
    return is;
  };

  /**
   * 파일 삭제
   * @param id 삭제할 파일 고유번호
   */
  public delete = async (id: number): Promise<any> => {
    const url: string = `file/${id}`;
    try {
      await apiService.delete(url);
      return Promise.resolve;
    } catch (error) {
      return Promise.reject();
    }
  };

  public loadImgDataSource = (file: File): Promise<string> =>
    new Promise((resolve) => {
      const reader: FileReader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = (): void => {
        resolve(`data:${file.type};base64,${btoa(reader.result as any)}`);
      };

      reader.onerror = (): void => {
        resolve('');
      };
    });
}

export default new FileService();
