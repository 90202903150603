import {
  Manifesto,
  IntroducePage,
  HistoryPage,
  ChairpersonPage,
  ContactPage,
  OrganizationPage,
  CiPage,
  CiPageBefore,
} from '@pages';
import { RouteProps } from 'react-router-dom';

const ForumRouteConfigs: RouteProps[] = [
  {
    exact: true,
    path: '/forum/introduce',
    component: IntroducePage,
  },
  {
    exact: true,
    path: '/forum/manifesto',
    component: Manifesto,
  },
  {
    exact: true,
    path: '/forum/history',
    component: HistoryPage,
  },
  {
    exact: true,
    path: '/forum/chairperson',
    component: ChairpersonPage,
  },
  {
    exact: true,
    path: '/forum/contact',
    component: ContactPage,
  },
  {
    exact: true,
    path: '/forum/organization',
    component: OrganizationPage,
  },
  {
    exact: true,
    path: '/forum/ci',
    component: CiPage,
  },
  {
    exact: true,
    path: '/forum/ci/ci_1.0ver',
    component: CiPageBefore,
  },
];

export default ForumRouteConfigs;
