import { BoardType, BoardConditions, EventConditions, EventData, AnnualFeeParams } from '@types';
import api from './apiService';

class BoardService {
  static getBoardList = async (boardType: BoardType, conditions: BoardConditions) => {
    const url: string = `/community/${boardType}?${api.qs(conditions)}`;

    try {
      const { page: response } = await api.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getBenefitList = async (conditions: any) => {
    const url: string = `/benefit/search/approval?${api.qs(conditions)}`;

    try {
      const response = await api.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getCooperative = async (conditions: any) => {
    const url: string = `/cooperative/search?${api.qs(conditions)}`;

    try {
      const response = await api.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getBenefitDetail = async (id: number) => {
    const url: string = `/benefit/${id}`;

    try {
      const response = await api.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getLegalSupportList = async (conditions: any) => {
    const url: string = `/legal-support/search?${api.qs(conditions)}`;

    try {
      const response = await api.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getLegalSupportDetail = async (id: number) => {
    const url: string = `/legal-support/${id}`;

    try {
      const response = await api.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static legalSupportHit = async (id: number) => {
    const url: string = `/legal-support/${id}/hit`;

    try {
      const response = await api.patch(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static cooperativeHit = async (id: number) => {
    const url: string = `/cooperative/${id}/hit`;

    try {
      const response = await api.patch(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getEventList = async (conditions: EventConditions, data: EventData) => {
    const url: string = `/event?${api.qs(conditions)}`;

    try {
      const { data: response } = await api.post(url, data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getMyProgramList = async (conditions: EventConditions, data: EventData) => {
    const url: string = `/pariticipant/event?${api.qs(conditions)}`;

    try {
      const { data: response } = await api.post(url, data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getAnnualFeeList = async (params: AnnualFeeParams) => {
    const url: string = `/annual/fee/list/detail?${api.qs(params)}`;
    try {
      const response = await api.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getBoard = async (boardType: BoardType, id: number) => {
    const url: string = `/community/${boardType}/${id}`;

    try {
      const { data: response } = await api.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static checkRank = async (id: number) => {
    const url: string = `/event/${id}/check/rank`;

    try {
      const { data: response } = await api.post(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static cancelEvent = async (id: number, body: any) => {
    const url: string = `/pariticipant/event/${id}/cancel`;

    try {
      const { data: response } = await api.post(url, body);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getEventDetail = async (id: number) => {
    const url: string = `/event/${id}`;

    try {
      const { data: response } = await api.post(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getEventQuestion = async (id: number) => {
    const url: string = `/event/${id}/question`;

    try {
      const { data: response } = await api.post(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getEventConfirm = async (id: number, body: any) => {
    const url: string = `/pariticipant/event/${id}`;

    try {
      const { data: response } = await api.post(url, body);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static registerEvent = async (id: number, board: any) => {
    const url: string = `/event/${id}/application`;

    try {
      const { data: response } = await api.post(url, board);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static updateEvent = async (id: number, board: any) => {
    const url: string = `pariticipant/event/${id}/update`;

    try {
      const { data: response } = await api.post(url, board);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static insertBoard = async (boardType: BoardType, board: any) => {
    const url = `/community/${boardType}`;

    try {
      await api.post(url, board);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static insertConference = async (dtype: any, board: any) => {
    const url = `/conference/${dtype}`;

    try {
      await api.post(url, board);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getIndustry = async () => {
    const url: string = `/conference/count/industry`;

    try {
      const response = await api.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static insertLegal = async (board: any) => {
    const url = `/legal-support`;

    try {
      await api.post(url, board);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static updateLegal = async (board: any, id: any) => {
    const url = `/legal-support/${id}`;

    try {
      await api.patch(url, board);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static insertBenefit = async (board: any) => {
    const url = `/benefit/promotion`;

    try {
      await api.post(url, board);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getCooperativeDetail = async (id: number) => {
    const url = `/cooperative/${id}`;

    try {
      const response = await api.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static insertCooperative = async (board: any) => {
    const url = `/cooperative`;

    try {
      await api.post(url, board);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static updateCooperative = async (board: any, id: any) => {
    const url = `/cooperative/${id}`;

    try {
      await api.patch(url, board);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static updateBoard = async (boardType: BoardType, board: any) => {
    const url = `/community/${boardType}/${board.id}`;

    try {
      const res = await api.put(url, board);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static deleteBoard = async (boardType: BoardType, id: number) => {
    const url = `/community/${boardType}/${id}`;

    try {
      await api.delete(url);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static deleteLegalSupport = async (id: number) => {
    const url = `/legal-support/${id}`;

    try {
      await api.delete(url);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static deleteCooperative = async (id: number) => {
    const url = `/cooperative/${id}`;

    try {
      await api.delete(url);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static getCommentList = async (id: number) => {
    const url: string = `/community/comment/${id}/comment?page=0&cpp=0`;

    try {
      const { page: response } = await api.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static insertComment = async (id: number, comment: any) => {
    const url: string = `/community/comment/${id}/comment`;

    try {
      await api.post(url, comment);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static insertReplyComment = async (id: number, comment: any) => {
    const url: string = `/community/comment/${id}/reply`;

    try {
      await api.post(url, comment);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static updateComment = async (id: number, comment: any) => {
    const url: string = `/community/comment/${id}`;

    try {
      await api.put(url, comment);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  static deleteComment = async (id: number) => {
    const url: string = `/community/comment/${id}`;

    try {
      await api.delete(url);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default BoardService;
