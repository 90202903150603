import { SET_BUSINESS, SET_ENUMS } from '@store/actions/commonAction';

const initialState = {};

const common = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_BUSINESS:
      return {
        ...state,
        business: payload,
      };
    case SET_ENUMS:
      return {
        ...state,
        enums: {
          ...payload,
        },
      };
    default:
      return state;
  }
};

export default common;
