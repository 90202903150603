import React, { Component, ReactNode, Suspense } from 'react';
import { BrowserRouter, Switch, RouteProps, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import shortId from 'shortid';
import routeConfigs from './routeConfigs';
import ScrollWithRouter from './ScrollWithRouter';

interface PageRouterProps {
  match: any;
  location: any;
}

class PageRouter extends Component<PageRouterProps | any> {
  componentDidUpdate(prevProps: any) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render(): ReactNode {
    return (
      <BrowserRouter>
        <ScrollWithRouter />
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {routeConfigs.map(
              (route: RouteProps): ReactNode => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Route key={shortId.generate()} {...route} />
              ),
            )}
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (auth: any): object => ({
  authenticated: auth.authenticated,
});

export default withRouter(connect(mapStateToProps)(PageRouter));
