import { RouteProps } from 'react-router-dom';
import { LoginPage, SearchPage, SearchResultPage, GuidancePage } from '@pages';

const LoginRouteConfigs: RouteProps[] = [
  {
    exact: true,
    path: '/login',
    component: LoginPage,
  },
  {
    exact: true,
    path: '/search/id',
    component: SearchPage,
  },
  {
    exact: true,
    path: '/search/password',
    component: SearchPage,
  },
  {
    path: '/search/id/result',
    component: SearchResultPage,
  },
  {
    path: '/search/password/result',
    component: SearchResultPage,
  },
  {
    exact: true,
    path: '/search/guidance',
    component: GuidancePage,
  },
];

export default LoginRouteConfigs;
