import { combineReducers } from 'redux';
import common from './commonReducer';
import user from './userReducer';

const rootReducer = combineReducers({
  common,
  user,
});

export default rootReducer;
