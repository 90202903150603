import React, { Component, ReactNode } from 'react';
import { CommonService } from '@services';
import { withRouter } from 'react-router-dom';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer';
import ApplyStep from '../../../assets/images/apply-step.png';
import MApplyStep from '../../../assets/images/m-apply-step.png';
import Benefit from '../../../assets/images/benefit.png';
import TBenefit from '../../../assets/images/t-benefit.png';
import MBenefit from '../../../assets/images/m-benefit.png';
import './JoinGuide.scss';

interface JoinGuidePageProps {
  location: any;
  history: any;
  match: any;
}

class JoinGuidePage extends Component<JoinGuidePageProps, any> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  onClickJoinPage = (): void => {
    const { history } = this.props;
    history.push('/membership/join/detail');
  };

  onClickBenefitPage = (): void => {
    const { history } = this.props;
    history.push('/membership/benefits');
  };

  render(): ReactNode {
    return (
      <div>
        <Header />
        <div className='guide-page'>
          <div className='guide-container'>
            <div className='title-wrap inner'>
              <h1>회원가입 안내</h1>
              <div className='btn-wrap'>
                <h3>회원가입 절차</h3>
                <div className='img-wrap'>
                  <img src={ApplyStep} alt='' className='desktop' />
                  <img src={MApplyStep} alt='' className='phone' />
                </div>
                <button type='button' onClick={this.onClickJoinPage}>
                  가입신청
                </button>
              </div>
            </div>
            <Tabs>
              <TabList className='navigation-wrap inner'>
                <Tab>회원구분</Tab>
                <span>|</span>
                <Tab>연회비납부안내</Tab>
                <span className='desktop-span'>|</span>
                <Tab>회원사 혜택</Tab>
                <span>|</span>
                <Tab>자주 묻는 질문(FAQ)</Tab>
              </TabList>
              <div className='content-wrap'>
                <TabPanel>
                  <div className='division-wrap inner'>
                    <div className='member-container'>
                      <div className='member-wrap'>
                        <h3>스타트업 회원</h3>
                        <p>
                          1. 스타트업 법인 및 단체, 사업자등록을 한 개인사업자
                          <br />
                          2. 건강한 비즈니스 생태계 조성, 활성화에 동참하고자 하는 스타트업
                          <br />
                          3. 혁신적인 기술이나 아이디어를 통해 빠르게 성장하고 있는 스타트업
                          <br />
                          4. 창업 이후 엑싯 M&A, 상장 이전까지의 스타트업
                        </p>
                        <div className='detail-wrap'>
                          <div className='text-wrap'>
                            <h4>정회원</h4>
                            <p>
                              코스포 회원 혜택 대상이며, 회원활동에 주도적으로 참여합니다.
                              <br />
                              총회 의견권이 있어, 주요 사업계획을 의결합니다.
                            </p>
                            <div className='info-wrap'>
                              <div className='decision'>
                                총회 의결권 <span>있음 (대의원제)</span>
                              </div>
                              <div className='annual-fee'>연회비 : 60/120/180만원 이상 중 택 1</div>
                            </div>
                          </div>
                          <div className='text-wrap'>
                            <h4>준회원</h4>
                            <p>
                              코스포의 출범 취지 및 활동에 동의하며,
                              <br />
                              정보를 습득합니다. (제한적 참여)
                            </p>
                            <div className='info-wrap'>
                              <div className='decision'>
                                총회 의결권 <span>없음</span>
                              </div>
                              <div className='annual-fee'>연회비 : 무료</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='member-container'>
                      <div className='member-wrap'>
                        <h3>특별회원</h3>
                        <p>
                          국내외 대기업 및 계열사, 금융기관, 엑싯한 창업가 등
                          <br />
                          생태계 플레이어가 가입해 스타트업과 교류·생태계 성장을 지원합니다.
                        </p>
                        <div className='detail-wrap'>
                          <div className='text-wrap'>
                            <h4>기업특별회원</h4>
                            <p>
                              대기업 및 계열사, 금융기관 혹은 외국계 기업 중<br />
                              스타트업 생태계에 기여하고자 하는 기업
                            </p>
                            <div className='info-wrap'>
                              <div className='decision'>
                                총회 의결권 <span>없음</span>
                              </div>
                              <div className='annual-fee'>연회비 : 2000만원 이상</div>
                            </div>
                          </div>
                          <div className='text-wrap'>
                            <h4>후원회원</h4>
                            <p>
                              정회원 요건에 충족하지 않으나, 스타트업
                              <br />
                              생태계에 기여하고자 하는 기업 및 유관기관
                            </p>
                            <div className='info-wrap'>
                              <div className='decision'>
                                총회 의결권 <span>없음</span>
                              </div>
                              <div className='annual-fee'>연회비 : 100만원 이상</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className='pay-wrap'>
                    <p className='text-wrap'>
                      <b>
                        안녕하세요. 코리아스타트업포럼입니다.
                        <br />
                        <br />
                      </b>
                      코리아스타트업포럼은 스타트업이 겪는 다양한 규제 해소, 비즈니스 생태계 구축 및
                      스타트업 간 네트워킹, 산업·지역 협의회 운영,
                      <br />
                      스타트업 성장 지원 활동 등 다양한 사업을 스타트업과 함께 추진해오고 있습니다.
                      <br />
                      <br />
                      코리아스타트업포럼의 모든 활동은 회원사에서 납부해주신 연회비로 운영
                      되고있습니다. 모아진 운영 예산은 스타트업 생태계에 보탬이 되도록
                      <br />
                      소중히, 그리고 투명하게 사용하겠습니다. 연간 예결산보고는 연초 정기총회를 통해
                      확인 가능합니다.
                    </p>
                    <div className='user-wrap inner'>
                      <h3>회원별 연회비</h3>
                      <div className='content-wrap'>
                        <div className='tip-wrap'>
                          정회원 (일반회원)의 경우, ‘연회비 선택납부제(60만원, 120만원, 180만원
                          이상)’를 도입하고 있습니다. 회비 차액에 대한 혜택 차이는 없으며, 납부 시
                          기업에서 자율적으로 판단해주시면 되겠습니다.
                          <br />
                          <br />
                          이러한 제도를 도입한 이유는 개별 회원사 마다 규모와 단계가 상이하고,
                          코리아스타트업포럼의 경험 정도에 따라 회원사 관점에서 얻는 명분과 효용이
                          다를 것이기 때문입니다.
                          <br />
                          <br />
                          납부 금액이 커질수록 코리아스타트업포럼 운영 재정에 더 많은 기여를
                          해주시는 것은 맞지만 활동혜택에 차이를 두지 않으니, 부담없이 판단해주시길
                          바랍니다. 회원자격 변경 등 기타 문의사항은 아래 담당자 통해 연락주시기
                          바랍니다.
                        </div>
                        <div className='table-wrap'>
                          <table>
                            <thead>
                              <tr>
                                <th>회원구분</th>
                                <th>연회비 금액</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>정회원(이사)</td>
                                <td>1,200만원 이상</td>
                              </tr>
                              <tr>
                                <td>정회원(운영위원)</td>
                                <td>600만원 이상</td>
                              </tr>
                              <tr>
                                <td>정회원(일반회원) *</td>
                                <td>60만원, 120만원, 180만원 이상 중 택 1</td>
                              </tr>
                              <tr>
                                <td>특별회원사</td>
                                <td>2,000만원 이상</td>
                              </tr>
                              <tr>
                                <td>후원회원사</td>
                                <td>100만원 이상</td>
                              </tr>
                              <tr>
                                <td>준회원사</td>
                                <td>무료</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className='method-wrap inner'>
                      <div className='desc-wrap'>
                        <div className='method-title-wrap'>
                          <h3>납부 방법</h3>
                        </div>
                        <div className='pay-desc-wrap'>
                          <span>계좌이체</span> : 우리 1005-303-467906 (예금주:
                          (사)코리아스타트업포럼)
                          <br />
                          <span>카드결제</span> : SMS결제링크를 통한 카드결제
                        </div>
                      </div>
                      <p>
                        코리아스타트업포럼 연회비는 계좌이체 또는 카드결제로 납부 가능하며, 자세한
                        내용은 연회비 청구공문을 통해 개별 안내드리고 있습니다.
                        <br />
                        회원 자격으로 납부하는 회비는 ‘납입증명서’의 형태로 증빙서류를 발급해드리고
                        있습니다. 카드결제의 경우, 신용카드매출전표로 증빙이 가능하며, 별도의
                        계산서는 제공하지 않습니다. (법인세법 제4조 해당)
                      </p>
                    </div>
                    <div className='inquiry-wrap'>
                      <div className='desc-wrap inner'>
                        <div className='info-wrap'>
                          <h2>문의</h2>
                          <p>
                            (사)코리아스타트업포럼 사무국
                            <br />
                            <br />
                            <br />
                            02-6211-9400
                            <br />
                            hi@kstartupforum.org
                          </p>
                        </div>
                        ~
                        <div className='btn-wrap'>
                          <button
                            type='button'
                            onClick={() => {
                              CommonService.downLink('/코스포 사업자등록증.pdf');
                            }}
                          >
                            (사)코리아스타트업포럼 사업자등록증
                          </button>
                          <button
                            type='button'
                            onClick={() => {
                              CommonService.downLink('/코리아스타트업포럼_통장사본.jpg');
                            }}
                          >
                            (사)코리아스타트업포럼 통장사본
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className='benefit-wrap inner'>
                    <p className='benefit-title-wrap'>
                      코리아스타트업포럼은 스타트업과 생태계에 필요한 일들을 기획하고 지원합니다.
                      <br />
                      코스포 회원사라면 멤버십 혜택을 이용할 수 있으며, 일부 혜택은 회원 구분에 따라
                      차등 지원될 수 있습니다.
                      <br />
                      자세한 이용방법은 하단 연결 버튼을 통해 확인할 수 있습니다.
                    </p>
                    <button type='button' onClick={this.onClickBenefitPage}>
                      회원사 혜택 더보기
                    </button>
                    <div className='detail-wrap'>
                      <picture className='img-wrap'>
                        <source media='(min-width: 1025px)' srcSet={Benefit} />
                        <source media='(min-width: 768px)' srcSet={TBenefit} />
                        <img src={MBenefit} alt='benefit' />
                      </picture>
                      <div className='ul-wrap'>
                        <p>공동 복지 혜택</p>
                        <ul>
                          <li>건강검진 서비스</li>
                          <li>경조용품 지원서비스</li>
                        </ul>
                      </div>
                      <div className='ul-wrap'>
                        <p>멤버십 프로그램활동</p>
                        <ul>
                          <li>창업가들의 성장 커뮤니티 &lt;창업가클럽&gt;</li>
                          <li>심리상담 &lt;창업가들의 마음상담소&gt;</li>
                          <li>정책 지원</li>
                          <li>법률 상담 (with 법률지원단)</li>
                          <li>회원사 간 제휴·협업 지원</li>
                          <li>신규회원사 온보딩</li>
                          <li>네트워킹·이벤트</li>
                          <li>회원사 인터뷰 지원</li>
                          <li>산업·지역협의회 및 커뮤니티</li>
                          <li>Founder&apos;s Forum</li>
                        </ul>
                      </div>
                      <div className='ul-wrap'>
                        <p>회원사 소통채널</p>
                        <ul>
                          <li>
                            회원사 소통채널
                            <br />
                            (정회원 및 협의회별 대화방, 자유대화방, SNS 운영)
                          </li>
                          <li>코스포레터 (매주 화요일 정기 발행)</li>
                        </ul>
                      </div>
                      <div className='ul-wrap'>
                        <p>회원사 서비스 이용</p>
                        <ul>
                          <li>회원사 프로모션 홍보·이용 지원</li>
                          <li>협력 제안(파트너십)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className='faq-wrap inner'>
                    <ul>
                      <li>
                        <div className='question'>
                          <p>
                            연회비 납부 등 기타 행정업무에 대해 궁금한게 생기면 어디로 연락하면
                            될까요?
                          </p>
                        </div>
                        <div className='answer'>
                          <p>
                            정회원사(일반) 연회비 납부 → 납부 확인 후, 납입증명서 발송 및 홈페이지
                            통해 정회원사 전환 처리의 순으로 진행됩니다.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className='question'>
                          <p>연회비 납부에 대한 세금계산서 발행이 가능한가요?</p>
                        </div>
                        <div className='answer'>
                          <p>
                            <span>아니오.</span> 코스포는 비영리법인으로 법인세법상 고유목적사업에
                            사용하기 위해 회원으로부터 받는 회비는 재화나 용역의 공급대가에 해당되지
                            않기 때문에 (세금)계산서를 발행하지 않도록 규정되어 있어 발급이
                            어렵습니다. 다만{' '}
                            <span>납입증명서로도 법인에서 회계상 적격 비용 증빙 처리</span>가
                            가능합니다.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className='question'>
                          <p>연회비 납부금액에 따른 차등 혜택이 있나요?</p>
                        </div>
                        <div className='answer'>
                          <p>
                            <span>아니오.</span> 납부금액에 따른 혜택 차이는 없습니다. 다만, 향후
                            연회비 납부여부 및 금액에 따른 혜택을 준비 중에 있습니다.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className='question'>
                          <p>카드결제로 연회비를 납부하고 싶습니다.</p>
                        </div>
                        <div className='answer'>
                          <p>
                            나이스페이먼츠 <span>SMS링크</span>를 통해 카드결제가 가능합니다. 링크
                            공유를 위해 카드결제 신청 폼을 작성하여 제출해주시면 경영기획팀에서
                            순차적으로 연락을 드립니다.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className='question'>
                          <p>정회원사(일반) 연회비는 언제까지 납부해야 되나요?</p>
                        </div>
                        <div className='answer'>
                          <p>
                            정기 연회비 납부 요청 공문 수령 후, <span>당해연도 1분기 이내</span>{' '}
                            납부를 원칙으로 하고 있습니다. 연중 가입한 회원사의 경우, 회원가입 승인
                            후, 즉시 납부로 요청드리고 있습니다.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className='question'>
                          <p>정회원사(일반) 연회비 귀속기간은 어떻게 되나요?</p>
                        </div>
                        <div className='answer'>
                          <p>
                            정회원사(일반) 연회비는 납부한 당해연도(12/31) 까지만 귀속됩니다.
                            당해연도에 연회비 납부한 경우, 차년도 정기총회에 의결권을 갖게 됩니다.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </TabPanel>
              </div>
            </Tabs>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(JoinGuidePage);
