import React, { Component, ReactNode } from 'react';
import { withRouter } from 'react-router-dom';
import { Header, Footer } from '@components';
import '../JoinPage.scss';

interface JoinPrivacyPolicyPageProps {
  location: any;
  history: any;
  match: any;
}

class JoinPrivacyPolicyPage extends Component<JoinPrivacyPolicyPageProps, any> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  onClickMoveToList = (): void => {
    const { history } = this.props;
    history.goBack();
  };

  render(): ReactNode {
    return (
      <div className='join-page-wrap'>
        <Header />
        <div className='apply-page'>
          <div className='apply-inner'>
            <div className='section'>
              <button className='move-back' type='button' onClick={this.onClickMoveToList}>
                뒤로
              </button>
              <div className='title'>
                <h1>
                  <span>개인정보</span>
                  <br />
                  처리방침
                </h1>
              </div>
              <div className='policy-contents'>
                <div className='privacy-policy'>
                  <p>
                    (사)코리아스타트업포럼(이하 &#039;코스포&#039;)은 개인정보 보호법 제30조에 따라
                    정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수
                    있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립․공개합니다.
                    <br />
                    <br />
                    코스포에서 운영하는 모든 사이트에도 동일하게 적용됩니다. 단, 사이트의 서비스
                    특수성 및 전문성을 고려하여 상세한 개인정보 관리가 요구되는 경우에는 별도의
                    개인정보 처리방침을 둘 수 있습니다. <br />
                    <br />
                    <br />
                    제1조(개인정보의 처리목적) <br />
                    코스포는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는
                    다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는
                    개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할
                    예정입니다.
                    <br />
                    ○ 홈페이지 회원 가입 및 관리
                    <br />
                    회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별․인증, 회원자격
                    유지․관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지 각종
                    고지․통지, 고충처리 등을 목적으로 개인정보를 처리합니다.
                    <br />
                    ○ 민원사무처리
                    <br />
                    민원인의 신원 확인, 민원사항 확인, 사실확인을 위한 연락․통지, 처리결과 통보 등의
                    목적으로 개인정보를 처리합니다.
                    <br />
                    ○ 코스포 사업 안내 및 홍보, 유관기관 사업 홍보를 위한 처리
                    <br />
                    코스포 내에서 수행하는 모든 사업과 유관기관 사업
                    <br />
                    ○ 세미나·포럼, 교육·연수, 교류회, 조사, 기타 사업 등 안내 및 후속 사업 안내
                    <br />
                    ○ 코스포는 사업안내 및 홍보를 위한 정보 이외의 민감정보는 수집하지 않습니다.
                    <br />
                    <br />
                    <br />
                    제2조(개인정보의 처리 및 보유기간) <br />
                    1. 코스포는 법령에 따른 개인정보 보유․이용기간 또는 정보주체로부터 개인정보를
                    수집시에 동의받은 개인정보 보유․이용기간 내에서 개인정보를 처리․보유합니다.
                    <br />
                    2. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다. 코스포는 법령의 규정과
                    정보주체의 동의에 의해서만 개인정보를 수집·보유하며 그 보유기간 및 이용기간은
                    이용자가 회원가입을 신청한 시점부터 해지하겠다는 의사 표시를 하여 해지가 처리된
                    시점까지로 합니다. 사업참가자가 사업중지 및 정보 수신 거부시 개인정보를
                    파기합니다. 홈페이지 회원가입으로 수집된 개인정보의 경우 회원탈퇴 시를 개인정보
                    파기 사유가 발생한 경우로 판단하여 파기합니다.
                    <br />
                    3. 행사참가자의 코스포의 정보 제공 거부는 코스포 홈페이지(하단 수신거부등록)에서
                    하실 수 있으며 거부된 정보에 대해서는 더 이상 정보제공을 하지 않습니다.
                    <br />
                    <br />
                    <br />
                    제3조(개인정보의 제3자 제공) <br />
                    코스포는 개인정보를 제3자에게 제공하지 않습니다.
                    <br />
                    <br />
                    <br />
                    제4조(개인정보처리의 위탁)
                    <br />
                    1. 코스포는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를
                    위탁할 수 있습니다.
                    <br />
                    2. 코스포는 위탁계약 시 개인정보보호 관련 법규를 준수하며, 업체 계약 및 변경 시
                    공지사항 또는 개인정보처리방침을 통해 고지하겠습니다.
                    <br />
                    - 수탁업체 : 온오프믹스
                    <br />
                    - 위탁업무 : 홈페이지 시스템 개발 및 운영
                    <br />
                    - 보유 및 이용기간 : 회원탈퇴 시 혹은 위탁계약종료시
                    <br />
                    <br />
                    <br />
                    제5조(정보주체의 권리․의무 및 행사방법)
                    <br />
                    1. 정보주체는 코스포에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를
                    행사할 수 있습니다.
                    <br />
                    ○ 개인정보 열람요구
                    <br />
                    ○ 오류 등이 있을 경우 정정 요구
                    <br />
                    ○ 삭제 요구
                    <br />
                    ○ 처리정지 요구
                    <br />
                    2. 제1항에 따른 권리 행사는 코스포에 대해 서면, 전화, 전자우편 등을 통하여 하실
                    수 있으며 코스포는 이에 대해 지체없이 조치하겠습니다.
                    <br />
                    3. 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는
                    코스포는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지
                    않습니다.
                    <br />
                    4. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을
                    통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에
                    따른 위임장을 제출하셔야 합니다.
                    <br />
                    5. 정보주체는 개인정보 보호법 등 관계법령을 위반하여 코스포가 처리하고 있는
                    정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.
                    <br />
                    <br />
                    <br />
                    제6조(처리하는 개인정보 항목)
                    <br />
                    코스포는 다음의 개인정보 항목을 처리하고 있습니다.
                    <br />
                    ○ 홈페이지 회원 가입 및 관리
                    <br />
                    필수항목 : 이메일(ID), 이름, 비밀번호, 전화번호, 핸드폰번호, 회사주소,
                    사업자등록번호
                    <br />
                    선택항목 : 부서명, 직위, 팩스번호, 메일수신
                    <br />
                    <span>
                      · 홈페이지에서 제공하는 기타 서비스에서 개인정보 항목을 처리하고 있습니다.
                    </span>
                    <span>
                      · 기타 서비스 : 회원사 온라인 가입, 홈페이지 문의하기, 법률·특허 지원서비스
                    </span>
                    <span>· 필수항목 : 이름, 주소, 전화, 팩스, 휴대폰번호, 이메일</span>
                    <span>
                      · 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수
                      있습니다.
                    </span>
                    <span>· IP주소, 쿠키, 서비스 이용기록, 방문기록 등</span>
                    <span>
                      · 코스포는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’를 운용합니다.
                      쿠키란 코스포의 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는
                      아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다. 코스포는
                      다음과 같은 목적을 위해 쿠키를 사용합니다. 회원과 비회원의 접속 빈도나 방문
                      시간 등을 분석하고 이용자의 취향과 관심분야를 파악하여 서비스 개편 등의 척도로
                      활용합니다. 열람한 웹 페이지들에 대한 정보와 관심 있게 둘러본 페이지들에 대한
                      자취를 추적하여 다음 번 방문 때 개인 맞춤 서비스를 제공하는 데 이용합니다.
                    </span>
                    <span>
                      · 코스포가 진행하는 각종 행사에 참여 정도 및 방문 횟수를 파악하여 관심 분야에
                      따라 차별화된 정보를 제공하기 위한 자료로 이용됩니다.
                    </span>
                    <span>
                      · 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹
                      브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다
                      확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키
                      설치를 거부하셨을 경우 일부 서비스 제공에 어려움이 있을 수 있습니다.
                    </span>
                    <span>
                      · 쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과
                      같습니다.
                      <span>1. [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.</span>
                      <span>2. [개인정보 탭]을 클릭합니다</span>
                      <span>
                        3. [개인정보취급 수준]을 설정하시면 됩니다.
                        <span>
                          · 코스포는 사업안내 및 홍보를 위한 정보 이외의 민감정보는 수집하지
                          않습니다.
                        </span>
                      </span>
                    </span>
                    <br />
                    <br />
                    제7조(개인정보의 파기)
                    <br />
                    1. 코스포는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게
                    되었을 때에는 지체없이 해당 개인정보를 파기합니다. 2. 정보주체로부터 동의받은
                    개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에
                    따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의
                    데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다. 3. 개인정보 파기의
                    절차 및 방법은 다음과 같습니다.
                    <span>
                      ○ 파기절차
                      <span>
                        - 개인정보 : 이용자가 입력한 정보는 목적 달성 후 지체 없이 파기합니다.
                      </span>
                      <span>
                        - 개인정보파일 : 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그
                        개인정보 파일이 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로
                        인정되는 날로부터 지체 없이 파기합니다.
                      </span>
                    </span>
                    <span>
                      ○ 파기방법
                      <span>
                        - 전자적 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
                      </span>
                      <span>- 종이에 출력된 개인정보는 분쇄기로 분쇄하여 파기합니다.</span>
                    </span>
                    <br />
                    <br />
                    제8조(개인정보 보호책임자)
                    <br />
                    1. 코스포는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와
                    관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보
                    보호책임자를 지정하고 있습니다.
                    <br />
                    <span className='span-table'>
                      <span className='span-table-tr'>
                        <span className='span-table-td l-right l-bottom'>개인정보 관리 책임자</span>
                        <span className='span-table-td l-bottom'>개인정보 관리 담당자</span>
                      </span>
                      <span className='span-table-tr'>
                        <span className='span-table-td l-right'>황은아</span>
                        <span className='span-table-td'>박정균</span>
                      </span>
                      <span className='span-table-tr'>
                        <span className='span-table-td l-right'>코리아스타트업포럼 사무국</span>
                        <span className='span-table-td'>스타트업커뮤니티성장팀</span>
                      </span>
                      <span className='span-table-tr'>
                        <span className='span-table-td l-right'>사무국장</span>
                        <span className='span-table-td'>팀장</span>
                      </span>
                      <span className='span-table-tr'>
                        <span className='span-table-td l-right'>02-6211-9400</span>
                        <span className='span-table-td'>02-6211-9400</span>
                      </span>
                      <span className='span-table-tr'>
                        <span className='span-table-td l-right'>h111@kstartupforum.org</span>
                        <span className='span-table-td'>jkpark@kstartupforum.org</span>
                      </span>
                    </span>
                    2. 이용자는 코스포의 서비스(또는 사업)을 이용하면서 발생한 모든 개인정보 보호
                    관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로
                    문의하실 수 있습니다. 코스포는 정보주체의 문의에 대해 지체없이 답변 및
                    처리해드릴 것입니다.
                    <br />
                    <br />
                    <br />
                    제9조(개인정보 처리방침의 변경에 관한 사항)
                    <br />
                    ○ 이 개인정보 처리방침은 2021. 4. 1부터 적용됩니다.
                    <br />
                    <br />
                    <br />
                    제10조(동의를 거부할 권리가 있다는 사실과 동의 거부에 따른 불이익)
                    <br />
                    이용자는 코스포에서 수집하는 개인정보에 대해 동의를 거부할 권리가 있으며 동의
                    거부 시에는 코스포 정보제공 서비스가 일부 제한됩니다.
                    <br />
                    <br />
                    <br />
                    제11조(권익침해 구제방법)
                    <br />
                    정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실
                    수 있습니다.
                    <br />
                    <span className='span-table'>
                      <span className='span-table-tr'>
                        <span className='span-table-td l-right l-bottom'>
                          개인정보분쟁 조정위원회
                        </span>
                        <span className='span-table-td l-right l-bottom'>
                          대검찰청 사이버범죄 수사단
                        </span>
                        <span className='span-table-td l-bottom'>경찰청 사이버테러 대응센터</span>
                      </span>
                      <span className='span-table-tr'>
                        <span className='span-table-td l-right l-bottom'>
                          https://www.kopico.go.kr
                        </span>
                        <span className='span-table-td l-right l-bottom'>http://www.spo.go.k</span>
                        <span className='span-table-td l-bottom'>http://www.netan.go.kr</span>
                      </span>
                      <span className='span-table-tr'>
                        <span className='span-table-td l-right'>(국번없이)118(내선2번)</span>
                        <span className='span-table-td l-right'>02-3480-3582</span>
                        <span className='span-table-td'>1566-0112</span>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(JoinPrivacyPolicyPage);
