import { RouteProps } from 'react-router-dom';

import JoinDetailPage from './JoinDetailPage/JoinDetailPage';
import JoinGuidePage from './JoinGuidePage/JoinGuidePage';
import JoinResultPage from './JoinResultPage/JoinResultPage';
import JoinPrivacyPolicyPage from './JoinPrivacyPolicyPage/JoinPrivacyPolicyPage';

const JoinRouteConfigs: RouteProps[] = [
  {
    exact: true,
    path: '/join',
    component: JoinGuidePage,
  },
  {
    exact: true,
    path: '/membership/join',
    component: JoinGuidePage,
  },
  {
    path: '/privacy-policy',
    component: JoinPrivacyPolicyPage,
  },
  {
    path: '/membership/join/detail',
    component: JoinDetailPage,
  },
  {
    path: '/membership/join/result/:status',
    component: JoinResultPage,
  },
];

export default JoinRouteConfigs;
