import { AxiosRequestConfig } from 'axios';
import { STRG_ACCESS_TOKEN_KEY, STRG_REFRESH_TOKEN_KEY } from '@constants';
import apiService from './apiService';

/**
 * Token 관련 서비스 로직
 * @author deokchanjung(deokchan@nextculture.kr)
 */
class TokenService {
  /**
   * 로컬 스토리지에서 엑세스 토큰 가져오기
   * @return {string | null}
   */
  public getAccessToken = (): string | null => localStorage.getItem(STRG_ACCESS_TOKEN_KEY);

  /**
   * 로컬 스토리지에서 리프레시 토큰 가져오기
   * @return {string | null}
   */
  public getRefreshToken = (): string | null => localStorage.getItem(STRG_REFRESH_TOKEN_KEY);

  /**
   * 로컬 스토리지로 액세스 토큰 저장
   * @param {string} accessToken
   * @return {void}
   */
  public setAccessToken = (accessToken: string): void =>
    localStorage.setItem(STRG_ACCESS_TOKEN_KEY, accessToken);

  /**
   * 로컬 스토리지로 리프레시 토큰 저장
   * @param {string} refreshToken
   * @return {void}
   */
  public setRefreshToken = (refreshToken: string): void =>
    localStorage.setItem(STRG_REFRESH_TOKEN_KEY, refreshToken);

  /**
   * 로컬 스토리지에 저장된 액세스 토큰 삭제
   * @return {void}
   */
  public removeAccessToken = (): void => localStorage.removeItem(STRG_ACCESS_TOKEN_KEY);

  /**
   * 로컬 스토리지에 저장된 리프레시 토큰 삭제
   * @return {void}
   */
  public removeRefreshToken = (): void => localStorage.removeItem(STRG_REFRESH_TOKEN_KEY);

  /**
   * 토큰 리프레시
   * @return {Promise<any>}
   */
  public refresh = async (): Promise<any> => {
    try {
      const url: string = '/auth/token';
      const refreshToken: string | null = this.getRefreshToken();

      if (!refreshToken) {
        return Promise.reject();
      }

      const config: AxiosRequestConfig = {
        url,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      };

      const { accessToken } = await apiService.request(config);
      this.setAccessToken(accessToken);

      return Promise.resolve(accessToken);
    } catch (error) {
      this.cleanTokens();
      return Promise.reject(error);
    }
  };

  /**
   * 로컬 스토리지에 있는 토큰(액세스/리프레시 토큰)들을 모두 삭제
   * @return {void}
   */
  public cleanTokens = (): void => {
    this.removeAccessToken();
    this.removeRefreshToken();
  };
}

export default new TokenService();
