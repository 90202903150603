/**
 * 공통 코드
 * @author deokchanjung(deokchan@nextculture.kr)
 */

/**
 * HTTP 상태 코드
 */
export const HTTP_OK = 200;
export const HTTP_BAD_REQUEST = 400;
export const HTTP_UNAUTHORIZED = 401;
export const HTTP_FORBIDDEN = 403;
export const HTTP_NOT_FOUND = 404;
export const HTTP_INTERNAL_SERVER_ERROR = 500;

/**
 * API 에러코드
 */

// 파라미터 관련 오류
export const ERR_PARAMETER = 2000;
export const ERR_DUPLICATE = 2001;

// 인증 관련 오류
export const ERR_UNAUTHENTICATED = 4000;

// 데이터 관련 오류
export const ERR_NULL_POINTER = 9000;
export const ERR_ENTITY_NOT_FOUND = 9001;

// 파일관련 오류
export const ERR_IO = 9100;
export const ERR_EXCEED_FILE_SIZE = 9101;

// JSON 관련 오류
export const ERR_JSON = 9200;
export const ERR_JSON_PROCESS = 9201;

// 접근 권한 관련 오류
export const ERR_ACCESS_DENIED = 9400;

// JWT 관련 오류
export const ERR_JWT = 9600;
export const ERR_EXPIRED_JWT = 9601;

// 런타임 관련 오류
export const ERR_RUNTIME = 9900;

// 알 수 없는 오류
export const ERR_UNKNOWN = 9999;
