import { RouteProps } from 'react-router-dom';
import { MyPage, ResultPage } from '@pages';

const MypageRouteConfigs: RouteProps[] = [
  {
    path: '/mypage',
    component: MyPage,
  },
  {
    path: '/mypage/result',
    component: ResultPage,
  },
];

export default MypageRouteConfigs;
