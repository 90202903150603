/* eslint-disable no-underscore-dangle */
import { compose, createStore } from 'redux';
import rootReducer from './reducers';

const store = createStore(
  rootReducer,
  process.env.NODE_ENV !== 'production'
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    : null || compose,
);

export default store;
