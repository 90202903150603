import React, { ReactNode } from 'react';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import shortid from 'shortid';
import { TokenService, AuthService } from '@services';
import { navigationConfig } from './NavigationConfigs';
import HeaderMobile from './HeaderMobile';

import './Header.scss';
// import { isMobile } from 'react-device-detect';

interface HeaderProps {
  history?: any;
  location?: any;
  header?: 'white';
  pageType?: string;
}

interface HeaderStates {
  isPageType: boolean;
  isActive: boolean;
  isScrolld: boolean;
  isBurgerVisible: boolean;
  isMobile: boolean;
  activeIndx: number | undefined;

  accessToken: string | null;
}

class Header extends React.Component<RouteComponentProps<any>, HeaderStates> {
  constructor(props: any) {
    super(props);
    this.state = {
      isPageType: false,
      isActive: false,
      isScrolld: false,
      isBurgerVisible: false,
      isMobile: false,
      activeIndx: undefined,
      accessToken: TokenService.getAccessToken(),
    };
  }

  componentDidMount = (): void => {
    const { location } = this.props;
    if (location.pathname === '/') {
      this.setState({
        isPageType: true,
      });
    }
    this.updateLayout();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.updateLayout);
  };

  componentWillUnmount = (): void => {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.updateLayout);
  };

  // checkGranted = async (url: string): Promise<void> => {
  //   try {
  //     const accessToken = TokenService.getAccessToken();

  //     if (!accessToken) {
  //       // this.openVisiblePopup();
  //     } else {
  //       window.location.href = url;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  signOut = async (): Promise<void> => {
    const { history, location } = this.props;

    try {
      await AuthService.signOut();
    } finally {
      if (location.pathname === '/') {
        window.location.reload();
      } else {
        history.push('/');
      }
    }
  };

  handleScroll = (): void => {
    const { pageYOffset } = window;
    const deltaY = pageYOffset - 50;

    if (pageYOffset !== 0 && deltaY >= 0) {
      this.setState({
        isScrolld: true,
      });
    } else {
      this.setState({
        isScrolld: false,
      });
    }
  };

  updateLayout = (): void => {
    if (window.innerWidth < 1280) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  handleMouseEnter = (): void => {
    this.setState({ isActive: true });
  };

  handleMouseLeave = (): void => {
    this.setState({ isActive: false });
  };

  handleToggleburger = (): void => {
    const { isBurgerVisible } = this.state;
    this.setState({ isBurgerVisible: !isBurgerVisible });
  };

  // handleMobileMenu = (index: number): void => {
  //   const { activeIndx, isMobile } = this.state;

  //   if (!isMobile) return;

  //   if (activeIndx === index) {
  //     this.setState({
  //       activeIndx: undefined,
  //     });
  //   } else {
  //     this.setState({
  //       activeIndx: index,
  //     });
  //   }
  // };

  handleLogout = async (): Promise<void> => {
    const { history, location } = this.props;

    try {
      await AuthService.signOut();
    } finally {
      if (location.pathname === '/') {
        window.location.reload();
      } else {
        history.push('/');
      }
    }
  };

  moveToMypage = (): void => {
    const { history } = this.props;
    history.push(`/mypage`);
  };

  render(): ReactNode {
    // const { header } = this.props;
    const {
      isPageType,
      isActive,
      isScrolld,
      isBurgerVisible,
      isMobile,
      activeIndx,
      accessToken,
    } = this.state;

    return (
      <header className='header header--black'>
        <div className='header-wrap'>
          <div className='header-align'>
            <h1 className='header-logo'>
              <a href='/' className='header-logo-link'>
                Korea Startup Forum
              </a>
            </h1>

            {/* nav */}
            <nav
              className='nav'
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
            >
              {navigationConfig.map((item, index) => (
                <ul key={shortid.generate()} className='nav-wrap'>
                  <li className='nav-item'>
                    <NavLink
                      to={!accessToken && item.auth ? '/login' : item.url}
                      className={`nav-item__link${
                        isMobile && activeIndx === index ? ' nav-item__link--open' : ''
                      }`}
                      activeClassName='nav-item__link--active'
                      // onClick={(): void => this.handleMobileMenu(index)}
                    >
                      <span className='nav-item__text'>{item.title}</span>
                    </NavLink>
                    {item.children && (
                      <ul className='nav-depth'>
                        {item.children.map((subMenu) => (
                          <li key={shortid.generate()} className='nav-depth__item'>
                            <NavLink
                              to={!accessToken && subMenu.auth ? '/login' : subMenu.url}
                              className='nav-depth__link'
                              activeClassName='nav-depth__link--active'
                            >
                              {subMenu.title}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                </ul>
              ))}
            </nav>

            {/* nav-right */}
            <div className='nav-right'>
              <ul>
                <li>
                  {accessToken ? (
                    <button type='button' onClick={this.moveToMypage}>
                      마이페이지
                      <span className='bar' />
                    </button>
                  ) : (
                    <a href='/login'>
                      로그인
                      <span className='bar' />
                    </a>
                  )}
                </li>
                <li>
                  {accessToken ? (
                    <button type='button' onClick={this.signOut}>
                      로그아웃
                    </button>
                  ) : (
                    <a href='/join'>회원가입</a>
                  )}
                </li>
              </ul>
            </div>

            <HeaderMobile />
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
