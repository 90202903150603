import React, { ReactNode } from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import LoginRouteConfigs from '../pages/login/LoginRouteConfigs';
import JoinRouteConfigs from '../pages/join/JoinRouteConfigs';
import ForumRouteConfigs from '../pages/forum/ForumRouteConfigs';
import MainRouteConfigs from '../pages/main/MainRouteConfigs';
import MemberRouteConfigs from '../pages/membership/MemberRouteConfigs';
import MypageRouteConfigs from '../pages/mypage/MypageRouteConfigs';
import NewsRouteConfigs from '../pages/news/NewsRouteConfigs';
import CommunityRouteConfigs from '../pages/community/CommunityRouteConfigs';
import EnglishouteConfigs from '../pages/english/EnglishRouteConfigs';

const routeConfigs = [
  ...LoginRouteConfigs,
  ...MainRouteConfigs,
  ...JoinRouteConfigs,
  ...ForumRouteConfigs,
  ...MemberRouteConfigs,
  ...MypageRouteConfigs,
  ...NewsRouteConfigs,
  ...CommunityRouteConfigs,
  ...EnglishouteConfigs,
  {
    exact: true,
    path: '/',
    component: (): ReactNode => <Redirect to='/' />,
  },
] as Array<RouteProps>;

export const REDIRECT_PATH: string = '/';

export default routeConfigs;
