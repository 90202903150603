/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
import React, { Component, ReactNode } from 'react';
import { withRouter } from 'react-router-dom';
import DaumPostcode from 'react-daum-postcode';
import { FileService, UserService } from '@services';
import { ERR_DUPLICATE, ERR_RUNTIME } from '@constants';
import { Account, BusinessListItems, CeoError, ChargeError, Charger, Errors } from '@types';
import shortid from 'shortid';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  TextField,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import { RankType, GenderType, InvestmentStepType, JoinPathType, ChargeTaskType } from '../enums';
import '../JoinPage.scss';
import JoinResultPopup from '../JoinResultPage/JoinResultPopup';
// import { objectOf } from 'prop-types';

/**
 * membership > 가입안내 > 가입신청
 */

interface JoinCommonInfoPageProps {
  location: any;
  history: any;
  match: any;
}

interface JoinCommonInfoPageStates {
  user: Account;
  // 비밀번호 확인
  passwordConfirm: string;
  // checkbox 목록
  businessListItems: Array<BusinessListItems>;
  businessKeywordIntermediateItems: Array<BusinessListItems>;
  businessKeywordCoreTechnologyItems: Array<BusinessListItems>;
  businessKeywordEtcItems: Array<BusinessListItems>;
  investmentSourceItems: Array<BusinessListItems>;
  joinReasonList: Array<BusinessListItems>;
  businessEtcText: string;
  IntermediateEtcText: string;
  CoreTechnologyEtcText: string;
  investmentSourceEtcText: string;
  KeywordEtcText: string;
  // 주소 모달
  isAddressModalVisible: boolean;
  isRegistrationModalVisible: boolean;
  registrationErrorMessage: string;
  // file tmp
  tempCompanyIntroduce: any;
  tempLogo: any;
  // logo 미리보기 url
  logoPreview: string;
  // 대표자 정보 가져오기
  getCeo: boolean;
  // error
  errors: Errors;
  // popup
  popupShow: boolean;
  // tip
  tipShow: boolean;
}

const BASE_YEAR = 1900;
const addressModalStyle = {
  width: '100%',
  border: '1px solid #000000',
  overflow: 'hidden',
};

class JoinCommonInfoPage extends Component<JoinCommonInfoPageProps, JoinCommonInfoPageStates> {
  years: Array<any> = [];

  constructor(props: JoinCommonInfoPageProps) {
    super(props);

    this.state = {
      user: {
        id: 0,
        // 기본정보
        username: '',
        rank: RankType.ASSOCIATE,
        registrationNumber: '',
        corporateNumber: '',
        password: '',
        // 기업정보
        companyNameKo: '',
        companyNameEn: '',
        serviceNameKo: '',
        serviceNameEn: '',
        zipcode: '',
        baseAddress: '',
        detailAddress: '',
        tel: '',
        homepage: '',
        introduce: '',
        businessFieldList: [],
        businessKeywordList: [],
        investmentSourceList: [],
        joinReasonList: [],
        reasonForJoin: '',
        ceoList: [
          {
            ceoName: '',
            ceoEmail: '',
            ceoMobile: '',
            newsletterFlag: 'true',
            gender: GenderType.MALE,
            ageYear: '',
          },
        ],
        chargeList: [
          {
            chargeName: '',
            chargeEmail: '',
            chargeMobile: '',
            chargeTask: ChargeTaskType.NONE,
            chargeTaskText: '',
            newsletterFlag: 'true',
          },
        ],
        accumulativeInvestment: 0, // undefined
        averageRevenue: 0, // undefined
        capital: 0, // undefined
        foundationDate: '',
        investmentStep: InvestmentStepType.NONE,
        employs: 0,
        paymentFlag: false,
        companyIntroduce: [],
        logo: {
          id: 0,
        },
        joinPath: JoinPathType.NONE,
        joinPathText: '',
        privacyPolicyFlag: false,
      },
      businessListItems: [],
      businessKeywordIntermediateItems: [],
      businessKeywordCoreTechnologyItems: [],
      businessKeywordEtcItems: [],
      investmentSourceItems: [],
      joinReasonList: [],
      businessEtcText: '',
      IntermediateEtcText: '',
      CoreTechnologyEtcText: '',
      investmentSourceEtcText: '',
      KeywordEtcText: '',
      passwordConfirm: '',
      isAddressModalVisible: false,
      isRegistrationModalVisible: false,
      registrationErrorMessage: '',
      tempCompanyIntroduce: {},
      tempLogo: {},
      logoPreview: '',
      getCeo: false,
      errors: {
        rank: '',
        username: '',
        registrationNumber: '',
        password: '',
        passwordConfirm: '',
        companyNameKo: '',
        companyNameEn: '',
        serviceNameKo: '',
        serviceNameEn: '',
        address: '',
        tel: '',
        introduce: '',
        businessFieldList: '',
        businessKeywordList: '',
        investmentSourceList: '',
        joinReasonList: '',
        investmentStep: '',
        foundationDate: '',
        averageRevenue: '',
        accumulativeInvestment: '',
        employs: '',
        capital: '',
        companyIntroduce: '',
        logo: '',
        ceoList: [
          {
            ceoName: '',
            ceoEmail: '',
            ceoMobile: '',
            ageYear: '',
          },
        ],
        chargeList: [
          {
            chargeName: '',
            chargeEmail: '',
            chargeMobile: '',
            chargeTask: '',
            chargeTaskText: '',
          },
        ],
        reasonForJoin: '',
        joinPath: '',
        joinPathText: '',
        paymentFlag: '',
        privacyPolicyFlag: '',
      },
      popupShow: false,
      tipShow: false,
    };
    this.setAgeYears();
  }

  componentDidMount = (): void => {
    this.getBusinessList();
  };

  // checkbox 목록 가져오기 event
  getBusinessList = (): void => {
    UserService.getBusinessList()
      .then(
        (response: {
          businessList: Array<BusinessListItems>;
          Intermediate: Array<BusinessListItems>;
          CoreTechnology: Array<BusinessListItems>;
          Etc: Array<BusinessListItems>;
          InvestmentSource: Array<BusinessListItems>;
          joinReasonList: Array<BusinessListItems>;
        }) => {
          const businessListItems = response.businessList || [];
          const businessKeywordIntermediateItems = response.Intermediate || [];
          const businessKeywordCoreTechnologyItems = response.CoreTechnology || [];
          const businessKeywordEtcItems = response.Etc || [];
          const investmentSourceItems = response.InvestmentSource || [];
          const joinReasonList = response.joinReasonList || [];
          this.setState({
            businessListItems,
            businessKeywordIntermediateItems,
            businessKeywordCoreTechnologyItems,
            businessKeywordEtcItems,
            investmentSourceItems,
            joinReasonList,
          });
        },
      )
      .catch((error) => {
        console.log(error);
      });
  };

  // 출생연도 event
  setAgeYears = (): void => {
    const currentYear = new Date().getFullYear();
    let year: number = BASE_YEAR;

    while (year <= currentYear) {
      this.years.push(year);
      year += 1;
    }
  };

  // 회원가입 이벤트
  registerUser = (): void => {
    const { user } = this.state;

    try {
      this.requestUserRegisterApi(user);
    } catch (error) {
      console.log(error);
    }
  };

  requestUserRegisterApi = async (userRegister: Account): Promise<void> => {
    const { tempLogo } = this.state;
    const payload = userRegister;

    try {
      if (tempLogo && tempLogo.name) {
        const attachId = (await FileService.upload(tempLogo))[0].id;
        if (payload.logo) payload.logo.id = attachId || 0;
      }

      await UserService.registerUser(payload);
      this.setState({
        popupShow: true,
      });
    } catch (error) {
      if (error.error_code === ERR_DUPLICATE) {
        alert('이미 사용중인 아이디 입니다.');
      } else if (error.error_code === ERR_RUNTIME) {
        alert('빈칸 혹은 공백으로 채워진 입력칸을 수정해주세요.');
      }
    }
  };

  onClickRegisterUser = (): void => {
    if (this.validate()) {
      return;
    }
    this.registerUser();
  };

  checkRegistration = async (): Promise<void> => {
    const { user } = this.state;
    const payload = user.registrationNumber ?? '';

    try {
      const result = await UserService.checkRegistration(payload);
      if (result.success) {
        this.setState({
          isRegistrationModalVisible: false,
          tipShow: true,
        });
      }
    } catch (error) {
      if (error.error_code === 3000) {
        this.setState({
          registrationErrorMessage: '국세청에 등록되지 않았거나 폐업자입니다.',
        });
      } else if (error.error_code === 3001) {
        this.setState({
          registrationErrorMessage: '이미 등록된 사업자 번호입니다.',
        });
      } else {
        this.setState({
          registrationErrorMessage: '예기치 못한 에러가 발생하였습니다.',
        });
      }
    }
  };

  // 주소 모달창 event
  onClickRegistration = (): void => {
    this.setState({
      isRegistrationModalVisible: true,
    });
  };

  onClickRegistrationClose = (): void => {
    const { user } = this.state;
    this.setState({
      isRegistrationModalVisible: false,
      user: {
        ...user,
        registrationNumber: '',
      },
    });
  };

  // 주소 모달창 event
  onClickSearchAddress = (): void => {
    this.setState({
      isAddressModalVisible: true,
    });
  };

  onClickSearchAddressClose = (): void => {
    this.setState({
      isAddressModalVisible: false,
    });
  };

  onChangeAddressSearch = (data: any): void => {
    const { user } = this.state;
    let AllAddress = data.address;
    let extraAddress = '';
    const zoneCodes = data.zonecode;

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      AllAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    this.setState({
      user: {
        ...user,
        zipcode: zoneCodes,
        baseAddress: AllAddress,
      },
    });
    this.onClickSearchAddressClose();
  };

  // input value change event
  onChangeValue = (key: any, value: any): void => {
    if (key === 'registrationNumber' || key === 'corporateNumber') {
      value = value.replace(/[^0-9]/g, '');
    }
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [key]: value,
      },
    }));
  };

  onChangeCeoValue = (key: any, value: any, index: number): void => {
    const { user } = this.state;
    user.ceoList[index] = {
      ...user.ceoList[index],
      [key]: value,
    };
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        ceoList: [...user.ceoList],
      },
    }));
  };

  onChangeChargeValue = (key: any, value: any, index: number): void => {
    const { user } = this.state;
    user.chargeList[index] = {
      ...user.chargeList[index],
      [key]: value,
    };
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        chargeList: [...user.chargeList],
      },
    }));
  };

  appendCeo = (): void => {
    const { user } = this.state;
    const { errors } = this.state;
    const newItem = {
      ceoName: '',
      ceoEmail: '',
      ceoMobile: '',
      newsletterFlag: 'true',
      gender: GenderType.MALE,
      ageYear: '',
    };

    const newErrorItem = {
      ceoName: '',
      ceoEmail: '',
      ceoMobile: '',
      ageYear: '',
    };

    this.setState({
      user: {
        ...user,
        ceoList: [...user.ceoList, newItem],
      },
      errors: {
        ...errors,
        ceoList: [...errors.ceoList, newErrorItem],
      },
    });
  };

  removeCeo = (index: number) => {
    const { user } = this.state;
    user.ceoList.splice(index, 1);

    const ceoListTmp = user.ceoList;

    this.setState({
      user: {
        ...user,
        ceoList: ceoListTmp,
      },
    });
  };

  appendCharge = (): void => {
    const { user } = this.state;
    const { errors } = this.state;
    const newItem = {
      chargeName: '',
      chargeEmail: '',
      chargeMobile: '',
      chargeTask: ChargeTaskType.NONE,
      chargeTaskText: '',
      newsletterFlag: 'true',
    };

    const newErrorItem = {
      chargeName: '',
      chargeEmail: '',
      chargeMobile: '',
      chargeTaskText: '',
      chargeTask: '',
    };

    this.setState({
      user: {
        ...user,
        chargeList: [...user.chargeList, newItem],
      },
      errors: {
        ...errors,
        chargeList: [...errors.chargeList, newErrorItem],
      },
    });
  };

  removeCharge = (index: number) => {
    const { user } = this.state;
    user.chargeList.splice(index, 1);

    const chargeListTmp = user.chargeList;

    this.setState({
      user: {
        ...user,
        chargeList: chargeListTmp,
      },
    });
  };

  // 비밀번호 확인 이벤트
  onChangePwConfirm = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      passwordConfirm: e.target.value,
    });
  };

  // 사업분야 event
  onChangeBusinessFiledItemsCheck = ({ target }: any): void => {
    const { user } = this.state;
    const id = Number(target.value);
    const name = String(target.parentNode.parentNode.dataset.name);
    const businessFiledIdList = user.businessFieldList.map((selected) => selected.id);
    const alreadyChecked: boolean = businessFiledIdList.includes(id);

    if (alreadyChecked) {
      this.setState({
        user: {
          ...user,
          businessFieldList: user.businessFieldList.filter((selected) => selected.id !== id),
        },
      });
    } else if (businessFiledIdList.length > 1) {
      console.log('초과');
    } else {
      this.setState({
        user: {
          ...user,
          businessFieldList: [
            ...user.businessFieldList,
            {
              id,
              flagName: name,
            },
          ],
        },
      });
    }
  };

  // 주요 비즈니스 event
  onChangeBusinessKeywordItemsCheck = ({ target }: any): void => {
    const { user } = this.state;
    const id = Number(target.value);
    const name = String(target.parentNode.parentNode.dataset.name);
    const businessKeywordIdList = user.businessKeywordList.map((selected) => selected.id);
    const alreadyChecked: boolean = businessKeywordIdList.includes(id);

    if (alreadyChecked) {
      this.setState({
        user: {
          ...user,
          businessKeywordList: user.businessKeywordList.filter((selected) => selected.id !== id),
        },
      });
    } else if (businessKeywordIdList.length > 2) {
      console.log('초과');
    } else {
      this.setState({
        user: {
          ...user,
          businessKeywordList: [
            ...user.businessKeywordList,
            {
              id,
              flagName: name,
            },
          ],
        },
      });
    }
  };

  // 투자 출처 event
  onChangeInvestmentSourceListItemsCheck = ({ target }: any): void => {
    const { user } = this.state;
    const id = Number(target.value);
    const name = String(target.parentNode.parentNode.dataset.name);
    const investmentSourceListIdList = user.investmentSourceList.map((selected) => selected.id);
    const alreadyChecked: boolean = investmentSourceListIdList.includes(id);

    if (alreadyChecked) {
      this.setState({
        user: {
          ...user,
          investmentSourceList: user.investmentSourceList.filter((selected) => selected.id !== id),
        },
      });
    } else {
      this.setState({
        user: {
          ...user,
          investmentSourceList: [
            ...user.investmentSourceList,
            {
              id,
              flagName: name,
            },
          ],
        },
      });
    }
  };

  // 가입경로 event
  onChangeJoinReasonListItemsCheck = ({ target }: any): void => {
    const { user } = this.state;
    const id = Number(target.value);
    const name = String(target.parentNode.parentNode.dataset.name);
    const joinReasonListIdList = user.joinReasonList.map((selected) => selected.id);
    const alreadyChecked: boolean = joinReasonListIdList.includes(id);

    if (alreadyChecked) {
      this.setState({
        user: {
          ...user,
          joinReasonList: user.joinReasonList.filter((selected) => selected.id !== id),
        },
      });
    } else {
      this.setState({
        user: {
          ...user,
          joinReasonList: [
            ...user.joinReasonList,
            {
              id,
              flagName: name,
            },
          ],
        },
      });
    }
  };

  businessFieldIsChecked = (id: number): boolean => {
    const {
      user: { businessFieldList },
    } = this.state;
    return businessFieldList.map((selected) => selected.id).includes(id);
  };

  businessKeywordIsChecked = (id: number): boolean => {
    const {
      user: { businessKeywordList },
    } = this.state;
    return businessKeywordList.map((selected) => selected.id).includes(id);
  };

  investmentSourceIsChecked = (id: number): boolean => {
    const {
      user: { investmentSourceList },
    } = this.state;
    return investmentSourceList.map((selected) => selected.id).includes(id);
  };

  joinReasonIsChecked = (id: number): boolean => {
    const {
      user: { joinReasonList },
    } = this.state;
    return joinReasonList.map((selected) => selected.id).includes(id);
  };

  onChangeBusinessEtcText = (key: any, value: any): void => {
    const { user } = this.state;
    const newArray: Array<{ id: number; flagName: string }> = [];
    user.businessFieldList.forEach((element) => {
      if (element.id === 40) {
        newArray.push({ id: element.id, flagName: value });
      } else {
        newArray.push({ id: element.id, flagName: element.flagName });
      }
    });
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
      user: {
        ...user,
        businessFieldList: newArray,
      },
    }));
  };

  onChangeBusinessKeywordEtcText = (key: any, value: any): void => {
    const { user } = this.state;
    const newArray: Array<{ id: number; flagName: string }> = [];
    user.businessKeywordList.forEach((element) => {
      if (element.id === 48 || element.id === 57 || element.id === 65) {
        newArray.push({ id: element.id, flagName: value });
      } else {
        newArray.push({ id: element.id, flagName: element.flagName });
      }
    });
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
      user: {
        ...user,
        businessKeywordList: newArray,
      },
    }));
  };

  onChangeInvestmentSourceEtcText = (key: any, value: any): void => {
    const { user } = this.state;
    const newArray: Array<{ id: number; flagName: string }> = [];
    user.investmentSourceList.forEach((element) => {
      if (element.id === 74) {
        newArray.push({ id: element.id, flagName: value });
      } else {
        newArray.push({ id: element.id, flagName: element.flagName });
      }
    });
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
      user: {
        ...user,
        investmentSourceList: newArray,
      },
    }));
  };

  // 원화 event
  onChangeCapital = (key: any, value: any): void => {
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [key]: value.replace(/,/g, ''),
      },
    });
  };

  capitalValue = (key: any) => {
    return key || String(key).length > 3 ? String(key).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : key;
  };

  // 창업날짜 event
  onChangeFoundationDate = (e: any): void => {
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        foundationDate: moment(e).format('YYYY-MM-DD'),
      },
    });
  };

  // validate
  validate = (): boolean => {
    const { user, errors, passwordConfirm } = this.state;

    let passwordError = '';
    if (user.password === '') passwordError = '비밀번호를 입력해 주세요.';
    else if (user.password.length < 6) passwordError = '6자 이상 입력해 주세요.';

    let passwordConfirmError = '';
    if (passwordConfirm === '') passwordConfirmError = '비밀번호를 한번 더 입력해 주세요.';
    else if (user.password !== passwordConfirm)
      passwordConfirmError = '비밀번호가 일치하지 않습니다.';

    let businessFieldListError = '';
    if (user.businessFieldList.length === 0)
      businessFieldListError = '주요 사업 분야를 선택해 주세요.';
    else if (user.businessFieldList)
      for (let i = 0; i < user.businessFieldList.length; i += 1)
        if (
          user.businessFieldList[i].flagName === '기타' ||
          user.businessFieldList[i].flagName === ''
        )
          businessFieldListError = '기타항목을 작성해주세요.';

    let businessKeywordListError = '';
    if (user.businessKeywordList.length === 0)
      businessKeywordListError = '주요 비즈니스 키워드를 선택해 주세요.';
    else if (user.businessKeywordList)
      for (let i = 0; i < user.businessKeywordList.length; i += 1)
        if (
          user.businessKeywordList[i].flagName === '기타' ||
          user.businessKeywordList[i].flagName === ''
        )
          businessKeywordListError = '기타항목을 작성해주세요.';

    let investmentSourceListError = '';
    if (user.rank !== RankType.SPECIAL && user.investmentSourceList.length === 0)
      investmentSourceListError = '투자 출처를 선택해 주세요.';
    else if (user.investmentSourceList)
      for (let i = 0; i < user.investmentSourceList.length; i += 1)
        if (
          user.investmentSourceList[i].flagName === '기타' ||
          user.investmentSourceList[i].flagName === ''
        )
          investmentSourceListError = '기타항목을 작성해주세요.';

    let joinPathError = '';
    if (user.joinPath === JoinPathType.NONE) joinPathError = '가입경로를 선택해 주세요.';
    else if (user.joinPath === JoinPathType.ETC && user.joinPathText === '')
      joinPathError = '기타항목을 작성해주세요.';

    const ceoListError: Array<CeoError> = [];
    user.ceoList.forEach((x) => {
      const errorItem = {
        ceoName: '',
        ceoEmail: '',
        ceoMobile: '',
        ageYear: '',
      };
      if (x.ceoName === '') errorItem.ceoName = '대표자명을 입력해 주세요.';
      if (x.ceoEmail === '') errorItem.ceoEmail = '대표자 이메일을 입력해 주세요.';
      if (x.ceoMobile === '') errorItem.ceoMobile = '대표자 연락처를 입력해 주세요.';
      if (x.ageYear === '') errorItem.ageYear = '출생연도를 선택해 주세요.';
      ceoListError.push(errorItem);
    });

    const chargeListError: Array<ChargeError> = [];
    user.chargeList.forEach((x) => {
      const errorItem = {
        chargeName: '',
        chargeEmail: '',
        chargeMobile: '',
        chargeTask: '',
        chargeTaskText: '',
      };
      if (x.chargeName === '') errorItem.chargeName = '담당자명을 입력해 주세요.';
      if (x.chargeEmail === '') errorItem.chargeEmail = '담당자 이메일을 입력해 주세요.';
      if (x.chargeMobile === '') errorItem.chargeMobile = '담당자 연락처를 입력해 주세요.';
      if (x.chargeTask === ChargeTaskType.NONE) errorItem.chargeTask = '담당업무를 선택해 주세요.';
      else if (x.chargeTask === 'ETC' && x.chargeTaskText === '')
        errorItem.chargeTask = '기타항목을 작성해주세요.';
      if (x.chargeTask === ChargeTaskType.ETC && x.chargeTaskText === '')
        errorItem.chargeTaskText = '담당업무를 작성해 주세요.';
      chargeListError.push(errorItem);
    });
    this.setState({
      errors: {
        ...errors,
        registrationNumber: user.registrationNumber === '' ? '사업자등록번호를 조회해주세요.' : '',
        password: passwordError,
        passwordConfirm: passwordConfirmError,
        companyNameKo: user.companyNameKo === '' ? '기업명(국문)을 입력해 주세요.' : '',
        companyNameEn: user.companyNameEn === '' ? '기업명(영문)을 입력해 주세요.' : '',
        serviceNameKo: user.serviceNameKo === '' ? '서비스명(국문)을 입력해 주세요.' : '',
        serviceNameEn: user.serviceNameEn === '' ? '서비스명(영문)을 입력해 주세요.' : '',
        address:
          user.zipcode === '' || user.baseAddress === '' || user.detailAddress === ''
            ? '주소를 입력해 주세요.'
            : '',
        tel: user.tel === '' ? '회사 대표번호를 입력해 주세요.' : '',
        introduce: user.introduce === '' ? '사업 소개를 입력해 주세요.' : '',
        businessFieldList: businessFieldListError,
        businessKeywordList: businessKeywordListError,
        investmentStep:
          user.rank !== RankType.SPECIAL && user.investmentStep === InvestmentStepType.NONE
            ? '투자단계를 선택해 주세요.'
            : '',
        foundationDate: user.foundationDate === '' ? '기업설립일을 선택해 주세요.' : '',
        investmentSourceList: investmentSourceListError,
        companyIntroduce:
          user.companyIntroduce && user.companyIntroduce[0]?.name === undefined
            ? '회사(서비스) 소개서를 첨부해주세요.'
            : '',
        ceoList: ceoListError,
        chargeList: chargeListError,
        joinReasonList: user.joinReasonList.length === 0 ? '가입신청 사유를 선택해 주세요.' : '',
        reasonForJoin: user.reasonForJoin === '' ? '구체적인 신청 사유를 입력해 주세요.' : '',
        joinPath: joinPathError,
        privacyPolicyFlag: !user.privacyPolicyFlag
          ? '개인정보 수집 이용 및 제공에 동의해 주세요.'
          : '',
      },
    });

    if (passwordError) return true;
    if (passwordConfirmError) return true;
    if (businessFieldListError) return true;
    if (businessKeywordListError) return true;
    if (investmentSourceListError) return true;
    if (joinPathError) return true;
    if (
      ceoListError[0].ceoName ||
      ceoListError[0].ceoEmail ||
      ceoListError[0].ceoMobile ||
      ceoListError[0].ageYear
    )
      return true;
    if (
      (chargeListError[0].chargeName,
      chargeListError[0].chargeEmail,
      chargeListError[0].chargeMobile,
      chargeListError[0].chargeTask || chargeListError[0].chargeTaskText)
    )
      return true;
    if (!user.registrationNumber) return true;
    if (!user.companyNameKo) return true;
    if (!user.companyNameEn) return true;
    if (!user.serviceNameKo) return true;
    if (!user.serviceNameEn) return true;
    if (!user.zipcode || !user.baseAddress || !user.detailAddress) return true;
    if (!user.tel) return true;
    if (!user.introduce) return true;
    if (user.rank !== RankType.SPECIAL && user.investmentStep === InvestmentStepType.NONE)
      return true;
    if (!user.foundationDate) return true;
    if (user.companyIntroduce && user.companyIntroduce[0]?.name === undefined) return true;
    if (!user.joinReasonList.length) return true;
    if (!user.reasonForJoin) return true;
    if (!user.privacyPolicyFlag) return true;

    return false;
  };

  // file event
  onChangeAttachedFile = async (e: any) => {
    e.preventDefault();

    const { user } = this.state;
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    e.target.value = '';

    try {
      if (file && file.name) {
        const attachId = (await FileService.upload(file))[0].id;
        const fileData = {
          id: attachId,
          name: file.name,
        };
        if (user.companyIntroduce)
          this.setState({
            user: {
              ...user,
              companyIntroduce: [...user.companyIntroduce, fileData],
            },
          });
      }
    } catch (e) {
      console.log(e);
    }
  };

  onClickRemoveAttachedFile = async (id: number | undefined) => {
    const { user } = this.state;
    const selectedFileIndex = user.companyIntroduce?.findIndex((selected) => selected.id === id);
    const selectedFile = user.companyIntroduce?.filter((x, idx) => idx !== selectedFileIndex);
    this.setState({
      user: {
        ...user,
        companyIntroduce: selectedFile,
      },
    });
  };

  // logo file event
  onChangeLogoFile = async (e: any) => {
    e.preventDefault();

    const file: File = e.target.files[0];

    if (!file) {
      return;
    }

    e.target.value = '';

    try {
      this.setState({
        tempLogo: file,
        logoPreview: await FileService.loadImgDataSource(file),
      });
    } catch (e) {
      console.log(e);
    }
  };

  onClickRemoveLogoFile = async () => {
    this.setState({
      tempLogo: undefined,
      logoPreview: '',
    });
  };

  onChangePolicyAgreement = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        privacyPolicyFlag: e.target.checked,
      },
    });
  };

  onChangeGetCeoData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { user } = this.state;
    if (e.target.checked) {
      if (user.ceoList.length === 0) {
        alert('대표자 정보가 없습니다.');
      } else {
        const newArray: Array<Charger> = [];
        user.chargeList.forEach((x, index) => {
          let newItem = {
            chargeName: '',
            chargeEmail: '',
            chargeMobile: '',
            chargeTask: '',
            chargeTaskText: '',
            newsletterFlag: '',
          };
          if (index === 0) {
            newItem = {
              chargeName: user.ceoList[0].ceoName,
              chargeEmail: user.ceoList[0].ceoEmail,
              chargeMobile: user.ceoList[0].ceoMobile,
              chargeTask: '',
              chargeTaskText: '',
              newsletterFlag: 'true',
            };
          } else {
            newItem = {
              chargeName: x.chargeName,
              chargeEmail: x.chargeEmail,
              chargeMobile: x.chargeMobile,
              chargeTask: x.chargeTask,
              chargeTaskText: x.chargeTaskText,
              newsletterFlag: x.newsletterFlag,
            };
          }
          newArray.push(newItem);
        });
        this.setState({
          getCeo: e.target.checked,
          user: {
            ...user,
            chargeList: newArray,
          },
        });
      }
    } else {
      this.setState({
        getCeo: e.target.checked,
      });
    }
  };

  onClickMoveBack = (): void => {
    const { history } = this.props;
    history.push(`/join`);
  };

  render(): ReactNode {
    const {
      user,
      passwordConfirm,
      businessListItems,
      businessKeywordIntermediateItems,
      businessKeywordCoreTechnologyItems,
      businessKeywordEtcItems,
      investmentSourceItems,
      joinReasonList,
      businessEtcText,
      IntermediateEtcText,
      CoreTechnologyEtcText,
      investmentSourceEtcText,
      KeywordEtcText,
      isAddressModalVisible,
      isRegistrationModalVisible,
      tempLogo,
      logoPreview,
      errors,
      getCeo,
      registrationErrorMessage,
      popupShow,
      tipShow,
    } = this.state;

    return (
      <>
        <div className='basic-info-container'>
          <div className='sub-title sub-title-between'>
            <p>기본정보</p>
            <ul>
              <li>
                <span className='step active'>
                  <span className='num'>01</span> 정보입력
                </span>
              </li>
              <li>
                <span className='step'>
                  <span className='num'>02</span> 가입완료
                </span>
              </li>
            </ul>
          </div>
          <div className='required-text'>
            <span className='required'>필수입력 항목</span>
          </div>
          <div className='inner'>
            <div className='input-container rank-container'>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='required'>회원등급</span>
                  </div>
                  <div className='input-area'>
                    <FormControl component='fieldset' className='radio-wrap'>
                      <RadioGroup
                        aria-label='gradeType'
                        className='input-radio'
                        defaultValue={RankType.ASSOCIATE}
                        value={user.rank}
                        onChange={(e): void => this.onChangeValue('rank', e.target.value)}
                      >
                        <FormControlLabel
                          value={RankType.ASSOCIATE}
                          control={<Radio className='radio' color='default' />}
                          label='준회원'
                        />
                        <FormControlLabel
                          value={RankType.REGULAR_NORMAL}
                          control={<Radio className='radio' color='default' />}
                          label='정회원'
                        />
                        <FormControlLabel
                          value={RankType.SPECIAL}
                          control={<Radio className='radio' color='default' />}
                          label='특별회원/후원회원'
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div className='input-inline-container'>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='required'>사업자 등록번호</span>
                  </div>
                  <div className='input-area'>
                    <input
                      type='text'
                      placeholder='사업자 등록번호 입력 ( - 없이 숫자만)'
                      className='input-text'
                      value={user.registrationNumber}
                      onClick={this.onClickRegistration}
                      onChange={(e): void =>
                        this.onChangeValue('registrationNumber', e.target.value)
                      }
                      readOnly
                    />
                  </div>
                  {isRegistrationModalVisible ? (
                    <div className='registration-modal'>
                      <div className='registration-title-wrap'>
                        <p className='registration-modal-title'>사업자등록번호 조회</p>
                        <button
                          type='button'
                          onClick={this.onClickRegistrationClose}
                          className='btn-close-modal'
                        >
                          닫기
                        </button>
                      </div>
                      <TextField
                        type='text'
                        placeholder='사업자 등록번호 입력 ( - 없이 숫자만)'
                        className='input-text'
                        InputProps={{
                          inputProps: {
                            type: 'text',
                            maxLength: 20,
                          },
                        }}
                        value={user.registrationNumber}
                        onChange={(e): void =>
                          this.onChangeValue('registrationNumber', e.target.value)
                        }
                      />
                      {registrationErrorMessage !== '' && (
                        <p className='error-message-popup'>{registrationErrorMessage}</p>
                      )}
                      <div
                        className={`button-wrap ${
                          registrationErrorMessage !== '' ? 'error-button-wrap' : ''
                        }`}
                      >
                        <button type='button' className='btn' onClick={this.checkRegistration}>
                          조회
                        </button>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {errors.registrationNumber !== '' && (
                  <p className='error-message'>{errors.registrationNumber}</p>
                )}
                {tipShow && <p className='user-tip'>계속사업자입니다.</p>}
              </div>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='nomal'>법인 등록번호</span>
                  </div>
                  <div className='input-area'>
                    <TextField
                      placeholder='법인 등록번호 입력 ( - 없이 숫자만)'
                      className='input-text'
                      InputProps={{
                        inputProps: {
                          type: 'text',
                          maxLength: 13,
                        },
                      }}
                      value={user.corporateNumber}
                      onChange={(e): void => this.onChangeValue('corporateNumber', e.target.value)}
                    />
                  </div>
                </div>
                <p className='user-tip'>※ 법인인 경우, 법인등록번호를 작성해 주세요.</p>
              </div>
            </div>
            <div className='input-inline-container'>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='required'>비밀번호</span>
                  </div>
                  <div className='input-area'>
                    <TextField
                      placeholder='대/소문자, 숫자, 특수문자를 포함한 6자 이상 입력'
                      className='input-text'
                      InputProps={{
                        inputProps: {
                          type: 'password',
                          maxLength: 20,
                        },
                      }}
                      value={user.password}
                      onChange={(e): void => this.onChangeValue('password', e.target.value)}
                    />
                  </div>
                </div>
                {errors.password !== '' && <p className='error-message'>{errors.password}</p>}
              </div>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='required'>비밀번호 확인</span>
                  </div>
                  <div className='input-area'>
                    <TextField
                      placeholder='비밀번호 재입력'
                      className='input-text'
                      InputProps={{
                        inputProps: {
                          type: 'password',
                          maxLength: 20,
                        },
                      }}
                      value={passwordConfirm}
                      onChange={this.onChangePwConfirm}
                    />
                  </div>
                </div>
                {errors.passwordConfirm !== '' && (
                  <p className='error-message'>{errors.passwordConfirm}</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='company-info-container'>
          <div className='sub-title'>
            <p>기업정보</p>
          </div>
          <div className='inner'>
            <div className='input-inline-container'>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='required'>기업명(국문)</span>
                  </div>
                  <div className='input-area'>
                    <TextField
                      placeholder='국문 기업명 입력'
                      className='input-text'
                      InputProps={{
                        inputProps: {
                          type: 'text',
                          maxLength: 50,
                        },
                      }}
                      value={user.companyNameKo}
                      onChange={(e): void => this.onChangeValue('companyNameKo', e.target.value)}
                    />
                  </div>
                </div>
                {errors.companyNameKo !== '' && (
                  <p className='error-message'>{errors.companyNameKo}</p>
                )}
              </div>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='required'>기업명(영문)</span>
                  </div>
                  <div className='input-area'>
                    <TextField
                      placeholder='영문 기업명 입력'
                      className='input-text'
                      InputProps={{
                        inputProps: {
                          type: 'text',
                          maxLength: 50,
                        },
                      }}
                      value={user.companyNameEn}
                      onChange={(e): void => this.onChangeValue('companyNameEn', e.target.value)}
                    />
                  </div>
                </div>
                {errors.companyNameEn !== '' && (
                  <p className='error-message'>{errors.companyNameEn}</p>
                )}
              </div>
            </div>
            <div className='input-inline-container'>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='required'>서비스명(국문)</span>
                  </div>
                  <div className='input-area'>
                    <TextField
                      placeholder='국문 서비스명 입력'
                      className='input-text'
                      InputProps={{
                        inputProps: {
                          type: 'text',
                          maxLength: 50,
                        },
                      }}
                      value={user.serviceNameKo}
                      onChange={(e): void => this.onChangeValue('serviceNameKo', e.target.value)}
                    />
                  </div>
                </div>
                {errors.serviceNameKo !== '' && (
                  <p className='error-message'>{errors.serviceNameKo}</p>
                )}
              </div>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='required'>서비스명(영문)</span>
                  </div>
                  <div className='input-area'>
                    <TextField
                      placeholder='영문 서비스명 입력'
                      className='input-text'
                      InputProps={{
                        inputProps: {
                          type: 'text',
                          maxLength: 50,
                        },
                      }}
                      value={user.serviceNameEn}
                      onChange={(e): void => this.onChangeValue('serviceNameEn', e.target.value)}
                    />
                  </div>
                </div>
                {errors.serviceNameEn !== '' && (
                  <p className='error-message'>{errors.serviceNameEn}</p>
                )}
              </div>
            </div>
            <div className='input-container address-container'>
              <div className='input-wrap'>
                <div className='input-inner top-align'>
                  <div className='input-title'>
                    <span className='required'>주소</span>
                  </div>
                  <div className='input-area'>
                    <div className='address'>
                      <input
                        type='number'
                        className='input-address zip-code disable'
                        value={user.zipcode}
                        onChange={(e): void => this.onChangeValue('zipcode', e.target.value)}
                        readOnly
                      />
                      <input
                        type='text'
                        className='input-address address-base disable'
                        value={user.baseAddress}
                        onChange={(e): void => this.onChangeValue('baseAddress', e.target.value)}
                        readOnly
                      />
                      <button
                        type='button'
                        className='btn btn-address'
                        onClick={this.onClickSearchAddress}
                      >
                        주소검색
                      </button>
                    </div>
                    <div>
                      <input
                        type='text'
                        className='input-address detail-address'
                        placeholder='상세주소 입력'
                        maxLength={100}
                        value={user.detailAddress}
                        onChange={(e): void => this.onChangeValue('detailAddress', e.target.value)}
                      />
                    </div>
                    {isAddressModalVisible ? (
                      <div className='address-modal'>
                        <DaumPostcode
                          height={450}
                          style={addressModalStyle}
                          onComplete={this.onChangeAddressSearch}
                          autoClose
                        />
                        <button
                          type='button'
                          onClick={this.onClickSearchAddressClose}
                          className='btn-close'
                        >
                          닫기
                        </button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                {errors.address !== '' && <p className='error-message'>{errors.address}</p>}
              </div>
            </div>
            <div className='input-inline-container'>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='required'>회사 대표번호</span>
                  </div>
                  <div className='input-area'>
                    <TextField
                      type='text'
                      placeholder='번호 입력'
                      className='input-text'
                      InputProps={{
                        inputProps: {
                          type: 'text',
                          maxLength: 11,
                        },
                      }}
                      value={user.tel}
                      onChange={(e): void =>
                        this.onChangeValue('tel', e.target.value.replace(/[^0-9]/g, ''))
                      }
                    />
                  </div>
                </div>
                {errors.tel !== '' && <p className='error-message'>{errors.tel}</p>}
              </div>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='nomal'>홈페이지</span>
                  </div>
                  <div className='input-area'>
                    <TextField
                      placeholder='URL 입력'
                      className='input-text'
                      InputProps={{
                        inputProps: {
                          type: 'text',
                          maxLength: 500,
                        },
                      }}
                      value={user.homepage}
                      onChange={(e): void => this.onChangeValue('homepage', e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='input-container'>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='required'>사업 소개</span>
                  </div>
                  <div className='input-area'>
                    <TextField
                      placeholder='사업/서비스에 관해 간단히 소개해주세요'
                      className='input-text input-introduce'
                      InputProps={{
                        inputProps: {
                          type: 'text',
                          maxLength: 100,
                        },
                      }}
                      value={user.introduce}
                      onChange={(e): void => this.onChangeValue('introduce', e.target.value)}
                    />
                  </div>
                </div>
                {errors.introduce !== '' && <p className='error-message'>{errors.introduce}</p>}
              </div>
            </div>
            <div className='input-container business-field-container'>
              <div className='input-wrap'>
                <div className='input-inner d-block-inner'>
                  <div className='input-title'>
                    <span className='required'>주요 사업분야 (최대 2개까지 선택 가능)</span>
                  </div>
                  <div className='input-area input-checkbox-area'>
                    <div className='checkbox-wrap'>
                      {businessListItems.map(({ id, flagName }) => (
                        <div className='checkbox-label-wrap'>
                          <FormControlLabel
                            className='checkbox'
                            key={shortid.generate()}
                            control={
                              <Checkbox
                                color='default'
                                value={id}
                                data-name={flagName}
                                onChange={this.onChangeBusinessFiledItemsCheck}
                                checked={this.businessFieldIsChecked(id)}
                                className='item'
                              />
                            }
                            label={flagName}
                          />
                          {flagName === '기타' && (
                            <TextField
                              type='text'
                              className='input-etc-text'
                              InputProps={{
                                inputProps: {
                                  type: 'text',
                                },
                              }}
                              value={businessEtcText}
                              disabled={
                                !user.businessFieldList.map((selected) => selected.id).includes(40)
                              }
                              onChange={(e) =>
                                this.onChangeBusinessEtcText('businessEtcText', e.target.value)
                              }
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {errors.businessFieldList !== '' && (
                  <p className='error-message'>{errors.businessFieldList}</p>
                )}
              </div>
            </div>
            <div className='input-container business-keyword-container'>
              <div className='input-wrap'>
                <div className='input-inner d-block-inner'>
                  <div className='input-title'>
                    <span className='required'>
                      주요 비즈니스 키워드(중개유형, 핵심기술 등 포괄하여 최대 3개 까지 선택 가능)
                    </span>
                  </div>
                  <div className='business-sub-container input-area input-checkbox-area'>
                    <div className='business-sub-wrap'>
                      <div className='business-sub-title'>중개유형</div>
                      <div className='checkbox-wrap'>
                        {businessKeywordIntermediateItems.map(({ id, flagName }) => (
                          <div className='checkbox-label-wrap'>
                            <FormControlLabel
                              className='checkbox'
                              key={shortid.generate()}
                              control={
                                <Checkbox
                                  color='default'
                                  value={id}
                                  data-name={flagName}
                                  onChange={this.onChangeBusinessKeywordItemsCheck}
                                  checked={this.businessKeywordIsChecked(id)}
                                  className='item'
                                />
                              }
                              label={flagName}
                            />
                            {flagName === '기타' && (
                              <TextField
                                type='text'
                                className='input-etc-text'
                                InputProps={{
                                  inputProps: {
                                    type: 'text',
                                  },
                                }}
                                value={IntermediateEtcText}
                                disabled={
                                  !user.businessKeywordList
                                    .map((selected) => selected.id)
                                    .includes(48)
                                }
                                onChange={(e) =>
                                  this.onChangeBusinessKeywordEtcText(
                                    'IntermediateEtcText',
                                    e.target.value,
                                  )
                                }
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className='business-sub-wrap'>
                      <div className='business-sub-title'>핵심기술</div>
                      <div className='checkbox-wrap'>
                        {businessKeywordCoreTechnologyItems.map(({ id, flagName }) => (
                          <div className='checkbox-label-wrap'>
                            <FormControlLabel
                              className='checkbox'
                              key={shortid.generate()}
                              control={
                                <Checkbox
                                  color='default'
                                  value={id}
                                  data-name={flagName}
                                  onChange={this.onChangeBusinessKeywordItemsCheck}
                                  checked={this.businessKeywordIsChecked(id)}
                                  className='item'
                                />
                              }
                              label={flagName}
                            />
                            {flagName === '기타' && (
                              <TextField
                                type='text'
                                className='input-etc-text'
                                InputProps={{
                                  inputProps: {
                                    type: 'text',
                                  },
                                }}
                                value={CoreTechnologyEtcText}
                                disabled={
                                  !user.businessKeywordList
                                    .map((selected) => selected.id)
                                    .includes(57)
                                }
                                onChange={(e) =>
                                  this.onChangeBusinessKeywordEtcText(
                                    'CoreTechnologyEtcText',
                                    e.target.value,
                                  )
                                }
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className='business-sub-wrap'>
                      <div className='business-sub-title'>기타</div>
                      <div className='checkbox-wrap'>
                        {businessKeywordEtcItems.map(({ id, flagName }) => (
                          <div className='checkbox-label-wrap'>
                            <FormControlLabel
                              className='checkbox'
                              key={shortid.generate()}
                              control={
                                <Checkbox
                                  color='default'
                                  value={id}
                                  data-name={flagName}
                                  onChange={this.onChangeBusinessKeywordItemsCheck}
                                  checked={this.businessKeywordIsChecked(id)}
                                  className='item'
                                />
                              }
                              label={flagName}
                            />
                            {flagName === '기타' && (
                              <TextField
                                type='text'
                                className='input-etc-text'
                                InputProps={{
                                  inputProps: {
                                    type: 'text',
                                  },
                                }}
                                value={KeywordEtcText}
                                disabled={
                                  !user.businessKeywordList
                                    .map((selected) => selected.id)
                                    .includes(65)
                                }
                                onChange={(e) =>
                                  this.onChangeBusinessKeywordEtcText(
                                    'KeywordEtcText',
                                    e.target.value,
                                  )
                                }
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {errors.businessKeywordList !== '' && (
                  <p className='error-message'>{errors.businessKeywordList}</p>
                )}
              </div>
            </div>
            <div className='input-inline-container'>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='required'>
                      투자단계
                      <br className='m-hidden' />
                      (Deal closing step)
                    </span>
                  </div>
                  <div className='input-area'>
                    <FormControl variant='outlined'>
                      <Select
                        className='select-item select-investment-step'
                        defaultValue={InvestmentStepType.NONE}
                        value={user.investmentStep}
                        onChange={(e): void => this.onChangeValue('investmentStep', e.target.value)}
                        disabled={user.rank === RankType.SPECIAL}
                      >
                        <MenuItem value={InvestmentStepType.NONE}>선택 안함</MenuItem>
                        <MenuItem value={InvestmentStepType.SUPPORT_FUND}>지원금</MenuItem>
                        <MenuItem value={InvestmentStepType.SEED}>Seed</MenuItem>
                        <MenuItem value={InvestmentStepType.PRE_A}>Pre A</MenuItem>
                        <MenuItem value={InvestmentStepType.PRE_B}>Pre B</MenuItem>
                        <MenuItem value={InvestmentStepType.SERIES_A}>Series A</MenuItem>
                        <MenuItem value={InvestmentStepType.SERIES_B}>Series B</MenuItem>
                        <MenuItem value={InvestmentStepType.SERIES_C}>Series C</MenuItem>
                        <MenuItem value={InvestmentStepType.SERIES_D}>Series D</MenuItem>
                        <MenuItem value={InvestmentStepType.SERIES_E}>Series E</MenuItem>
                        <MenuItem value={InvestmentStepType.SERIES_F}>Series F</MenuItem>
                        <MenuItem value={InvestmentStepType.PRE_IPO}>Pre IPO</MenuItem>
                        <MenuItem value={InvestmentStepType.IPO}>IPO</MenuItem>
                        <MenuItem value={InvestmentStepType.M_AND_A}>M&A</MenuItem>
                        <MenuItem value={InvestmentStepType.PRIVATE}>비공개</MenuItem>
                        <MenuItem value={InvestmentStepType.NO_PLAN}>투자유치 계획없음</MenuItem>
                        <MenuItem value={InvestmentStepType.NO_EXPERIENCE}>
                          투자유치 이력없음
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                {errors.investmentStep !== '' && (
                  <p className='error-message'>{errors.investmentStep}</p>
                )}
              </div>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <p className='input-title'>
                    <span className='required'>기업설립일</span>
                  </p>
                  <div className='input-area'>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant='inline'
                        format='yyyy-MM-dd'
                        autoOk
                        className='input-date'
                        value={user.foundationDate || null}
                        onChange={this.onChangeFoundationDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                {errors.foundationDate !== '' && (
                  <p className='error-message'>{errors.foundationDate}</p>
                )}
              </div>
            </div>
            <div className='input-container'>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='required'>투자출처</span>
                  </div>
                  <div className='input-area'>
                    {investmentSourceItems.map(({ id, flagName }) => (
                      <div className='checkbox-label-wrap'>
                        <FormControlLabel
                          className={flagName === '기타' ? 'checkbox-input-box' : 'checkbox'}
                          key={shortid.generate()}
                          control={
                            <Checkbox
                              color='default'
                              value={id}
                              data-name={flagName}
                              onChange={this.onChangeInvestmentSourceListItemsCheck}
                              checked={this.investmentSourceIsChecked(id)}
                              className='item'
                              disabled={user.rank === RankType.SPECIAL}
                            />
                          }
                          label={flagName}
                        />
                        {flagName === '기타' && (
                          <TextField
                            type='text'
                            className='input-etc-text'
                            InputProps={{
                              inputProps: {
                                type: 'text',
                              },
                            }}
                            value={investmentSourceEtcText}
                            disabled={
                              !user.investmentSourceList.map((selected) => selected.id).includes(74)
                            }
                            onChange={(e) =>
                              this.onChangeInvestmentSourceEtcText(
                                'investmentSourceEtcText',
                                e.target.value,
                              )
                            }
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                {errors.investmentSourceList !== '' && (
                  <p className='error-message'>{errors.investmentSourceList}</p>
                )}
              </div>
            </div>
            <div className='input-inline-container'>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='nomal'>평균 매출규모</span>
                  </div>
                  <div className='input-area input-number'>
                    <TextField
                      type='text'
                      placeholder='평균매출 입력'
                      className='input-text'
                      InputProps={{
                        inputProps: {
                          type: 'text',
                          maxLength: 20,
                        },
                      }}
                      value={this.capitalValue(user.averageRevenue)}
                      onChange={(e): void => this.onChangeCapital('averageRevenue', e.target.value)}
                      // value={user.accumulativeInvestment || undefined}
                      // onChange={(e): void =>
                      //   this.onChangeValue('accumulativeInvestment', e.target.value)
                      // }
                    />
                    <span>원</span>
                  </div>
                </div>
              </div>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='nomal'>누적투자 규모</span>
                  </div>
                  <div className='input-area input-number'>
                    <TextField
                      type='text'
                      placeholder='투자규모 입력'
                      className='input-text'
                      disabled={user.rank === RankType.SPECIAL}
                      InputProps={{
                        inputProps: {
                          type: 'text',
                          maxLength: 20,
                        },
                      }}
                      value={this.capitalValue(user.accumulativeInvestment)}
                      onChange={(e): void =>
                        this.onChangeCapital('accumulativeInvestment', e.target.value)
                      }
                      // value={user.accumulativeInvestment || undefined}
                      // onChange={(e): void =>
                      //   this.onChangeValue('accumulativeInvestment', e.target.value)
                      // }
                    />
                    <span>원</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='input-inline-container'>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='nomal'>재직자 수</span>
                  </div>
                  <div className='input-area input-number'>
                    <TextField
                      type='text'
                      placeholder='재직자 수 입력'
                      className='input-text'
                      InputProps={{
                        inputProps: {
                          type: 'text',
                          maxLength: 11,
                        },
                      }}
                      value={this.capitalValue(user.employs)}
                      onChange={(e): void => this.onChangeCapital('employs', e.target.value)}
                    />
                    <span>명</span>
                  </div>
                </div>
              </div>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='nomal'>자본금</span>
                  </div>
                  <div className='input-area input-number'>
                    <TextField
                      type='text'
                      placeholder='자본금 입력'
                      className='input-text'
                      disabled={user.rank === RankType.SPECIAL}
                      InputProps={{
                        inputProps: {
                          type: 'text',
                          maxLength: 20,
                        },
                      }}
                      value={this.capitalValue(user.capital)}
                      onChange={(e): void => this.onChangeCapital('capital', e.target.value)}
                      // value={this.capitalValue() || undefined}
                      // onFocus={this.isFocused}
                      // onChange={this.onChangeCapital}
                    />
                    <span>원</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='input-inline-container'>
              <div className='input-wrap'>
                <div className='input-inner top-align'>
                  <div className='input-title'>
                    <span className='required'>
                      회사 (서비스)
                      <br className='m-hidden' /> 소개서
                    </span>
                  </div>
                  <div className='input-area input-file-area'>
                    <input
                      type='file'
                      accept='.pdf, .pptx'
                      className='input-file'
                      onChange={this.onChangeAttachedFile}
                    />
                    <button type='button' className='btn btn-file'>
                      파일첨부
                    </button>
                    {user.companyIntroduce &&
                      user.companyIntroduce.map(
                        (x) =>
                          x.name && (
                            <div className='file-box-wrap'>
                              <p className='filename'>{x.name}</p>
                              <button
                                type='button'
                                className='btn btn-file delete-btn'
                                onClick={() => this.onClickRemoveAttachedFile(x.id)}
                              >
                                삭제
                              </button>
                            </div>
                          ),
                      )}
                    <p className='file-tip'>*파일 형식 : pdf, pptx | 최대 10MB</p>
                  </div>
                </div>
                {errors.companyIntroduce !== '' && (
                  <p className='error-message'>{errors.companyIntroduce}</p>
                )}
              </div>
              <div className='input-wrap'>
                <div className='input-inner top-align'>
                  <div className='input-title'>
                    <span className='nomal'>회사 로고</span>
                  </div>
                  <div className='logo-file'>
                    <div>
                      <div className='file-upload'>
                        <div className='file-inner'>
                          <input
                            type='file'
                            className='upload-photo'
                            accept='.png, .jpg'
                            onChange={this.onChangeLogoFile}
                          />
                          {tempLogo && tempLogo.name ? (
                            <>
                              <img className='mypage-logo' src={logoPreview} alt='' />
                              <button
                                type='button'
                                className='btn btn-file delete-btn'
                                onClick={this.onClickRemoveLogoFile}
                              >
                                삭제
                              </button>
                            </>
                          ) : (
                            <Button variant='outlined' className='btn-photo'>
                              <span className='upload-text'>
                                클릭하여 이미지 업로드
                                <br className='m-hidden' />
                                <span className='font-gray'>권장사이즈 : 305*195 px</span>
                              </span>
                            </Button>
                          )}
                        </div>
                        <p>*파일 형식 : png, jpg | 최대 10MB</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='ceo-info-container'>
          <div className='sub-title'>
            <p>대표자 정보</p>
          </div>
          <div className='inner'>
            {user.ceoList.map((ceo, index: number) => {
              return (
                <div className='input-box-wrap'>
                  <div className='input-inline-container'>
                    <div className='input-wrap'>
                      <div className='input-inner'>
                        <div className='input-title'>
                          <span className='required'>대표자명</span>
                        </div>
                        <div className='input-area'>
                          <TextField
                            placeholder='대표자명 입력'
                            className='input-text'
                            InputProps={{
                              inputProps: {
                                type: 'text',
                                maxLength: 50,
                              },
                            }}
                            value={ceo.ceoName}
                            onChange={(e): void =>
                              this.onChangeCeoValue(`ceoName`, e.target.value, index)
                            }
                          />
                        </div>
                      </div>
                      {errors.ceoList[index].ceoName !== '' && (
                        <p className='error-message'>{errors.ceoList[index].ceoName}</p>
                      )}
                    </div>
                    <div className='input-wrap'>
                      <div className='input-inner'>
                        <div className='input-title'>
                          <span className='required'>대표자 연락처</span>
                        </div>
                        <div className='input-area'>
                          <TextField
                            type='text'
                            placeholder='대표자 휴대폰번호 입력'
                            className='input-text'
                            InputProps={{
                              inputProps: {
                                type: 'text',
                                maxLength: 11,
                              },
                            }}
                            value={ceo.ceoMobile}
                            onChange={(e): void =>
                              this.onChangeCeoValue(
                                'ceoMobile',
                                e.target.value.replace(/[^0-9]/g, ''),
                                index,
                              )
                            }
                          />
                        </div>
                      </div>
                      {errors.ceoList[index].ceoMobile !== '' && (
                        <p className='error-message'>{errors.ceoList[index].ceoMobile}</p>
                      )}
                    </div>
                  </div>
                  <div className='input-inline-container'>
                    <div className='input-wrap'>
                      <div className='input-inner'>
                        <div className='input-title'>
                          <span className='required'>대표자 이메일</span>
                        </div>
                        <div className='input-area'>
                          <TextField
                            placeholder='대표자 이메일 입력'
                            className='input-text'
                            InputProps={{
                              inputProps: {
                                type: 'text',
                                maxLength: 50,
                              },
                            }}
                            value={ceo.ceoEmail}
                            onChange={(e): void =>
                              this.onChangeCeoValue(`ceoEmail`, e.target.value, index)
                            }
                          />
                        </div>
                      </div>
                      {errors.ceoList[index].ceoEmail !== '' && (
                        <p className='error-message'>{errors.ceoList[index].ceoEmail}</p>
                      )}
                    </div>
                    <div className='input-wrap'>
                      <div className='input-inner'>
                        <div className='input-title'>
                          <span className='required'>
                            정기 소식지
                            <br className='m-hidden' /> 수신여부
                          </span>
                        </div>
                        <div className='input-area'>
                          <FormControl component='fieldset' className='input-radio-wrap'>
                            <RadioGroup
                              aria-label='agreementAvailability'
                              className='input-radio'
                              value={ceo.newsletterFlag}
                              onChange={(e): void =>
                                this.onChangeCeoValue(`newsletterFlag`, e.target.value, index)
                              }
                            >
                              <FormControlLabel
                                value='true'
                                control={<Radio className='radio' color='default' value='true' />}
                                label='동의'
                              />
                              <FormControlLabel
                                value='false'
                                control={<Radio className='radio' color='default' value='false' />}
                                label='비동의'
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                      <div className='input-tip'>
                        ※ 정기 소식지를 통해 회원사 혜택, 멤버십 프로그램 (워크숍 등) 코스포 동향
                        등이 안내됩니다.
                        <br />
                        코스포 활동 참여를 원할 경우, 정기 소식지 수신여부에 동의를 선택해 주세요.
                      </div>
                    </div>
                  </div>
                  <div className='input-inline-container birth-input-container'>
                    <div className='input-wrap'>
                      <div className='input-inner'>
                        <div className='input-title'>
                          <span className='required'>창업자 출생연도</span>
                        </div>
                        <div className='input-area'>
                          <FormControl variant='outlined'>
                            <Select
                              value={ceo.ageYear || ''}
                              className='select-item'
                              onChange={(e): void =>
                                this.onChangeCeoValue(`ageYear`, e.target.value, index)
                              }
                            >
                              <MenuItem disabled>출생연도</MenuItem>
                              {this.years.map((year: any) => (
                                <MenuItem key={shortid.generate()} value={year}>
                                  {year}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {errors.ceoList[index].ageYear !== '' && (
                        <p className='error-message'>{errors.ceoList[index].ageYear}</p>
                      )}
                    </div>
                    <div className='input-wrap'>
                      <div className='input-inner'>
                        <div className='input-title'>
                          <span className='required'>창업자 성별</span>
                        </div>
                        <div className='input-area'>
                          <FormControl component='fieldset' className='input-radio-wrap'>
                            <RadioGroup
                              aria-label='gender'
                              className='input-radio'
                              value={ceo.gender}
                              onChange={(e): void =>
                                this.onChangeCeoValue(`gender`, e.target.value, index)
                              }
                            >
                              <FormControlLabel
                                value={GenderType.MALE}
                                control={
                                  <Radio
                                    className='radio'
                                    color='default'
                                    value={GenderType.MALE}
                                  />
                                }
                                label='남성'
                              />
                              <FormControlLabel
                                value={GenderType.FEMALE}
                                control={
                                  <Radio
                                    className='radio'
                                    color='default'
                                    value={GenderType.FEMALE}
                                  />
                                }
                                label='여성'
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type='button'
                    className='btn-remove-item'
                    onClick={() => this.removeCeo(index)}
                  >
                    삭제
                  </button>
                </div>
              );
            })}
            <div className='button-wrap'>
              <button type='button' className='btn' onClick={this.appendCeo}>
                대표자 추가
              </button>
            </div>
          </div>
        </div>
        <div className='charge-info-container'>
          <div className='sub-title sub-title-between'>
            <p>담당자 정보</p>
            <div className='checkbox-wrap'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={getCeo}
                    onChange={this.onChangeGetCeoData}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    className='policy-checkbox'
                  />
                }
                label='대표자 정보와 동일'
              />
            </div>
          </div>
          <div className='inner'>
            {user.chargeList.map((charge, index) => {
              return (
                <div className='input-box-wrap'>
                  <div className='input-inline-container'>
                    <div className='input-wrap'>
                      <div className='input-inner'>
                        <div className='input-title'>
                          <span className='required'>담당자명</span>
                        </div>
                        <div className='input-area'>
                          <TextField
                            placeholder='담당자명 입력'
                            className='input-text'
                            InputProps={{
                              inputProps: {
                                type: 'text',
                                maxLength: 50,
                              },
                            }}
                            value={charge.chargeName}
                            onChange={(e: any): void =>
                              this.onChangeChargeValue(`chargeName`, e.target.value, index)
                            }
                          />
                        </div>
                      </div>
                      {errors.chargeList[index].chargeName !== '' && (
                        <p className='error-message'>{errors.chargeList[index].chargeName}</p>
                      )}
                    </div>
                    <div className='input-wrap'>
                      <div className='input-inner'>
                        <div className='input-title'>
                          <span className='required'>담당 업무</span>
                        </div>
                        <div className='input-area'>
                          <FormControl variant='outlined'>
                            <Select
                              className='select-item'
                              defaultValue={ChargeTaskType.NONE}
                              value={charge.chargeTask}
                              onChange={(e): void =>
                                this.onChangeChargeValue(`chargeTask`, e.target.value, index)
                              }
                            >
                              <MenuItem value={ChargeTaskType.NONE}>담당업무 선택</MenuItem>
                              <MenuItem value={ChargeTaskType.MONEY}>연회비 담당</MenuItem>
                              <MenuItem value={ChargeTaskType.FORUM}>포럼 소통</MenuItem>
                              <MenuItem value={ChargeTaskType.ETC}>기타</MenuItem>
                            </Select>
                          </FormControl>
                          {charge.chargeTask === ChargeTaskType.ETC && (
                            <TextField
                              placeholder='담당업무를 입력해 주세요.'
                              className='input-text'
                              InputProps={{
                                inputProps: {
                                  type: 'text',
                                  maxLength: 200,
                                },
                              }}
                              value={charge.chargeTaskText}
                              onChange={(e): void =>
                                this.onChangeChargeValue('chargeTaskText', e.target.value, index)
                              }
                            />
                          )}
                        </div>
                      </div>
                      {errors.chargeList[index].chargeTask !== '' && (
                        <p className='error-message'>{errors.chargeList[index].chargeTask}</p>
                      )}
                    </div>
                  </div>
                  <div className='input-inline-container'>
                    <div className='input-wrap'>
                      <div className='input-inner'>
                        <div className='input-title'>
                          <span className='required'>담당자 이메일</span>
                        </div>
                        <div className='input-area'>
                          <TextField
                            placeholder='담당자 이메일 입력'
                            className='input-text'
                            InputProps={{
                              inputProps: {
                                type: 'text',
                                maxLength: 50,
                              },
                            }}
                            value={charge.chargeEmail}
                            onChange={(e): void =>
                              this.onChangeChargeValue(`chargeEmail`, e.target.value, index)
                            }
                          />
                        </div>
                      </div>
                      {errors.chargeList[index].chargeEmail !== '' && (
                        <p className='error-message'>{errors.chargeList[index].chargeEmail}</p>
                      )}
                    </div>
                    <div className='input-wrap'>
                      <div className='input-inner'>
                        <div className='input-title'>
                          <span className='required'>담당자 연락처</span>
                        </div>
                        <div className='input-area'>
                          <TextField
                            type='text'
                            placeholder='담당자 연락처 입력'
                            className='input-text'
                            InputProps={{
                              inputProps: {
                                type: 'text',
                                maxLength: 11,
                              },
                            }}
                            value={charge.chargeMobile}
                            onChange={(e): void =>
                              this.onChangeChargeValue(
                                `chargeMobile`,
                                e.target.value.replace(/[^0-9]/g, ''),
                                index,
                              )
                            }
                          />
                        </div>
                      </div>
                      {errors.chargeList[index].chargeMobile !== '' && (
                        <p className='error-message'>{errors.chargeList[index].chargeMobile}</p>
                      )}
                    </div>
                  </div>
                  <div className='input-right-container'>
                    <div className='input-wrap'>
                      <div className='input-inner'>
                        <div className='input-title'>
                          <span className='required'>
                            정기 소식지
                            <br className='m-hidden' /> 수신여부
                          </span>
                        </div>
                        <div className='input-area input-contents'>
                          <FormControl component='fieldset' className='input-radio-wrap'>
                            <RadioGroup
                              aria-label='agreementAvailability'
                              className='input-radio'
                              value={charge.newsletterFlag}
                              onChange={(e): void =>
                                this.onChangeChargeValue(`newsletterFlag`, e.target.value, index)
                              }
                            >
                              <FormControlLabel
                                value='true'
                                control={<Radio className='radio' color='default' value='true' />}
                                label='동의'
                              />
                              <FormControlLabel
                                value='false'
                                control={<Radio className='radio' color='default' value='false' />}
                                label='비동의'
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                      <div className='input-tip'>
                        ※ 정기 소식지를 통해 회원사 혜택, 멤버십 프로그램 (워크숍 등) 코스포 동향
                        등이 안내됩니다.
                        <br />
                        코스포 활동 참여를 원할 경우, 정기 소식지 수신여부에 동의를 선택해 주세요.
                      </div>
                    </div>
                  </div>
                  {index !== 0 && (
                    <button
                      type='button'
                      className='btn-remove-item'
                      onClick={() => this.removeCharge(index)}
                    >
                      삭제
                    </button>
                  )}
                </div>
              );
            })}
            <div className='button-wrap'>
              <button type='button' className='btn' onClick={this.appendCharge}>
                담당자 추가
              </button>
            </div>
          </div>
        </div>
        <div className='add-info-container'>
          <div className='sub-title'>
            <p>추가정보</p>
          </div>
          <div className='inner'>
            <div className='input-container'>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='required'>가입신청 사유</span>
                  </div>
                  <div className='checkbox-wrap'>
                    {joinReasonList.map(({ id, flagName }) => (
                      <FormControlLabel
                        className='checkbox'
                        key={shortid.generate()}
                        control={
                          <Checkbox
                            color='default'
                            value={id}
                            data-name={flagName}
                            onChange={this.onChangeJoinReasonListItemsCheck}
                            checked={this.joinReasonIsChecked(id)}
                            className='item'
                          />
                        }
                        label={flagName}
                      />
                    ))}
                  </div>
                </div>
                {errors.joinReasonList !== '' && (
                  <p className='error-message'>{errors.joinReasonList}</p>
                )}
              </div>
            </div>
            <div className='input-container'>
              <div className='input-wrap'>
                <div className='input-inner top-align'>
                  <div className='input-title'>
                    <span className='required'>
                      구체척인
                      <br className='m-hidden' /> 신청사유
                    </span>
                  </div>
                  <div className='input-area text-area'>
                    <textarea
                      placeholder='가입신청사유 항목과는 별개로 구체적인 신청사유를 입력해주세요. - 희망 활동 등 (최대 1,000자 까지 입력 가능합니다.)'
                      maxLength={1000}
                      value={user.reasonForJoin}
                      onChange={(e): void => this.onChangeValue('reasonForJoin', e.target.value)}
                    />
                  </div>
                </div>
                {errors.reasonForJoin !== '' && (
                  <p className='error-message'>{errors.reasonForJoin}</p>
                )}
              </div>
            </div>
            <div className='input-container'>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='required'>가입경로</span>
                  </div>
                  <div className='input-area'>
                    <FormControl variant='outlined'>
                      <Select
                        className='select-item'
                        defaultValue={JoinPathType.NONE}
                        value={user.joinPath}
                        onChange={(e): void => this.onChangeValue('joinPath', e.target.value)}
                      >
                        <MenuItem value={JoinPathType.NONE}>선택안함</MenuItem>
                        <MenuItem value={JoinPathType.ACQUAINTANCE}>지인 소개</MenuItem>
                        <MenuItem value={JoinPathType.PORTAL}>포털 검색</MenuItem>
                        <MenuItem value={JoinPathType.SNS}>SNS 광고</MenuItem>
                        <MenuItem value={JoinPathType.ETC}>기타</MenuItem>
                      </Select>
                    </FormControl>
                    {user.joinPath === JoinPathType.ETC && (
                      <TextField
                        placeholder='가입경로를 입력해 주세요.'
                        className='input-text path-text'
                        InputProps={{
                          inputProps: {
                            type: 'text',
                            maxLength: 200,
                          },
                        }}
                        value={user.joinPathText}
                        onChange={(e): void => this.onChangeValue('joinPathText', e.target.value)}
                      />
                    )}
                  </div>
                </div>
                {errors.joinPath !== '' && <p className='error-message'>{errors.joinPath}</p>}
              </div>
            </div>
            <div className='input-container'>
              <div className='input-wrap'>
                <div className='input-inner'>
                  <div className='input-title'>
                    <span className='required'>약관동의</span>
                  </div>
                  <div className='checkbox-wrap'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={user.privacyPolicyFlag}
                          onChange={this.onChangePolicyAgreement}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          className='policy-checkbox'
                        />
                      }
                      label='개인정보 수집 이용 및 제공 동의서'
                    />
                    <a className='btn-policy' target='_blank' href='/privacy-policy'>
                      내용보기
                    </a>
                  </div>
                </div>
                {errors.privacyPolicyFlag !== '' && (
                  <p className='error-message'>{errors.privacyPolicyFlag}</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='bottom-area'>
          <button type='button' className='btn btn-back' onClick={this.onClickMoveBack}>
            이전
          </button>
          <button
            type='button'
            className='btn btn-complete'
            onClick={this.onClickRegisterUser}
            disabled={popupShow}
          >
            작성완료
          </button>
        </div>
        {popupShow && <JoinResultPopup />}
      </>
    );
  }
}

export default withRouter(JoinCommonInfoPage);
