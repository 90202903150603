import { RouteProps } from 'react-router-dom';
import {
  MemberListPage,
  MemberDetailPage,
  MemberBenefitsPage,
  MemberInterviewListPage,
} from '@pages';
import MemberGuidePage from './MemberPage/MemberGuidePage';
import MemberBenefitsDetailPage from './MemberPage/MemberBenefitsDetailPage';
import MemberBenefitsCreatePage from './MemberPage/MemberBenefitsCreatePage';

const MemberRouteConfigs: RouteProps[] = [
  {
    exact: true,
    path: '/membership/member',
    component: MemberListPage,
  },
  {
    path: '/membership/member/:id',
    component: MemberDetailPage,
  },
  {
    exact: true,
    path: '/membership/interview',
    component: MemberInterviewListPage,
  },
  {
    exact: true,
    path: '/membership/benefits',
    component: MemberBenefitsPage,
  },
  {
    path: '/membership/benefits/create',
    component: MemberBenefitsCreatePage,
  },
  {
    path: '/membership/benefits/:id',
    component: MemberBenefitsDetailPage,
  },
  {
    exact: true,
    path: '/membership/guide',
    component: MemberGuidePage,
  },
];

export default MemberRouteConfigs;
