import { NOTICEND_TOKEN } from '@constants';
import { AxiosRequestConfig } from 'axios';
import api from './apiService';

class NoticendService {
  static getNoticendList = async (conditions: any) => {
    const url: string =
      conditions.word === ''
        ? `https://noticendopenapi.azurewebsites.net/api/v2/Posts/preview/channel/19?${api.qs(
            conditions,
          )}`
        : `https://noticendopenapi.azurewebsites.net/api/v2/Posts/search/channel/19/${
            conditions.word
          }/withContent/false?${api.qs(conditions)}`;

    const requestConfig: AxiosRequestConfig = {
      url,
      method: 'GET',
      headers: {
        authorization: `Bearer ${NOTICEND_TOKEN}`,
      },
    };

    try {
      const response = await api.request(requestConfig);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default NoticendService;
